import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"

import { get } from "helpers/api_helper"
import WorkingHourForm from "components/WorkingHours/WorkingHourForm"
import SpinnerLoader from "components/Loader/SpinnerLoader"
const EditWorkingHour = props => {
  const [working_hour, setWorkingHour] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    get(`working-hours/${props.match.params.id}`).then(res => {
      const { status, result } = res
      setWorkingHour(result)
      setLoading(false)
    })
  }, [])

  return (
    <div className="page-content">
      <MetaTags>
        <title>Working Hour | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{
            title: "Working Hours",
            href: "/working-hours",
          }}
          breadcrumbItem="Edit Working Hour"
        />
        {loading ? (
          <SpinnerLoader/>
        ) : (
          <WorkingHourForm
            c_data={working_hour}
            working_hour_id={props.match.params.id}
          />
        )}
      </div>
    </div>
  )
}

export default EditWorkingHour
