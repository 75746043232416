import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { get } from "helpers/api_helper"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { Col, Card, CardBody, Row } from "reactstrap"
import SpinnerLoader from "components/Loader/SpinnerLoader"

let country_options = {
  "+27": "UAE",
  "+971": "South Africa",
}

const ViewCustomer = props => {
  const [customer, setCustomer] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    get(`customers/${props.match.params.id}`).then(res => {
      const { status, result } = res
      setCustomer(result)
      setLoading(false)
    })
  }, [])
  return (
    <div className="page-content">
      <MetaTags>
        <title>Customers | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{
            title: "Customers",
            href: "/customers",
          }}
          breadcrumbItem="View Customer"
        />
        {loading ? (
          <SpinnerLoader />
        ) : (
          <Card>
            <CardBody>
              <Row>
                <div className="col-12 fs-5">
                  <div className="invoice-title"></div>
                  <hr />
                  <Row>
                    <div className="col-6">
                      <address>
                        <strong>Id no:</strong>
                        <br />#{customer._id}
                      </address>
                    </div>
                    <div className="col-6 text-end">
                      <address>
                        <strong>Country:</strong>
                        <br />
                        {country_options[customer.country_code]}
                        <br />
                        {/* {customer.city ? `City: ${customer.city}` : ""} */}
                        {customer.city ? (
                          <span>
                            <b>City: </b>
                            {customer.city}
                          </span>
                        ) : (
                          ""
                        )}
                      </address>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-6">
                      <address>
                        <strong>Name:</strong>
                        <br />
                        {`${customer.first_name} ${customer.last_name || ""} `}
                      </address>
                    </div>
                    <div className="col-6 text-end">
                      <address>
                        <strong>Email:</strong>
                        <br />
                        {customer.email}
                      </address>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-6">
                      <address>
                        <strong>Contact no:</strong>
                        <br />
                        {(customer.country_code
                          ? customer.country_code + "-"
                          : "") + customer.contact_number}
                      </address>
                    </div>
                    <div className="col-6 text-end">
                      <address>
                        <strong> Wallet Balance:</strong>
                        <br />
                        {customer.wallet
                          ? (customer.wallet.currency || "") +
                            " " +
                            (customer.wallet.amount || 0)
                          : null}
                      </address>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-6">
                      <address>
                        <strong>Address:</strong>
                        <br />
                        {customer.address || "Nil"}
                      </address>
                    </div>
                  </Row>
                </div>
              </Row>
            </CardBody>
          </Card>
        )}
      </div>
    </div>
  )
}

export default ViewCustomer
