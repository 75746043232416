import React, { useEffect, useState, useContext } from "react"
import MetaTags from "react-meta-tags"
import { get, post } from "helpers/api_helper"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { Col, Card, CardBody, Row, Button } from "reactstrap"
import dayjs from "dayjs"
import { getOrderStatus } from "utils/helpers/order"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import Autosuggest from "react-autosuggest"
import ConfirmationModal from "components/Common/Modals/ConfirmationModal"
import SpinnerLoader from "components/Loader/SpinnerLoader"

const ImagePreview = ({ link }) => {
  return (
    <div className="p-2">
      <a href={link} target="_blank">
        <img src={link} width={100} height={100} />
      </a>
    </div>
  )
}
const ViewOrder = props => {
  const [order, setOrder] = useState({})
  const [deliver_guys, setDeliverGuys] = useState({})
  const [loading, setLoading] = useState(false)
  const [deliverGuy, setDeliverGuy] = useState({})
  const customAlert = useContext(CustomAlertContext)
  const [showCancelModal, setShowCancelModal] = useState(false)

  const [suggestions, setSuggestions] = useState([])
  const [value, setValue] = useState("")
  useEffect(() => {
    setLoading(true)
    get(`order/${props.match.params.id}`).then(res => {
      const { status, result } = res
      setOrder(result)
      getDeliverGuys()
      setLoading(false)
    })
  }, [])

  const getDeliverGuys = () => {
    get("to-assign-deliverGuys").then(res => {
      const { status, result } = res
      let deliverGuys =
        result.length &&
        result.map(dg => {
          dg.name = `${dg.first_name} ${dg.last_name || ""}`
          return dg
        })
      setDeliverGuys(deliverGuys)
    })
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    return inputLength === 0
      ? []
      : deliver_guys.filter(
          lang => lang.name.toLowerCase().slice(0, inputLength) === inputValue
        )
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = suggestion => suggestion.name

  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => (
    <div className="d-flex justify-content-between">
      <span>{suggestion.name}</span>
      <span>
        {suggestion.contact_number}{" "}
        <span
          className={`dot ${suggestion.online_status ? "online" : "offline"}`}
        />
      </span>
    </div>
  )

  const onChange = (event, { newValue }) => {
    setValue(newValue)
  }

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setDeliverGuy(suggestion)
  }
  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: "Type Driver Name",
    value,
    onChange: onChange,
    className: "form-control",
  }
  const markDelivered = () => {
    post("order-status", {
      order_id: order._id,
      status: 3,
    })
      .then(res => {
        const { result } = res
        customAlert.notify(res.message)
        result ? setOrder({ ...order, status: 3 }) : ""
      })
      .catch(error => {
        customAlert.notify(error.response.data.message, "error")
      })
  }

  const toggleCancelModal = () => {
    setShowCancelModal(!showCancelModal)
  }

  const handleConfirmOrderDelete = () => {
    post("order-status", {
      order_id: order._id,
      status: 4,
    })
      .then(res => {
        const { result, message } = res
        customAlert.notify(message)
        if (result) {
          setOrder({ ...order, status: 4 })
        }
      })
      .catch(error => {
        customAlert.notify(error.response.data.message, "error")
      })
      .finally(() => {
        toggleCancelModal()
      })
  }

  const handleAssignDriver = () => {
    let data = {
      order_id: props.match.params.id,
      deliverGuy,
    }
    post(`assign-driver`, data)
      .then(res => {
        const { result } = res
        customAlert.notify(res.message)
        setOrder(result)
        setValue("")
      })
      .catch(error => {
        customAlert.notify(error.response.data.message, "error")
      })
  }

  const getAddressLine = key => {
    let addressLine = ""

    if (order[key].building_name) {
      addressLine += "Building - " + order[key].building_name + ", "
    }
    if (order[key].unit_number) {
      addressLine += "Block - " + order[key].unit_number + ", "
    }
    if (order[key].floor_number) {
      addressLine += "Floor - " + order[key].floor_number + ", "
    }
    if (order[key].landmark) {
      addressLine += "Landmark - " + order[key].landmark + ", "
    }

    addressLine += order[key].address

    return addressLine
  }

  const getAddressLink = key => {
    let link = ""
    if (order[key].lat && order[key].lng) {
      link = `https://www.google.com/maps/search/?api=1&query=${order[key].lat}%2C${order[key].lng}`
    }
    return link ? (
      <a href={link} target="_blank">
        <i className="mdi mdi-map-marker" />
      </a>
    ) : null
  }

  const pickup_address = order.pickup_address ? (
    <address>
      <strong>Pickup Address:</strong>
      <div>Sender Name: {order.pickup_address.sender_name}</div>
      <div>
        Address{getAddressLink("pickup_address")}:{" "}
        {getAddressLine("pickup_address")}
      </div>
      <div>Contact Number: {order.pickup_address.contact_number}</div>
    </address>
  ) : null

  const delivery_address = order.delivery_address ? (
    <address>
      <strong>Delivery Address</strong>
      <div>Recipient Name: {order.delivery_address.recipient_name}</div>
      <div>
        Address{getAddressLink("delivery_address")}:{" "}
        {getAddressLine("delivery_address")}
      </div>
      <div>Contact Number: {order.delivery_address.contact_number}</div>
    </address>
  ) : null

  const customer_details = order.customer_details ? (
    <address>
      <strong>Customer Details</strong>
      <div>
        <a
          href={`/customers/view/${order.customer_details._id}`}
          target="blank"
        >
          Customer Name:{" "}
          {`${order.customer_details.first_name} ${
            order.customer_details.last_name || ""
          }`}
        </a>
      </div>
      <div>
        <a href={`tel:${order.customer_details.contact_number}`}>
          Contact Number: {order.customer_details.contact_number}
        </a>
      </div>
    </address>
  ) : null

  const assigned_rider = order.assigned ? (
    <address>
      <strong>Delivery Guy Details</strong>
      <div>
        <a
          href={`/deliver-guys/view/${order.assigned.deliverGuy_id}`}
          target="blank"
        >
          Delivery Guy Name: {order.assigned.full_name}
        </a>
      </div>
      <div>Vehicle:{order.assigned.vehicle}</div>
      <div>Vehicle Number:{order.assigned.vehicle_no}</div>
      <div>
        <a href={`tel:${order.assigned.contact_number}`}>
          Contact Number: {order.assigned.contact_number}
        </a>
      </div>
    </address>
  ) : null

  return (
    <>
      <ConfirmationModal
        toggleModal={toggleCancelModal}
        isOpenModal={showCancelModal}
        deleteRecord={handleConfirmOrderDelete}
        title={"Cancel Order #" + order.order_track_id}
        text={
          order.status == 2
            ? "Order is already PICKED UP by the rider. Do you really want to cancel this order"
            : "Do you really want to cancel this order"
        }
        extraInfo={
          "*In case of cancellation by admin, a full refund will be processed to the customer."
        }
      />
      <div className="page-content">
        <MetaTags>
          <title>Orders | GoFetchIt</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumb
            breadcrumb1={{ title: "Orders", href: "/orders" }}
            breadcrumbItem="View Order"
          />
          {loading ? (
            <SpinnerLoader/>
          ) : (
            <Col>
              <Card>
                <CardBody>
                  {[0, 1, 2].includes(order.status) ? ( //        * 0 => in-cart, * 1 => "confirmed", * 2 => "in-process", * 3 => "delivered, * 4 => "cancelled"
                    <Row>
                      {[0, 1].includes(order.status) ? (
                        <>
                          <Col md={3}>
                            <Autosuggest
                              suggestions={suggestions}
                              onSuggestionsFetchRequested={
                                onSuggestionsFetchRequested
                              }
                              onSuggestionsClearRequested={
                                onSuggestionsClearRequested
                              }
                              getSuggestionValue={getSuggestionValue}
                              renderSuggestion={renderSuggestion}
                              inputProps={inputProps}
                              onSuggestionSelected={onSuggestionSelected}
                            />
                          </Col>
                          <Col md={2}>
                            <Button
                              className="primary"
                              onClick={handleAssignDriver}
                            >
                              Assign Driver
                            </Button>
                          </Col>
                        </>
                      ) : null}
                      {[0, 1, 2].includes(order.status) ? (
                        <Col
                          md={order.status == 2 ? 12 : 7}
                          className="d-flex justify-content-md-end"
                        >
                          <Button color="secondary" onClick={toggleCancelModal}>
                            Cancel?
                          </Button>
                        </Col>
                      ) : null}
                    </Row>
                  ) : null}
                  <Row>
                    <div className="invoice-title pt-2 d-flex align-items-center justify-content-between">
                      <h4>Status: {getOrderStatus(order.status)}</h4>
                      <h4 className="font-size-16">
                        <strong>Order Track ID: #{order.order_track_id}</strong>
                      </h4>
                    </div>
                    <hr />
                  </Row>
                  <Row>
                    <Col md={6} sm={12}>
                      {pickup_address}
                    </Col>
                    <Col md={6} sm={12} className="text-end">
                      {delivery_address}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={12}>
                      {customer_details}
                    </Col>
                    <Col md={6} sm={12} className="text-end">
                      {assigned_rider}
                    </Col>
                  </Row>
                  <Row>
                    <div className="col-6 mt-4">
                      <address>
                        <div>
                          <strong>Pickup Date: </strong>
                          {order.time_slot
                            ? dayjs(order.time_slot).format(
                                "MMMM DD, YYYY hh:mm A"
                              )
                            : "N/A"}
                        </div>
                        {getOrderStatus(
                          "light",
                          order.is_scheduled ? "Schedule" : "Same-Day"
                        )}
                      </address>
                    </div>
                    <div className="col-6 mt-4 text-end">
                      <address>
                        <div>
                          <strong>Delivery Date:</strong>
                          {order.delivery_time
                            ? dayjs(order.delivery_time).format(
                                "MMMM DD, YYYY hh:mm A"
                              )
                            : "N/A"}
                        </div>
                      </address>
                    </div>
                    <br />
                  </Row>
                  <Row>
                    <div className="col-6 mt-4">
                      <address>
                        <strong>Package Details:</strong>
                        <div>
                          Package Type:
                          {order.pickup_address &&
                            order.pickup_address.package_type}
                        </div>
                        <div>
                          Package Value: {order.amount?.currency || "ZAR"}
                          {order.package_value}
                        </div>
                        <br />
                      </address>
                    </div>
                    <div className="col-6 mt-4 text-end">
                      <address>
                        <strong>Order Date:</strong>
                        <br />
                        {dayjs(order.createdAt).format("MMMM DD, YYYY")}
                        <br />
                        {order.amount && order.amount.promo_code != "null" ? (
                          <>
                            <strong>Discount Details:</strong>
                            <div>
                              Promo Code:{" "}
                              {order.amount.promo_code != "null"
                                ? order.amount.promo_code
                                : "N/A"}
                            </div>
                            <div>
                              Coupon Discount: {order.amount.coupon_discount}
                            </div>
                            <div>
                              Applied Discount: {order.amount.applied_discount}
                            </div>
                            <div>Coupon Type: {order.amount.coupon_type}</div>
                            <div>
                              Discount Type: {order.amount.discount_type}
                            </div>
                          </>
                        ) : null}
                      </address>
                    </div>
                  </Row>
                  <Row>
                    <label>Package Photos</label>
                    {order.package_photos_url &&
                    order.package_photos_url.length ? (
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {order.package_photos_url.map((item, i) => (
                          <ImagePreview link={item} key={i} />
                        ))}
                      </div>
                    ) : (
                      <label>N/A</label>
                    )}
                  </Row>
                  <Row>
                    <Col md={6}>
                      <label>Pickup Verification Photo: </label>
                      {order.pickup_verify_image_url ? (
                        <ImagePreview
                          link={order.pickup_verify_image_url}
                          key="Pickup Verification Photo"
                        />
                      ) : (
                        " N/A"
                      )}
                    </Col>
                    <Col md={6} className="text-end">
                      <label>Delivery Verification Photo: </label>
                      {order.verify_image_url ? (
                        <ImagePreview
                          link={order.verify_image_url}
                          key="Delivery Verification Photo"
                        />
                      ) : (
                        " N/A"
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <div className="col-12">
                      <div>
                        <div className="p-2">
                          <h3 className="font-size-16">
                            <strong>Order Summary</strong>
                          </h3>
                        </div>
                        <div className="">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <td>
                                    <strong>Total Amount</strong>
                                  </td>
                                  <td>
                                    <strong>From Wallet</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Applied Discount</strong>
                                  </td>
                                  <td className="text-end">
                                    <strong>Totals</strong>
                                  </td>
                                </tr>
                              </thead>
                              {order.amount ? (
                                <tbody>
                                  <tr>
                                    <td>
                                      {(order.amount?.currency || "ZAR") +
                                        " " +
                                        order.amount?.total_amount}
                                    </td>
                                    <td>
                                      {(order.amount?.currency || "ZAR") +
                                        " " +
                                        order.amount?.wallet_amount}
                                    </td>
                                    <td className="text-center">
                                      {(order.amount?.currency || "ZAR") +
                                        " " +
                                        (order.amount?.applied_discount || 0)}
                                    </td>
                                    <td className="text-end">
                                      {(order.amount?.currency || "ZAR") +
                                        " " +
                                        (order.amount?.payment || 0)}
                                    </td>
                                  </tr>
                                </tbody>
                              ) : null}
                            </table>
                          </div>
                          <div className="d-print-none">
                            <div className="float-end">
                              {/* <Link
                                to="#"
                                onClick={() => {
                                  window.print()
                                }}
                                className="btn btn-success waves-effect waves-light"
                              >
                                <i className="fa fa-print"></i>
                              </Link>{" "} */}
                              {[1, 2].includes(order.status) ? (
                                <Button
                                  color="primary"
                                  onClick={markDelivered}
                                  className="mb-1"
                                >
                                  Mark as Delivered?
                                </Button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          )}
        </div>
      </div>
    </>
  )
}

export default ViewOrder
