import React, { useEffect, useState, useContext } from "react"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { post, put } from "helpers/api_helper"
// import { CountryDropdown } from "react-country-region-selector"
import { useFormik } from "formik"
import * as Yup from "yup"
import { textField } from "components/Common/Form/InputFields"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { yupMessages } from "helpers/validations/messages"
import { formats } from "helpers/validations/regex"
const KycOptionForm = props => {
  const { disabled, kyc_option_id, data } = props
  const customAlert = useContext(CustomAlertContext)

  const handleSubmit = values => {
    let callApi
    if (kyc_option_id) {
      callApi = updateKycOption(values)
    } else {
      callApi = addKycOption(values)
    }
    callApi
      .then(res => {
        customAlert.notify(res.message)
        window.location.href = "/kyc-options"
      })
      .catch(err => {
        console.log(`err.response`, err.response)

        customAlert.notify(err.response.data.message)
      })
  }

  const addKycOption = data => post(`kyc-options`, data)
  const updateKycOption = data => put(`kyc-options/${kyc_option_id}`, data)

  const KycOptionSchema = Yup.object().shape({
    name: Yup.string()
      .strict()
      .trim(`Name ${yupMessages.trim}`)
      .required(`Name ${yupMessages.is_required}`)
      .matches(formats.string, `Name ${yupMessages.alpha_space}`),
  })
  const formik = useFormik({
    initialValues: {
      name: data ? data.name : "",
    },
    validationSchema: KycOptionSchema,
    onSubmit: values => {
      handleSubmit(values)
    },
  })

  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Row>
                  <Col lg={12}>
                    {textField(formik, "name", "Name*", "Enter KYC Option")}
                  </Col>
                </Row>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <Button type="submit" color="primary" className="">
                  Submit
                </Button>{" "}
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default KycOptionForm
