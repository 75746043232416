import React, { useEffect, useState, useContext } from "react"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { post, put } from "helpers/api_helper"
import CustomImageInput from "components/Common/CustomImageUpload"
import { selectField, textField } from "components/Common/Form/InputFields"
import { useFormik } from "formik"
import * as Yup from "yup"
import { CustomAlertContext } from "utils/context/CustomAlertContext"

const orderSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  vehicle: Yup.string().required("Required"),
  distance: Yup.string().required("Required"),
  pickup_address: Yup.object({
    sender_name: Yup.string().required(),
    contact_number: Yup.number().required(),
    address: Yup.string(),
    package_type: Yup.string().required(),
  }),
  delivery_address: Yup.object({
    recipient_name: Yup.string().required(),
    contact_number: Yup.number().required(),
    address: Yup.string(),
  }),
  pickup_date: Yup.string().required("Required"),
  delivery_date: Yup.string(),
  payment_method: Yup.string().required("Required"),
  package_value: Yup.number().required("Required"),
  applied_coupon: Yup.string(),
  package: Yup.object({
    package_size: Yup.string().required(),
    package_weight: Yup.string(),
  }),
  amount: Yup.object({
    coupon_discount: Yup.number(),
    shipping_cost: Yup.number(),
    tax: Yup.number(),
    order_price: Yup.number().required(),
    total_amount: Yup.number().required(),
    coupon_type: Yup.string(),
  }),
  package_photos: Yup.mixed()
    .required("Required")
    .test("fileFormat", "forms.errors.unsupportedFormat", value => {
      return value && value.length
        ? value.every(file =>
            typeof file != "string"
              ? SUPPORTED_FORMATS.includes(file.type)
              : true
          )
        : true
    }),
})

let SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  ".jpg",
]
const OrderForm = props => {
  const { disabled, order_id, data } = props
  const customAlert = useContext(CustomAlertContext)

  const handleSubmit = values => {
    let formData = new FormData()
    formData.append("title", values.title)
    formData.append("vehicle", values.vehicle)
    formData.append("distance", values.distance)
    formData.append("pickup_address", JSON.stringify(values.pickup_address))
    formData.append("delivery_address", JSON.stringify(values.delivery_address))
    formData.append("amount", JSON.stringify(values.amount))
    formData.append("pickup_date", values.pickup_date)
    formData.append("delivery_date", values.delivery_date)
    formData.append("payment_method", values.payment_method)
    formData.append("package_value", values.package_value)
    formData.append("applied_coupon", values.applied_coupon)
    formData.append("package", JSON.stringify(values.package))
    formData.append("assigned", JSON.stringify(values.assigned))
    formData.append("status", "pending")

    values.package_photos.length &&
      Array.from(values.package_photos).map((f, i) => {
        return typeof f == "object"
          ? formData.append("package_photos", f)
          : null
      })
    let callApi
    if (order_id) {
      callApi = updateOrder(formData)
    } else {
      callApi = addOrder(formData)
    }

    callApi
      .then(res => {
        customAlert.notify(res.message)
        // window.location.href = "/orders"
      })
      .catch(err => {
        console.log(`err`, err)

        customAlert.notify(err.response.data.message)
      })
  }

  const addOrder = data => post(`orders`, data)

  const updateOrder = data => put(`order/${order_id}`, data)

  const formik = useFormik({
    initialValues: {
      package: data ? data.package : {},
      package_photos: data ? data.package_photos : [],

      pickup_address: data ? data.pickup_address : {},
      delivery_address: data ? data.delivery_address : {},
      amount: data ? data.amount : {},

      pickup_date: data ? data.pickup_date : "",
      payment_method: data ? data.payment_method : "",

      package_value: data ? data.package_value : "",

      applied_coupon: data ? data.applied_coupon : "",
      title: data ? data.title : "",
      vehicle: data ? data.vehicle : "",
    },
    validationSchema: orderSchema,
    onSubmit: values => {
      handleSubmit(values)
    },
  })
  let payment_options = [
    { name: "COD", value: "COD" },
    { name: "COP", value: "COP" },
    { name: "Wallet", value: "wallet" },
  ]
  let vehicle_options = [
    { name: "bike", value: "bike" },
    { name: "tata-ace", value: "tata-ace" },
    { name: "3-wheeler", value: "3-wheeler" },
  ]
  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Row>
                  <Col md={6}>
                    {textField(formik, "title", "Title", "Enter your Title")}
                  </Col>
                  <Col md={6}>
                    {textField(
                      formik,
                      "distance",
                      "Distance",
                      "Enter your Distance"
                    )}
                  </Col>
                  <Col md={6}>
                    {selectField(
                      formik,
                      "vehicle",
                      "Vehicle",
                      vehicle_options,
                      "Select Vehicle"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Label>Pickup Address</Label>
                <Row>
                  <Col md={6}>
                    {textField(
                      formik,
                      "pickup_address.sender_name",
                      "Sender Name",
                      "Enter your Sender Name",
                      false,
                      "text",
                      { p_name: "pickup_address", sub_name: "sender_name" }
                    )}
                  </Col>
                  <Col md={6}>
                    {textField(
                      formik,
                      "pickup_address.contact_number",
                      "Sender Contact Number",
                      "Enter your Sender Contact Number",
                      false,
                      "number",
                      { p_name: "pickup_address", sub_name: "contact_number" }
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {textField(
                      formik,
                      "pickup_address.address",
                      "Pickup Address",
                      "Enter your Pickup Address",
                      false,
                      "text",
                      { p_name: "pickup_address", sub_name: "address" }
                    )}
                  </Col>
                  <Col md={6}>
                    {textField(
                      formik,
                      "pickup_address.package_type",
                      "Package Type",
                      "Enter your Package Type",
                      false,
                      "text",
                      { p_name: "pickup_address", sub_name: "package_type" }
                    )}
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Label>Delivery Address</Label>

                <Row>
                  <Col md={6}>
                    {textField(
                      formik,
                      "delivery_address.recipient_name",
                      "Recipient Name",
                      "Enter your Recipient Name",
                      false,
                      "text",
                      {
                        p_name: "delivery_address",
                        sub_name: "recipient_name",
                      }
                    )}
                  </Col>
                  <Col md={6}>
                    {textField(
                      formik,
                      "delivery_address.contact_number",
                      "Delivery Contact Number",
                      "Enter your Delivery Contact Number",
                      false,
                      "number",
                      {
                        p_name: "delivery_address",
                        sub_name: "contact_number",
                      }
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {textField(
                      formik,
                      "delivery_address.address",
                      "Delivery Address",
                      "Enter your Delivery Address",
                      false,
                      "text",
                      {
                        p_name: "delivery_address",
                        sub_name: "address",
                      }
                    )}
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col md={6} className="mb-3">
                    {textField(
                      formik,
                      "pickup_date",
                      "Pickup date",
                      "Enter your Pickup date"
                    )}
                  </Col>
                  <Col md={6} className="mb-3">
                    {textField(
                      formik,
                      "delivery_date",
                      "Delivery date",
                      "Enter your Delivery date"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Row>
                  <Col xl={6}>
                    {selectField(
                      formik,
                      "payment_method",
                      "Payment Method",
                      payment_options,
                      "Select Payment Method"
                    )}
                  </Col>
                  <Col xl={6}>
                    {textField(
                      formik,
                      "package_value",
                      "Package Value",
                      "Enter your Package Value",
                      false,
                      "number"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Row>
                  <Col md={6}>
                    {textField(
                      formik,
                      "applied_coupon",
                      "Applied Coupon",
                      "Enter your Applied Coupon"
                    )}
                  </Col>
                  <Col md={6}>
                    {textField(
                      formik,
                      "amount.coupon_discount",
                      "Coupon Discount",
                      "Enter your Coupon Discount",
                      false,
                      "number",
                      {
                        p_name: "amount",
                        sub_name: "coupon_discount",
                      }
                    )}
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Label>Package</Label>

                <Row>
                  <Col md={6}>
                    {textField(
                      formik,
                      "package.package_size",
                      "Package Size",
                      "Enter your Package Size",
                      false,
                      "text",
                      {
                        p_name: "package",
                        sub_name: "package_size",
                      }
                    )}
                  </Col>
                  {/* <Col md={6}>
                      {textField(
                        formik,
                        "package.package_weight",
                        "Package Weight",
                        "Enter your Package Weight",
                        false,
                        "text",
                        {
                          p_name: "package",
                          sub_name: "package_weight",
                        }
                      )}
                    </Col> */}
                </Row>
              </div>
              <div className="mb-3">
                <Label>Amount</Label>
                <Row>
                  <Col xl={3}>
                    {textField(
                      formik,
                      "amount.shipping_cost",
                      "Shipping Cost",
                      "Enter your Shipping Cost",
                      false,
                      "number",
                      {
                        p_name: "amount",
                        sub_name: "shipping_cost",
                      }
                    )}
                  </Col>
                  <Col xl={3}>
                    {textField(
                      formik,
                      "amount.tax",
                      "Tax",
                      "Enter your Tax",
                      false,
                      "number",
                      {
                        p_name: "amount",
                        sub_name: "tax",
                      }
                    )}
                  </Col>{" "}
                  <Col xl={3}>
                    {textField(
                      formik,
                      "amount.order_price",
                      "Order Price",
                      "Enter your Order Price",
                      false,
                      "number",
                      {
                        p_name: "amount",
                        sub_name: "order_price",
                      }
                    )}
                  </Col>{" "}
                  <Col xl={3}>
                    {textField(
                      formik,
                      "amount.total_amount",
                      "Total Amount",
                      "Enter your Total Amount",
                      false,
                      "number",
                      {
                        p_name: "amount",
                        sub_name: "total_amount",
                      }
                    )}
                  </Col>
                </Row>
              </div>
              <CustomImageInput
                formik={formik}
                label={"Package files"}
                name={"package_photos"}
                data={data ? data.package_photos : false}
                multiple
                required={false}
              />

              {disabled ? null : (
                <div className="d-flex flex-wrap gap-2">
                  <Button type="submit" color="primary" className="">
                    Submit
                  </Button>{" "}
                </div>
              )}
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default OrderForm
