import React from "react"
import { Modal } from "reactstrap"

const ConfirmationModal = ({
  isOpenModal,
  toggleModal,
  deleteRecord,
  title,
  text = "Are you sure you want to delete this",
  extraInfo = "",
}) => {
  return (
    <Modal isOpen={isOpenModal} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={toggleModal}
        ></button>
      </div>
      <div className="modal-body">
        <p>{text}?</p>
        {extraInfo ? <p><small>{extraInfo}</small></p> : null}
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={deleteRecord}
        >
          Confirm
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={toggleModal}
        >
          Cancel
        </button>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
