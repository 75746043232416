import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { get } from "helpers/api_helper"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { Col, Card, CardBody, Row } from "reactstrap"
import SpinnerLoader from "components/Loader/SpinnerLoader"

const ViewWithdrawalRequest = props => {
  const [withdrawal, setWithdrawal] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    get(`customer-support/${props.match.params.id}`).then(res => {
      const { status, result } = res
      setWithdrawal(result)
      setLoading(false)
    })
  }, [])
  return (
    <div className="page-content">
      <MetaTags>
        <title>Withdrawals | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{
            title: "Withdrawals",
            href: "/withdrawal",
          }}
          breadcrumbItem="View Withdrawal"
        />
        {loading ? (
          <SpinnerLoader/>
        ) : (
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col md={6} sm={12}>
                    <h5 className="mb-1">Id no: #{withdrawal._id}</h5>
                  </Col>

                  <Col md={6} sm={12}>
                    <h5 className="mb-1">
                      Name:{" "}
                      {`${withdrawal.first_name} ${
                        withdrawal.last_name || ""
                      } `}
                    </h5>
                  </Col>
                  <Col md={6} sm={12}>
                    <h5 className="mb-1">Email: {withdrawal.email}</h5>
                  </Col>

                  <Col md={6} sm={12}>
                    <h5 className="mb-1">
                      Contact No: {withdrawal.contact_number}
                    </h5>
                  </Col>

                  <Col md={12} sm={12}>
                    <h5 className="mb-1">Message: {withdrawal.message}</h5>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        )}
      </div>
    </div>
  )
}

export default ViewWithdrawalRequest
