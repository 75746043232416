import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Table, Tbody, Td, Tr } from "react-super-responsive-table"
import { Pagination } from "components/Common/Pagination"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { get } from "helpers/api_helper"
import NoDataAvailable from "components/Common/NoDataAvailable"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
import { CustomSearch } from "components/Common/CustomSearch"
import { starRating } from "../../utils/helpers/reviews"
import { TableRowsHoc } from "components/Common/TableRowsHoc"

import NoData from "components/NoData/NoData"
import SpinnerLoader from "components/Loader/SpinnerLoader"

const Reviews = () => {
  const [reviewData, setReviewData] = useState([])
  const [loading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({ page_no: 1, limit: 20 })
  const [search, setSearch] = useState("")

  useEffect(() => {
    getReviews()
  }, [])

  const getReviews = (page = pagination) => {
    setIsLoading(true)
    get("review", { params: page }).then(res => {
      const { status, result } = res
      result.data.map(data => {
        data.rating
          ? (data.rating = starRating(data.rating))
          : (data.rating = "")
        data.app_rating
          ? (data.app_rating = starRating(data.app_rating))
          : (data.app_rating = "")
        data.time_rating
          ? (data.time_rating = starRating(data.time_rating))
          : (data.time_rating = "")

        return data
      })
      setReviewData(result.data)
      setPagination(result.meta_data)
      setIsLoading(false)
    })
  }

  // const getOrderTrackId = order => (order ? `#${order[0].order_track_id}` : "")
  const getOrderTrackId = key => `#${key}`

  let fields = [
    {
      column: "Order Track ID",
      key: "order_track_id",
      value: getOrderTrackId,
    },
    { column: "Rating", key: "rating" },
    { column: "App rating", key: "app_rating" },
    { column: "Timeliness", key: "time_rating" },

    { column: "Review", key: "review" },
  ]
  const getRows = () => (
    <TableRowsHoc length={reviewData.length}>
      {reviewData.map((review, i) => (
        <CustomRow
          fields={fields}
          data={review}
          section={"reviews"}
          action={{ view: true }}
          status={false}
          key={review._id}
          index={i}
          pagination={pagination}
        />
      ))}
    </TableRowsHoc>
  )
  return (
    <div className="page-content">
      <MetaTags>
        <title>Reviews | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb title="GoFetchIt" breadcrumbItem="Reviews" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CustomSearch
                  getData={getReviews}
                  pagination={pagination}
                  setSearch={setSearch}
                  placeholder={"Order Track ID"}
                />
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    {loading ? (
                      <SpinnerLoader/>
                    ) : reviewData ? (
                      <Table className="table table-striped table-bordered">
                        <CustomColumn fields={fields} status={false} />
                        <Tbody>{getRows()}</Tbody>
                      </Table>
                    ) : (
                      <NoDataAvailable module="reviews" />
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      {pagination.total_pages && pagination.total_pages > 1 ? (
        <Pagination
          pagination={pagination}
          getData={getReviews}
          search={search}
        />
      ) : null}
    </div>
  )
}

export default Reviews
