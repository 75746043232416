import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Table, Tbody } from "react-super-responsive-table"

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
// import "../Tables/datatables.scss"
import { get } from "helpers/api_helper"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
import { TableRowsHoc } from "components/Common/TableRowsHoc"
import CustomSpinner from "components/CustomSpinner"

let fields = [
  { column: "Name", key: "name" },
  { column: "Language", key: "language_code", className: "text-uppercase" },
]

const Pages = () => {
  const [pageData, setPageData] = useState([])
  const [loading, setIsLoading] = useState(false)

  useEffect(() => {
    getPages()
  }, [])

  const getPages = () => {
    setIsLoading(true)
    get("pages").then(res => {
      const { result } = res
      setPageData(result)
      setIsLoading(false)
    })
  }

  const getRows = () => (
    <TableRowsHoc length={pageData.length}>
      {pageData.map((page, i) => (
        <CustomRow
          fields={fields}
          data={page}
          section={"pages"}
          action={{
            edit: true,
            view: true,
          }}
          status={false}
          key={page._id}
          index={i}
        />
      ))}
    </TableRowsHoc>
  )
  return (
    <div className="page-content">
      <MetaTags>
        <title>Pages | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb breadcrumbItem="Pages" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Table className="table table-striped table-bordered">
                  <CustomColumn fields={fields} status={false} />
                  <Tbody>
                    {loading ? (
                      <CustomSpinner span={fields.length + 3} />
                    ) : (
                      getRows()
                    )}
                  </Tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Pages
