import RichTextEditor from "./RichTextEditor"

export const textField = (
  formik,
  name,
  label,
  placeholder = false,
  className = false,
  type = "text",
  obj = false,
  disabled = false
) => {
  let field_name = name
  let field_value = formik.values[name]
  let field_error = formik.errors ? formik.errors[name] : ""
  if (obj && typeof obj.index == "number") {
    name = `${obj.p_name}[${obj.index}].${[obj.sub_name]}`

    field_value =
      formik.values[obj.p_name] && formik.values[obj.p_name][obj.index]
        ? formik.values[obj.p_name][obj.index][obj.sub_name]
        : field_value
    field_error =
      formik.errors &&
        formik.errors[obj.p_name] &&
        formik.errors[obj.p_name][obj.index]
        ? formik.errors[obj.p_name][obj.index][obj.sub_name]
        : field_error
  } else if (obj && !obj.index) {
    name = [obj.p_name][obj.sub_name]
    field_value = formik.values[obj.p_name]
      ? formik.values[obj.p_name][obj.sub_name]
      : field_value
    field_error =
      formik.errors && formik.errors[obj.p_name]
        ? formik.errors[obj.p_name][obj.sub_name]
        : field_error
  }

  return (
    <div className="mb-3">
      <label htmlFor={field_name}>{label}</label>
      <input
        name={field_name}
        type={type}
        onChange={formik.handleChange}
        value={field_value}
        placeholder={placeholder}
        className="form-control "
        min={0}
        disabled={disabled}
        autoComplete="new-password"
      />
      <span className="error">{field_error}</span>
    </div>
  )
}

export const richTextField = (
  formik,
  name,
  label,
  placeholder = false,
) => (<div className="mb-3">
  <RichTextEditor
    formik={formik}
    name={name}
    placeholder={placeholder}
    className="form-control"
    label={label}
  />
</div>)

export const selectField = (
  formik,
  name,
  label,
  options,
  placeholder = false,
  className = false,
  disable = false
) => {
  return (
    <div className="mb-3">
      <label htmlFor={name}>{label}</label>
      <select
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="form-control"
        disabled={disable}
      >
        <option value="">{placeholder}</option>
        {options.map(op => (
          <option value={op.value} disabled={op.disabled || false}>{op.name}</option>
        ))}
      </select>
      {formik.errors[name] && (
        <span className="error">{formik.errors[name]}</span>
      )}
    </div>
  )
}
