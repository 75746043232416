import React, { useContext } from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { post, put } from "helpers/api_helper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { richTextField, textField } from "components/Common/Form/InputFields"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { yupMessages } from "helpers/validations/messages"
import { selectField } from "components/Common/Form/InputFields"

let language_options = [
  { name: "Arabic", value: "ar" },
  { name: "English", value: "en" },
]

const PageForm = props => {
  const { disabled, page_id, data = {} } = props
  const customAlert = useContext(CustomAlertContext)

  const handleSubmit = values => {
    let callApi
    if (page_id) {
      callApi = updatePage(values)
    } else {
      callApi = addPage(values)
    }

    callApi
      .then(res => {
        customAlert.notify(res.message)
        window.location.href = "/pages"
      })
      .catch(err => {
        console.log(`err.response`, err.response)

        customAlert.notify(err.response.data.message)
      })
  }
  const addPage = data => post(`pages`, data)

  const updatePage = data => put(`page/${page_id}`, data)
  // let required_string = Yup.string()
  //   .strict()
  //   .trim()
  //   .required(yupMessages.required)

  // const PageSchema = Yup.object().shape({
  //   name: required_string,
  //   content: required_string,
  //   meta_name: required_string,
  //   meta_description: required_string,
  //   meta_keywords: required_string,
  // })

  const PageSchema = Yup.object().shape({
    // name: required_string,
    name: Yup.string(`Name ${yupMessages.string_base}`)
      .strict()
      .trim(`Name ${yupMessages.trim}`)
      .required(`Name ${yupMessages.is_required}`),
    content: Yup.string(`Content ${yupMessages.string_base}`)
      .strict()
      .trim(`Content ${yupMessages.trim}`)
      .required(`Content ${yupMessages.is_required}`),
    meta_name: Yup.string(`Meta name ${yupMessages.string_base}`)
      .strict()
      .trim(`Meta name ${yupMessages.trim}`)
      .required(`Meta name ${yupMessages.is_required}`),
    meta_description: Yup.string(`Meta description ${yupMessages.string_base}`)
      .strict()
      .trim(`Meta description ${yupMessages.trim}`)
      .required(`Meta description ${yupMessages.is_required}`),
    meta_keywords: Yup.string(`Meta keywords ${yupMessages.string_base}`)
      .strict()
      .trim(`Meta keywords ${yupMessages.trim}`)
      .required(`Meta keywords ${yupMessages.is_required}`),
    language_code: Yup.string(`Language ${yupMessages.string_base}`)
  })

  const formik = useFormik({
    initialValues: {
      name: data.name || "",
      slug: data.slug || "",
      content: data.content || "",
      meta_name: data.meta_name || "",
      meta_description: data.meta_description || "",
      meta_keywords: data.meta_keywords || "",
      language_code: data.language_code || "en",
    },
    validationSchema: PageSchema,
    onSubmit: values => {
      handleSubmit(values)
    },
  })

  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={6}>
                  {textField(
                    formik,
                    "name",
                    "Name",
                    "Enter Page Name*",
                    false,
                    "text",
                    false,
                    data._id ? true : false
                  )}
                </Col>
                <Col lg={6}>
                  {textField(
                    formik,
                    "meta_name",
                    "Meta Name*",
                    "Enter Meta Name"
                  )}
                </Col>
              </Row>
              <div className="mb-3">
                {textField(
                  formik,
                  "meta_description",
                  "Meta Description*",
                  "Enter Meta Description"
                )}
              </div>
              <div className="mb-3">
                {textField(
                  formik,
                  "meta_keywords",
                  "Meta Keywords*",
                  "Enter Meta Keywords"
                )}
              </div>
              <div className="mb-3">
                {selectField(
                  formik,
                  "language_code",
                  "Language",
                  language_options
                )}
              </div>
              <div className="mb-3">
                {richTextField(
                  formik,
                  "content",
                  "Content*",
                  "Enter Page Content"
                )}
              </div>
              {disabled ? null : (
                <div className="d-flex flex-wrap gap-2">
                  <Button type="submit" color="primary" className="">
                    Submit
                  </Button>{" "}
                </div>
              )}
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default PageForm
