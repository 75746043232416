import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import Breadcrumb from "components/Common/Breadcrumb"
import { get } from "helpers/api_helper"
import SpinnerLoader from "components/Loader/SpinnerLoader"
import ServiceAreaForm from "components/Maps/ServiceAreaForm"

const EditServiceAreas = props => {
  const [serviceArea, setServiceArea] = useState({})
  const [loader, setLoader] = useState()

  useEffect(() => {
    setLoader(true)
    get(`service-areas/${props.match.params.id}`)
      .then(res => {
        const { status, result } = res
        setServiceArea(res.result)
        setLoader(false)
      })
      .catch(err => {
        setLoader(false)
      })
  }, [])

  return (
    <div className="page-content">
      <MetaTags>
        <title>Service Area | GoFetch</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Service Area", href: "/service-area" }}
          breadcrumbItem="Edit Service Area"
          breadcrumbItem1={serviceArea.title}
        />
        {loader ? (
          <SpinnerLoader />
        ) : (
          <ServiceAreaForm
            serviceArea={serviceArea}
            serviceArea_id={props.match.params.id}
          />
        )}
      </div>
    </div>
  )
}

export default EditServiceAreas
