import React, { useEffect, useState, useContext } from "react"
import MetaTags from "react-meta-tags"
import { Table, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { get, post } from "helpers/api_helper"
import NoDataAvailable from "components/Common/NoDataAvailable"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
import { Pagination } from "components/Common/Pagination"
import { CustomSearch } from "components/Common/CustomSearch"
import { getWithdrawalStatus } from "utils/helpers/transaction"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import NoData from "components/NoData/NoData"
import { TableRowsHoc } from "components/Common/TableRowsHoc"
import SpinnerLoader from "components/Loader/SpinnerLoader"

const WithdrawalRequests = () => {
  const [withdrawalRequestData, setWithdrawalData] = useState([])
  const [loading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({ page_no: 1, limit: 20 })
  const [search, setSearch] = useState("")
  const customAlert = useContext(CustomAlertContext)

  useEffect(() => {
    getWithdrawalRequests()
  }, [])

  const getWithdrawalRequests = (page = pagination) => {
    setIsLoading(true)
    get("withdrawal-requests", { params: page }).then(res => {
      const { status, result } = res
      setWithdrawalData(result.data)
      setPagination(result.meta_data)
      setIsLoading(false)
    })
  }

  const handleStatus = withdrawal_request_id => {
    let withdrawal_request = withdrawalRequestData.length
      ? withdrawalRequestData.find(x => x._id === withdrawal_request_id)
      : {}
    return (
      <span>
        {getWithdrawalStatus(withdrawal_request.status)}
        {withdrawal_request.status === 0 ? (
          <>
            <span
              className="send-reminder mx-2"
              onClick={handleRequestStatus(withdrawal_request_id, 1)}
            >
              Accept
            </span>
            <span
              className="send-reminder"
              onClick={handleRequestStatus(withdrawal_request_id, 2)}
            >
              Reject
            </span>
          </>
        ) : null}
      </span>
    )
  }

  const handleRequestStatus = (id, status) => () => {
    let data = {
      status,
    }
    post(`withdrawal-request/change-status/${id}`, { status })
      .then(res => {
        const { result } = res
        let withdrawal_request = [...withdrawalRequestData]
        let index = withdrawal_request.length
          ? withdrawal_request.findIndex(x => x._id === id)
          : -1

        index != -1 ? (withdrawal_request[index].status = status) : ""

        setWithdrawalData(withdrawal_request)
        customAlert.notify(res.message, "success")
      })
      .catch(err => {
        customAlert.notify(err.response.data.message, "error")
      })
  }
  const getViewCustomer = user =>
    user ? (
      <a href={`/customers/view/${user._id}`}>
        {user.first_name} {user.last_name}
      </a>
    ) : null

  const getTransactionAmount = (item, row) => {
    let amount = ""
    if (row.currency) {
      amount += row.currency + " "
    }
    amount += item
    return amount
  }

  let fields = [
    { column: "Name", key: "name" },
    { column: "Bank Code", key: "ifsc" },
    { column: "Amount", key: "amount", value: getTransactionAmount },
    {
      column: "Customer",
      key: "user_id",
      value: getViewCustomer,
    },
    { column: "Account No", key: "account_no" },
    { column: "Status", key: "_id", value: handleStatus },
  ]
  const getRows = () => (
    <TableRowsHoc length={withdrawalRequestData.length}>
      {withdrawalRequestData.map((withdrawalRequest, i) => (
        <CustomRow
          fields={fields}
          data={withdrawalRequest}
          section={"withdrawal-requests"}
          action={false}
          status={false}
          key={withdrawalRequest._id}
          index={i}
          pagination={pagination}
        />
      ))}
    </TableRowsHoc>
  )
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Withdrawal-Requests | GoFetchIt</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumb breadcrumbItem="Withdrawal-Requests" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CustomSearch
                    getData={getWithdrawalRequests}
                    pagination={pagination}
                    setSearch={setSearch}
                    placeholder={"IFSC, Name"}
                  />
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      {loading ? (
                        <SpinnerLoader/>
                      ) : withdrawalRequestData ? (
                        <Table className="table table-striped table-bordered">
                          <CustomColumn
                            fields={fields}
                            action={false}
                            status={false}
                          />
                          <Tbody>{getRows()}</Tbody>
                        </Table>
                      ) : (
                        <NoDataAvailable module="withdrawalRequests" />
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {pagination.total_pages && pagination.total_pages > 1 ? (
          <Pagination
            pagination={pagination}
            getData={getWithdrawalRequests}
            search={search}
          />
        ) : null}
      </div>
    </>
  )
}

export default WithdrawalRequests
