import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import CustomerForm from "components/Customers/CustomerForm"
const AddCustomer = props => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>Customers | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Customers", href: "/customers" }}
          breadcrumbItem="Add Customer"
        />
        <CustomerForm data={false} />{" "}
      </div>
    </div>
  )
}

export default AddCustomer
