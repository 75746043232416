import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { get } from "helpers/api_helper"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { Col, Card, CardBody, Row } from "reactstrap"
import SpinnerLoader from "components/Loader/SpinnerLoader"
const ViewPromoCode = props => {
  const [promoCode, setPromoCode] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    get(`promoCodes/${props.match.params.id}`).then(res => {
      const { status, result } = res
      setPromoCode(result)
      setLoading(false)
    })
  }, [])
  return (
    <div className="page-content">
      <MetaTags>
        <title>Promo Codes | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{
            title: "Promo Codes",
            href: "/promo-codes",
          }}
          breadcrumbItem="View Promo Code"
        />
        {loading ? (
          <SpinnerLoader/>
        ) : (
          <Card>
            <CardBody>
              <Row>
                <div className="col-12 fs-5">
                  <div className="invoice-title"></div>
                  <hr />
                  {/* <Row>
                    <Col md={6} sm={12}>
                      <h5 className="mb-1">Id no: #{promoCode._id}</h5>
                    </Col>
                    <Col md={6} sm={12}>
                      <h5>Coupon Code: {promoCode.promo_code}</h5>
                    </Col>

                    <Col md={6} sm={12}>
                      <h5>Start Date: {promoCode.start_date}</h5>
                    </Col>
                    <Col md={6} sm={12}>
                      <h5>End Date: {promoCode.end_date}</h5>
                    </Col>
                    <Col md={6} sm={12}>
                      <h5>Discount: {promoCode.discount}</h5>
                    </Col>
                    <Col md={6} sm={12}>
                      <h5>Discount Type: {promoCode.discount_type}</h5>
                    </Col>
                  </Row> */}
                  <Row>
                    <div className="col-6">
                      <address>
                        <strong>Id no:</strong>
                        <br />#{promoCode._id}
                      </address>
                    </div>
                    <div className="col-6 text-end">
                      <address>
                        <strong>Coupon Code:</strong>
                        <br />
                        {promoCode.promo_code}
                      </address>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-6">
                      <address>
                        <strong>Start Date:</strong>
                        <br />
                        {promoCode.start_date}
                      </address>
                    </div>
                    <div className="col-6 text-end">
                      <address>
                        <strong>End Date:</strong>
                        <br />
                        {promoCode.end_date}
                      </address>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-6">
                      <address>
                        <strong>Discount:</strong>
                        <br />
                        {promoCode.discount}
                      </address>
                    </div>
                    <div className="col-6 text-end">
                      <address>
                        <strong>Discount Type:</strong>
                        <br />
                        {promoCode.discount_type}
                      </address>
                    </div>
                  </Row>
                </div>
              </Row>
            </CardBody>
          </Card>
        )}
      </div>
    </div>
  )
}

export default ViewPromoCode
