import React, { useEffect, useState, useContext } from "react"
import MetaTags from "react-meta-tags"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { get, del } from "helpers/api_helper"
import NoDataAvailable from "components/Common/NoDataAvailable"
import ConfirmationModal from "components/Common/Modals/ConfirmationModal"
import { Pagination } from "components/Common/Pagination"
import { CustomSearch } from "components/Common/CustomSearch"
import SpinnerLoader from "components/Loader/SpinnerLoader"

const MyNotifications = () => {
  const [notificationData, setNotificationData] = useState([])
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [deleteData, setDeleteData] = useState(false)
  const [loading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({ page_no: 1, limit: 20 })
  const [search, setSearch] = useState("")

  const customAlert = useContext(CustomAlertContext)

  useEffect(() => {
    getNotifications()
  }, [])

  const getNotifications = (query = pagination) => {
    setIsLoading(true)
    get("notifications?admin=true", { params: query }).then(res => {
      const { status, result } = res
      setNotificationData(result.data)
      setPagination(result.meta_data)
      setIsLoading(false)
    })
  }

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  const openModal = (status, data) => {
    setIsOpenModal(status)
    setDeleteData(data)
  }
  const handleDelete = () => {
    del(`notification/${deleteData._id}`).then(res => {
      const { status, result } = res
      customAlert.notify(res.message)
      setDeleteData(false)
      setIsOpenModal(false)
      getNotifications()
    })
  }
  let fields = [
    { column: "Notification Type", key: "noti_type" },
    { column: "Title", key: "title" },
    { column: "Description", key: "description" },
  ]

  const getRows = () => {
    return notificationData.length
      ? notificationData.map((notification, i) => (
          <CustomRow
            fields={fields}
            data={notification}
            section={"notification"}
            action={false}
            status={false}
            key={notification._id}
            index={i}
            pagination={pagination}
          />
        ))
      : []
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>MyNotifications | GoFetchIt</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumb title="GoFetchIt" breadcrumbItem="MyNotifications" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CustomSearch
                    getData={getNotifications}
                    pagination={pagination}
                    setSearch={setSearch}
                  />
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      {loading ? (
                        <SpinnerLoader/>
                      ) : notificationData ? (
                        <Table className="table table-striped table-bordered">
                          <CustomColumn
                            fields={fields}
                            status={false}
                            action={false}
                          />
                          <Tbody>{getRows()}</Tbody>
                        </Table>
                      ) : (
                        <NoDataAvailable module="notifications" />
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {pagination.total_pages && pagination.total_pages > 1 ? (
          <Pagination
            pagination={pagination}
            getData={getNotifications}
            search={search}
          />
        ) : null}
      </div>
    </>
  )
}

export default MyNotifications
