import React, { useContext, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import Breadcrumb from "components/Common/Breadcrumb"
import SpinnerLoader from "components/Loader/SpinnerLoader"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { Table, Tbody } from "react-super-responsive-table"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
import ConfirmationModal from "components/Common/Modals/ConfirmationModal"
import { del, get } from "helpers/api_helper"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { TableRowsHoc } from "components/Common/TableRowsHoc"

const ServiceArea = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [deleteData, setDeleteData] = useState(false)
  const [loader, setLoader] = useState(false)
  const [serviceAreasData, setServiceAreasData] = useState([])
  const customAlert = useContext(CustomAlertContext)

  const getViewServiceArea = n_id => {
    let serviceArea = serviceAreasData.length
      ? serviceAreasData.find(n => n._id == n_id)
      : ""
    let n_link = ""
    if (serviceArea.title) {
      n_link = `service-area/view/${serviceArea._id}`
    }
    return n_link ? <a href={n_link}>{serviceArea.title}</a> : serviceArea.title
  }
  const fields = [
    { column: "Title", key: "_id", value: getViewServiceArea },
    { column: "Currency", key: "currency" },
    { column: "Base price", key: "base_price" },
    { column: "Base price inc. kms", key: "base_price_inclusive_km" },
    { column: "Per km price", key: "per_km_price" },
  ]

  useEffect(() => {
    getServiceAreas()
  }, [])

  const getServiceAreas = () => {
    setLoader(true)
    get("service-areas")
      .then(res => {
        setServiceAreasData(res.result.data)
        setLoader(false)
      })
      .catch(err => {
        setLoader(false)
      })
  }

  const openModal = (status, data) => {
    setIsOpenModal(status)
    setDeleteData(data)
  }

  const handleDelete = () => {
    del(`service-areas/${deleteData._id}`).then(res => {
      customAlert.notify(res.message)
      setDeleteData(false)
      setIsOpenModal(false)
      getServiceAreas()
    })
  }

  const getRows = () => (
    <TableRowsHoc length={serviceAreasData.length}>
      {serviceAreasData.map((serviceArea, i) => (
        <CustomRow
          fields={fields}
          data={serviceArea}
          section={"service-area"}
          status={false}
          key={serviceArea._id}
          handleDelete={openModal}
          index={i}
          action={{
            edit: true,
            delete: true,
          }}
          createdData={false}
        />
      ))}
    </TableRowsHoc>
  )

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  const data_table = (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <div className="d-flex justify-content-end">
              <a href="/service-area/all" style={{ color: "white" }}>
                <Button color="primary" className="m-1">
                  Show All
                </Button>
              </a>
              <a href="/service-area/add" style={{ color: "white" }}>
                <Button color="primary" className=" ">
                  Add New
                </Button>
              </a>{" "}
            </div>
            {loader ? (
              <SpinnerLoader />
            ) : (
              <Table className="table table-striped table-bordered">
                <CustomColumn
                  fields={fields}
                  status={false}
                  createdData={false}
                />
                <Tbody>{getRows()}</Tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )

  return (
    <div className="page-content">
      <MetaTags>
        <title>Service Areas | GoFetch</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb breadcrumbItem="Service Areas" />
        {loader ? <SpinnerLoader /> : data_table}
      </div>
      <ConfirmationModal
        toggleModal={toggleModal}
        isOpenModal={isOpenModal}
        deleteRecord={handleDelete}
        title="Delete Service Area"
      />
    </div>
  )
}
export default ServiceArea
