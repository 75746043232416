import Breadcrumb from "components/Common/Breadcrumb"
import { get, post } from "helpers/api_helper"
import React, { useContext, useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Card, CardBody, Col, Row } from "reactstrap"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { Button, InputGroup, Input } from "reactstrap"
import dayjs from "dayjs"
import SpinnerLoader from "components/Loader/SpinnerLoader"

function MessageList(props) {
  return (
    <div className="message-box-height">
      {props.receiveMessage
        ? props.receiveMessage.map(message =>
            message.sentBy == "user" ? (
              <div className="d-flex flex-row-start  ">
                <div className="support-message-text bg-info">
                  <span className="d-flex justify-content-start">
                    {message.message}
                  </span>
                  <span className="support-message-time d-flex justify-content-start">
                    {dayjs(message.createdAt).format("hh:mm A, DD MMM")}
                  </span>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-row-reverse  ">
                <div className="support-message-text bg-light text-reset">
                  <span className="d-flex justify-content-end">
                    {message.message}
                  </span>
                  <span
                    className="support-message-time d-flex justify-content-end"
                    style={{ color: "darkgray" }}
                  >
                    {dayjs(message.createdAt).format("hh:mm A, DD MMM")}
                  </span>
                </div>
              </div>
            )
          )
        : ""}
    </div>
  )
}

function SendMessage(props) {
  return (
    <div className="support-message-input  ">
      <div className="d-flex justify-content-center">
        <InputGroup>
          <Input
            placeholder={
              props.supportData?.status == false
                ? ` Ticket has been closed by the ${props.supportData.closedBy}.`
                : "Enter Your Message "
            }
            value={props.sendMessage}
            onChange={e => props.setSendMessage(e.target.value)}
            disabled={props.supportData?.status == false ? true : false}
          />
          <Button
            color="success"
            onClick={() => {
              props.handleSubmit(props.sendMessage)
            }}
            disabled={props.supportData?.status == false ? true : false}
          >
            Send
          </Button>
        </InputGroup>
      </div>
    </div>
  )
}

const SupportTicketView = props => {
  const [ticketViewData, setTicketViewData] = useState({})
  const [loading, setLoading] = useState(false)
  const [sendMessage, setSendMessage] = useState()
  const customAlert = useContext(CustomAlertContext)
  const [supportMessage, setSupportMessage] = useState([])
  const [receiveMessage, setReceiveMessage] = useState()
  const [supportData, setSupportData] = useState()
  console.log(supportData, "supportDatasupportData")
  // console.log(receiveMessage, "message")

  useEffect(() => {
    getMessage()
  }, [])
  const getMessage = () => {
    get(`supports/${props.match.params.id}`).then(res => {
      const { status, result } = res
      // console.log(res.result, "respone")
      setSupportData(res.result)
      setReceiveMessage(res.result.messages)
    })
  }

  const handleSubmit = message => {
    // setSupportMessage([...supportMessage, message])
    setSendMessage("")
    post(`supports/${supportData._id}/message`, { message: sendMessage })
      .then(res => {
        // customAlert.notify(res.message)
        getMessage()
      })
      .catch(err => {
        customAlert.notify(err.response.data.message, "error")
      })
  }
  useEffect(() => {
    setLoading(true)
    get(`supports/${props.match.params.id}`)
      .then(res => {
        const { status, result } = res
        setTicketViewData(result)
        setLoading(false)
      })
      .catch(err => {
        customAlert.notify(err.response.data.message, "error")
        setLoading(false)
      })
  }, [])
  return (
    <div className="page-content">
      <MetaTags>
        <title>Support | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          // breadcrumb1={{ title: "Support", href: "/support" }}
          breadcrumbItem="Ticket View"
        />
        {loading ? (
          <SpinnerLoader />
        ) : (
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <div className="invoice-title">
                    <h4 className="float-end font-size-16">
                      Name:
                      <strong className="fw-bold text-capitalize">
                        <a
                          href={`/customers/view/${ticketViewData?.user?._id}`}
                        >
                          &nbsp;
                          {ticketViewData?.user?.name}
                        </a>
                      </strong>
                    </h4>
                    <h4 className="mt-0">
                      Ticket ID: {ticketViewData.ticket_id}{" "}
                    </h4>
                  </div>
                  {/* <hr /> */}
                </Row>
              </CardBody>
            </Card>
            <div className="support-chat-container">
              <MessageList
                receiveMessage={receiveMessage}
                supportData={supportData}
              ></MessageList>
              <SendMessage
                sendMessage={sendMessage}
                supportData={supportData}
                setSendMessage={setSendMessage}
                handleSubmit={handleSubmit}
              ></SendMessage>
            </div>
          </Col>
        )}
      </div>
    </div>
  )
}

export default SupportTicketView
