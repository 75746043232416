import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container, Button } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// actions
import { loginUser, apiError } from "../../store/actions"

// import images
import logoSm from "../../assets/images/logo.png"
import { useFormik } from "formik"
import * as Yup from "yup"
import { textField } from "components/Common/Form/InputFields"

const eyeSlash = <i className="mdi mdi-eye-off" />
const eye = <i className="mdi mdi-eye" />
// const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />
// const eye = <FontAwesomeIcon icon={faEye} />

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("The selected email is invalid.")
    .required("The email field is required."),
  password: Yup.string().required("The password field is required"),
})
const Login = props => {
  // handleValidSubmit

  const handleValidSubmit = values => {
    props.loginUser(values, props.history)
  }
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: values => {
      handleValidSubmit(values)
    },
  })

  const [passwordShown, setPasswordShown] = useState(false)
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | GoFetchIt</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">Welcome Back !</h5>
                    <p className="text-white">
                      Sign in to continue to GoFetchIt.
                    </p>

                    <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="50" width="50" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <form
                      onSubmit={formik.handleSubmit}
                      className="form-horizontal mt-4"
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}
                      <div className="mb-3">
                        {textField(
                          formik,
                          "email",
                          "Email*",
                          "Enter your Email",
                          false,
                          "email"
                        )}
                      </div>
                      <div className="mb-3 password-class">
                        {textField(
                          formik,
                          "password",
                          "Password*",
                          "Enter your Password",
                          false,
                          passwordShown ? "text" : "password"
                        )}
                        <i
                          // className="icon-password"
                          className={`icon-password ${
                            formik.errors.password ? "mb-4" : ""
                          }`}
                          onClick={togglePasswordVisiblity}
                        >
                          {passwordShown ? eyeSlash : eye}
                        </i>{" "}
                      </div>
                      {/* <div className="mb-3 password-class">
                        <input
                          placeholder="Password"
                          name="password"
                          type={passwordShown ? "text" : "password"}
                          className="form-control "
                          // ref={register({ required: "This is required." })}
                        />
                        <i
                          className="icon-password"
                          onClick={togglePasswordVisiblity}
                        >
                          {eye}
                        </i>{" "}
                      </div> */}
                      <div className="text-end">
                        <Button type="submit" color="primary" className="">
                          Log In
                        </Button>
                      </div>
                      <Row className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          {/* <Link to="/forgot-password"> */}
                          <Link to="/forgot-password">
                            <i className="mdi mdi-lock"></i> Forgot your
                            password?
                          </Link>
                        </div>
                      </Row>
                    </form>{" "}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center"></div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
