import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Alert,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

// import images
import logo from "../../assets/images/logo.png"
import { post } from "helpers/api_helper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { textField } from "components/Common/Form/InputFields"

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
})

const ForgotPassword = () => {
  const [showAlert, setShowAlert] = useState(false)
  const [alert_type, setAlertType] = useState("primary")

  const [alertMessage, setAlertMessage] = useState(false)
  const handleSubmit = values => {
    post("reset-password", values)
      .then(res => {
        setShowAlert(true)
        setAlertMessage(res.message)
        setAlertType("primary")
      })
      .catch(error => {
        setShowAlert(true)
        setAlertMessage(error.response.data.message)
      })
  }

  const dismissAlert = () => {
    setShowAlert(false)
    setAlertMessage(false)
  }
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: values => {
      handleSubmit(values)
    },
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Recover Password | GoFetchIt</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="dashboard" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Alert
              color={alert_type || "primary"}
              isOpen={showAlert}
              toggle={dismissAlert}
              fade={false}
            >
              {alertMessage}
            </Alert>
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20 p-2">
                      Reset Password
                    </h5>
                    <Link to="dashboard" className="logo logo-admin">
                      <img src={logo} height="50" width="50" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <div
                      className="alert alert-success mt-5"
                      role="alert"
                      className="mt-5"
                    >
                      Enter your Email and instructions will be sent to you!
                    </div>

                    <form onSubmit={formik.handleSubmit} className="mt-4">
                      <div className="mb-3">
                        {textField(
                          formik,
                          "email",
                          "Email",
                          "Enter your Email",
                          false,
                          "email"
                        )}
                      </div>

                      <div className="row mb-0">
                        <Col xs={12} className="text-end">
                          <Button
                            type="submit"
                            color="primary"
                            className="btn btn-primary w-md waves-effect waves-light"
                          >
                            Submit
                          </Button>
                        </Col>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link to="login" className="fw-medium text-primary">
                    {" "}
                    Sign In here{" "}
                  </Link>{" "}
                </p>
                {/* <p className="mb-0">
                  © {new Date().getFullYear()} Veltrix. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Themesbrand
                </p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </React.Fragment>
  )
}

export default ForgotPassword
