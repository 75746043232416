import React, { useEffect, useState, useContext } from "react"
import MetaTags from "react-meta-tags"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { get, del } from "helpers/api_helper"
import NoDataAvailable from "components/Common/NoDataAvailable"
import ConfirmationModal from "components/Common/Modals/ConfirmationModal"
import { Pagination } from "components/Common/Pagination"
import { CustomSearch } from "components/Common/CustomSearch"
import NoData from "components/NoData/NoData"
import { TableRowsHoc } from "components/Common/TableRowsHoc"
import SpinnerLoader from "components/Loader/SpinnerLoader"

const Notifications = () => {
  const [notificationData, setNotificationData] = useState([])
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [deleteData, setDeleteData] = useState(false)
  const [loading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({ page_no: 1, limit: 20 })
  const [search, setSearch] = useState("")

  const customAlert = useContext(CustomAlertContext)

  useEffect(() => {
    getNotifications()
  }, [])

  const getNotifications = (query = pagination) => {
    setIsLoading(true)
    get("notifications?admin=false", { params: query }).then(res => {
      const { status, result } = res
      setNotificationData(result.data)
      setPagination(result.meta_data)
      setIsLoading(false)
    })
  }

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  const openModal = (status, data) => {
    setIsOpenModal(status)
    setDeleteData(data)
  }
  const handleDelete = () => {
    del(`notification/${deleteData._id}`).then(res => {
      const { status, result } = res
      customAlert.notify(res.message)
      setDeleteData(false)
      setIsOpenModal(false)
      getNotifications()
    })
  }
  const getDescription = n_id => {
    let notification = notificationData.length
      ? notificationData.find(n => n._id == n_id)
      : ""
    let n_link = ""
    if (notification.order_id) {
      n_link = `orders/view/${notification.order_id}`
    } else if (notification.user_type == "deliverGuys") {
      n_link = `deliver-guys/view/${notification.user_id}`
    } else if (notification.user_type == "customers") {
      n_link = `customers/view/${notification.user_id}`
    }
    return n_link ? (
      <a href={n_link} target="blank">
        {notification.description}
      </a>
    ) : (
      notification.description
    )
  }
  let fields = [
    { column: "Notification Type", key: "noti_type" },
    { column: "Title", key: "title" },
    { column: "Description", key: "_id", value: getDescription },
  ]

  const getRows = () => (
    <TableRowsHoc length={notificationData.length}>
      {notificationData.map((notification, i) => (
        <CustomRow
          fields={fields}
          data={notification}
          section={"notification"}
          handleDelete={openModal}
          action={{ delete: true }}
          status={false}
          key={notification._id}
          index={i}
          pagination={pagination}
        />
      ))}
    </TableRowsHoc>
  )

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Notifications | GoFetchIt</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumb title="GoFetchIt" breadcrumbItem="Notifications" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CustomSearch
                    getData={getNotifications}
                    pagination={pagination}
                    setSearch={setSearch}
                    placeholder={"Notification Type, Title"}
                  />
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      {loading ? (
                        <SpinnerLoader/>
                      ) : notificationData ? (
                        <Table className="table table-striped table-bordered">
                          <CustomColumn fields={fields} status={false} />
                          <Tbody>{getRows()}</Tbody>
                        </Table>
                      ) : (
                        <NoDataAvailable module="notifications" />
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {pagination.total_pages && pagination.total_pages > 1 ? (
          <Pagination
            pagination={pagination}
            getData={getNotifications}
            search={search}
          />
        ) : null}
      </div>
      <ConfirmationModal
        toggleModal={toggleModal}
        isOpenModal={isOpenModal}
        deleteRecord={handleDelete}
        title="Delete Notification"
      />
    </>
  )
}

export default Notifications
