import React from "react"
import MetaTags from "react-meta-tags"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
// import "../Tables/datatables.scss"

import OrderForm from "components/Orders/OrderForm";

const AddOrder = props => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>Orders | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb title="Orders" breadcrumbItem="Add Order" />
        <OrderForm c_data={false} />{" "}
      </div>
    </div>
  )
}

export default AddOrder
