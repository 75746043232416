import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { get } from "helpers/api_helper"
import PageForm from "components/Pages/PageForm"
import SpinnerLoader from "components/Loader/SpinnerLoader"
const EditPage = props => {
  const [page, setPage] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    get(`page/${props.match.params.id}`).then(res => {
      const { status, result } = res
      setPage(result)
      setLoading(false)
    })
  }, [])

  return (
    <div className="page-content">
      <MetaTags>
        <title>Pages | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Pages", href: "/pages" }}
          breadcrumbItem="Edit Page"
        />
        {loading ? (
          <SpinnerLoader/>
        ) : (
          <PageForm data={page} page_id={props.match.params.id} />
        )}
      </div>
    </div>
  )
}

export default EditPage
