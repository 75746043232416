import React, { useEffect, useState, useContext } from "react"
import MetaTags from "react-meta-tags"
import { get, post } from "helpers/api_helper"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { Col, Card, CardBody, Row, Button } from "reactstrap"
import { getDeliverGuyStatus } from "utils/helpers/deliverGuy"
import Lightbox from "react-image-lightbox"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import VehicleForm from "components/DeliverGuys/VehicleForm"

const ViewVehicle = props => {
  const [vehicle, setVehicleData] = useState({})
  const [loading, setLoading] = useState(false)
  const [lightbox_image, setIsOpen] = useState(false)
  const customAlert = useContext(CustomAlertContext)

  const deliverGuy_id = props.match.params.id
  useEffect(() => {
    getVehicle()
  }, [])

  const getVehicle = () => {
    setLoading(true)
    get(`deliverGuy/${deliverGuy_id}/vehicle`)
      .then(res => {
        const { status, result } = res
        setVehicleData(result)
        setLoading(false)
      })
      .catch(er => console.log(`er`, er))
  }
  const changeStatus = status => () => {
    let data = {
      key: "vehicles",
      status,
    }
    post(`verify-deliver-guy/${deliverGuy_id}`, data).then(res => {
      const { result } = res

      customAlert.notify(status === 1 ? "Approved" : "Rejected")
      window.location.href = "/deliver-guys"
    })
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Delivery Guys View Vehicle | GoFetchIt</title>
      </MetaTags>
      {lightbox_image && (
        <Lightbox
          mainSrc={lightbox_image}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Delivery Guys", href: "/deliver-guys" }}
          breadcrumbItem="View Vehicle"
        />
        <VehicleForm
          vehicle_data={vehicle}
          disabled={vehicle && vehicle.brand_name ? true : false}
          deliverGuy_id={deliverGuy_id}
        />
      </div>
    </div>
  )
}

export default ViewVehicle
