const NoDataAvailable = props => {
  return (
    <div className="text-center my-5">
      <h4>
        No Record Available.{" "}
        {props.module !== "notifications"
          ? `Please Add by click "Add New" Button`
          : null}
      </h4>{" "}
    </div>
  )
}

export default NoDataAvailable
