import React from "react"

const SpinnerLoader = () => (
  <div
    className="spinner-border spinner"
    style={{ width: "4rem", height: "4rem" }}
    role="status"
  >
    <span className="visually-hidden">Loading...</span>
  </div>
)

export default SpinnerLoader
