import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { get } from "helpers/api_helper"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { Col, Card, CardBody, Row } from "reactstrap"
import { getStatus, getTransactionType } from "utils/helpers/transaction"
import SpinnerLoader from "components/Loader/SpinnerLoader"

const ViewTransaction = props => {
  const [transaction, setTransaction] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    get(`transaction/${props.match.params.id}`).then(res => {
      const { status, result } = res
      setTransaction(result)
      setLoading(false)
    })
  }, [])
  return (
    <div className="page-content">
      <MetaTags>
        <title>Transactions | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Transactions", href: "/transactions" }}
          breadcrumbItem="View Transaction"
        />
        {loading ? (
          <SpinnerLoader/>
        ) : (
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <div className="col-12 fs-5">
                    <div className="invoice-title"></div>
                    <hr />
                    {/* <Row>
                      <Col md={6} sm={12}>
                        <h5 className="mb-1">
                          Transaction Id : #{transaction.t_id}
                        </h5>
                      </Col>
                      <Col md={6} sm={12}>
                        <h5 className="mb-1">
                          User ID: #
                          {transaction.user_id ? (
                            <a href={`/customers/view/${transaction.user_id}`}>
                              {transaction.user_id}
                            </a>
                          ) : (
                            "--"
                          )}
                        </h5>
                      </Col>

                      <Col md={6} sm={12}>
                        <h5 className="mb-1">
                          {" "}
                          Amount:{" "}
                          {`${transaction.amount} ${transaction.currency} `}
                        </h5>
                      </Col>

                      <Col md={6} sm={12}>
                        <h5 className="mb-1">
                          Description: {transaction.title}
                        </h5>
                      </Col>
                      <Col md={6} sm={12}>
                        <h5>Status: {`${getStatus(transaction.status)} `}</h5>
                      </Col>
                      {transaction.order_track_id ? (
                        <Col md={6} sm={12}>
                          <h5 className="mb-1">
                            {" "}
                            Order Track ID:
                            <a
                              href={
                                transaction.order_id
                                  ? `/orders/view/${transaction.order_id}`
                                  : "#"
                              }
                            >
                              {`#${transaction.order_track_id} `}
                            </a>
                          </h5>
                        </Col>
                      ) : null}
                    </Row> */}
                    <Row>
                      <div className="col-6">
                        <address>
                          <strong>Transaction Id:</strong>
                          <br />#{transaction.t_id}
                        </address>
                      </div>
                      <div className="col-6 text-end">
                        <address>
                          <strong>User Id:</strong>
                          <br />
                          {transaction.user_id ? (
                            <a href={`/customers/view/${transaction.user_id}`}>
                              #{transaction.user_id}
                            </a>
                          ) : (
                            "--"
                          )}
                        </address>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-6">
                        <address>
                          <strong>Amount:</strong>
                          <br />
                          {`${transaction.amount} ${transaction.currency} `}
                        </address>
                      </div>
                      <div className="col-6 text-end">
                        <address>
                          <strong>Description:</strong>
                          <br />
                          {transaction.title}
                        </address>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-6">
                        <address>
                          <strong>Status:</strong>
                          <br />
                          {`${getStatus(transaction.status)} `}
                        </address>
                      </div>
                      {transaction.order_track_id ? (
                        <div className="col-6 text-end">
                          <address>
                            <strong>Order Track Id:</strong>
                            <br />
                            <a
                              href={
                                transaction.order_id
                                  ? `/orders/view/${transaction.order_id}`
                                  : "#"
                              }
                            >
                              {`#${transaction.order_track_id} `}
                            </a>
                          </address>
                        </div>
                      ) : null}
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        )}
      </div>
    </div>
  )
}

export default ViewTransaction
