import React, { useEffect, useState, useContext } from "react"
import MetaTags from "react-meta-tags"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { get, del, put } from "helpers/api_helper"
import ConfirmationModal from "components/Common/Modals/ConfirmationModal"
import NoDataAvailable from "components/Common/NoDataAvailable"
import renderHTML from "react-render-html"

import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import NoData from "components/NoData/NoData"
import { TableRowsHoc } from "components/Common/TableRowsHoc"

const Faqs = () => {
  const [faqData, setFaqData] = useState([])
  const [loading, setIsLoading] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [deleteData, setDeleteData] = useState(false)
  const customAlert = useContext(CustomAlertContext)
  useEffect(() => {
    getFaqs()
  }, [])

  const getFaqs = () => {
    setIsLoading(true)
    get("faqs").then(res => {
      const { status, result } = res
      setFaqData(result)
      setIsLoading(false)
    })
  }

  const getDescription = data => renderHTML(data)

  let fields = [
    { column: "Question", key: "Q" },
    { column: "Answer", key: "A", value: getDescription },
    { column: "Type", key: "type" },
  ]

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  const openModal = (status, data) => {
    setIsOpenModal(status)
    setDeleteData(data)
  }

  const handleDelete = () => {
    del(`faq/${deleteData._id}`).then(res => {
      const { status, result } = res
      customAlert.notify(res.message)
      setDeleteData(false)
      setIsOpenModal(false)
      getFaqs()
    })
  }

  const getRows = () => (
    <TableRowsHoc length={faqData.length}>
      {faqData.map((faq, i) => (
        <CustomRow
          fields={fields}
          data={faq}
          section={"faqs"}
          status={false}
          key={faq._id}
          handleDelete={openModal}
          index={i}
        />
      ))}
    </TableRowsHoc>
  )
  return (
    <div className="page-content">
      <MetaTags>
        <title>FAQs | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb breadcrumbItem="FAQs" />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-end">
                  <Button color="primary" className="mb-2">
                    <a href="/faqs/add" style={{ color: "white" }}>
                      Add New
                    </a>{" "}
                  </Button>
                </div>
                <Table className="table table-striped table-bordered">
                  <CustomColumn fields={fields} status={false} />
                  <Tbody>{getRows()}</Tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <ConfirmationModal
        toggleModal={toggleModal}
        isOpenModal={isOpenModal}
        deleteRecord={handleDelete}
        title="Delete Faq"
      />
    </div>
  )
}

export default Faqs
