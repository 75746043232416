import React from "react"
import MetaTags from "react-meta-tags"
import Breadcrumb from "../../components/Common/Breadcrumb"
// import PageForm from "components/Pages/PageForm"

const AddPage = props => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>Pages | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Pages", href: "/pages" }}
          breadcrumbItem="Add Page"
        />
        {/* <PageForm data={false} />{" "} */}
      </div>
    </div>
  )
}

export default AddPage
