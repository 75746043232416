import { createContext, useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Modal } from "reactstrap"

export const ModalContext = createContext("Default Modal")

const init_state = { title: "", blockContent: null, onClose: null, size: "md" }
export const ModalContextProvider = ({ children }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [modalData, setModalData] = useState(init_state)

  useEffect(() => {
    if (modalData.title || modalData.blockContent) {
      setIsOpenModal(true)
    }
  }, [modalData.title])

  const toggleModal = status => {
    setIsOpenModal(status)
    if (status == false) {
      setModalData(init_state)
    }
  }
  let popup_modal = (
    <Modal
      isOpen={isOpenModal}
      toggle={() => toggleModal(false)}
      size={modalData.size}
    >
      <div className="modal-header">
        <h5 className="modal-title"> {modalData.title}</h5>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => toggleModal(false)}
        ></button>
      </div>
      {modalData.blockContent}
    </Modal>
  )
  return (
    <ModalContext.Provider value={{ setModalData, toggleModal }}>
      {children}
      {popup_modal}
    </ModalContext.Provider>
  )
}
