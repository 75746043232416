import React, { Component } from "react"
import ChartistGraph from "react-chartist"

function LineAreaChart({ data }) {
  var lineChartData = {
    labels: data.map(dt => dt._id.month),
    series: [data.map(dt => dt.total_amount)],
  }
  var lineChartOptions = {
    low: 0,
    showArea: true,
  }
  return (
    <React.Fragment>
      <ChartistGraph
        data={lineChartData}
        style={{ height: "300px" }}
        options={lineChartOptions}
        type={"Line"}
      />
    </React.Fragment>
  )
}

export default LineAreaChart
