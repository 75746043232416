import { createContext, useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export const CustomAlertContext = createContext("Default Value")

export const CustomAlertContextProvider = ({ children }) => {
  const [type, setType] = useState("success")

  const notify = (message, type = "success") => {
    type == "success" ? toast.success(message) : toast.error(message)
    setType(type)
  }
  return (
    <CustomAlertContext.Provider value={{ notify }}>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        //   newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        type={type}
      />
      {children}
    </CustomAlertContext.Provider>
  )
}
