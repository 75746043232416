import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"

import { get } from "helpers/api_helper"
import FaqForm from "components/Faqs/FaqForm"
import SpinnerLoader from "components/Loader/SpinnerLoader"
const EditFaq = props => {
  const [faq, setFaq] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    get(`faq/${props.match.params.id}`).then(res => {
      const { status, result } = res
      setFaq(result)
      setLoading(false)
    })
  }, [])

  return (
    <div className="page-content">
      <MetaTags>
        <title>FAQs | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "FAQs", href: "/faqs" }}
          breadcrumbItem="Edit Faq"
        />
        {loading ? (
          <SpinnerLoader/>
        ) : (
          <FaqForm data={faq} faq_id={props.match.params.id} />
        )}
      </div>
    </div>
  )
}

export default EditFaq
