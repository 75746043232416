import React from "react"
import { Td, Tr } from "react-super-responsive-table"

const NoData = ({ span }) => (
  <Tr>
    <Td colSpan={span}>No Data Found.</Td>
  </Tr>
)

export default NoData
