import React, { useEffect, useState, useContext } from "react"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { post, put } from "helpers/api_helper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { textField, selectField } from "components/Common/Form/InputFields"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { yupMessages } from "helpers/validations/messages"
import { formats } from "helpers/validations/regex"

const TimeSlotForm = props => {
  const { disabled, time_slot_id, c_data } = props
  const customAlert = useContext(CustomAlertContext)

  const handleSubmit = values => {
    let callApi
    if (time_slot_id) {
      callApi = updateTimeSlot(values)
    } else {
      callApi = addTimeSlot(values)
    }

    callApi
      .then(res => {
        customAlert.notify(res.message)
        window.location.href = "/time-slots"
      })
      .catch(err => {
        console.log(`err.response`, err.response)

        customAlert.notify(err.response.data.message)
      })
  }

  const addTimeSlot = data => post(`time-slots`, data)

  const updateTimeSlot = data => put(`time-slots/${time_slot_id}`, data)

  const TimeSlotSchema = Yup.object().shape({
    day: Yup.string()
      .trim()
      .required(`Day ${yupMessages.is}`)
      .matches(formats.string, "Day can contain only alphabets."),
    pickup_start_time: Yup.string()
      .when("is_closed", {
        is: false,
        then: Yup.string()
          .required(`Pickup start time ${yupMessages.is_required}`)
          .nullable(),
      })
      .nullable(),
    pickup_end_time: Yup.string()
      .test(
        "pickup_end_time",
        "pickup end time should be greater than pickup start time",
        (a, b) => {
          const { pickup_start_time, is_closed } = b.parent
          return !is_closed && a
            ? getDifferenceTime(pickup_start_time, a)
            : true
        }
      )
      .when("is_closed", {
        is: false,
        then: Yup.string()
          .required(`Pickup end time ${yupMessages.is_required}`)
          .nullable(),
      })
      .nullable(),

    delivery_start_time: Yup.string()
      .when("is_closed", {
        is: false,
        then: Yup.string()
          .required(`Delivery start time ${yupMessages.is_required}`)
          .nullable(),
      })
      .nullable(),
    delivery_end_time: Yup.string()
      .test(
        "delivery_end_time",
        "delivery end time should be greater than delivery start time",
        (a, b) => {
          const { delivery_start_time, is_closed } = b.parent
          return !is_closed && a
            ? getDifferenceTime(delivery_start_time, a)
            : true
        }
      )
      .when("is_closed", {
        is: false,
        then: Yup.string()
          .required(`Delivery end time ${yupMessages.is_required}`)
          .nullable(),
      })
      .nullable(),
    is_closed: Yup.boolean(),
  })

  const getDifferenceTime = (start_time, end_time) => {
    var stt = new Date("May 26, 2016 " + start_time)
    stt = stt.getTime()

    var endt = new Date("May 26, 2016 " + end_time)
    endt = endt.getTime()

    if (stt >= endt) {
      return false
    } else {
      return true
    }
  }
  const formik = useFormik({
    initialValues: {
      day: c_data ? c_data.day : "monday",
      pickup_start_time: c_data ? c_data.pickup_start_time : "10:00",
      pickup_end_time: c_data ? c_data.pickup_end_time : "16:00",
      delivery_start_time: c_data ? c_data.delivery_start_time : "12:00",
      delivery_end_time: c_data ? c_data.delivery_end_time : "18:00",
      is_closed: c_data ? c_data.is_closed : false,
    },
    validationSchema: TimeSlotSchema,
    onSubmit: values => {
      handleSubmit(values)
    },
  })

  const handleClose = ev => {
    if (ev.target.checked) {
      formik.setFieldValue("pickup_start_time", "")
      formik.setFieldValue("pickup_end_time", "")
      formik.setFieldValue("delivery_start_time", "")
      formik.setFieldValue("delivery_end_time", "")
    }
    formik.setFieldValue("is_closed", ev.target.checked)
  }
  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Row>
                  <Col lg={12}>
                    {textField(
                      formik,
                      "day",
                      "Day*",
                      "enter day name",
                      false,
                      "text"
                    )}
                  </Col>
                  <Col lg={3}>
                    {textField(
                      formik,
                      "pickup_start_time",
                      "Pickup Start Time",
                      "select pickup start time",
                      false,
                      "time"
                    )}
                  </Col>
                  <Col lg={3}>
                    {textField(
                      formik,
                      "pickup_end_time",
                      "Pickup End Time",
                      "select pickup end time",
                      false,
                      "time"
                    )}
                  </Col>

                  <Col lg={3}>
                    {textField(
                      formik,
                      "delivery_start_time",
                      "Delivery Start Time",
                      "select delivery start time",
                      false,
                      "time"
                    )}
                  </Col>
                  <Col lg={3}>
                    {textField(
                      formik,
                      "delivery_end_time",
                      "Delivery End Time",
                      "select delivery end time",
                      false,
                      "time"
                    )}
                  </Col>

                  <Col>
                    <input
                      type="checkbox"
                      name="is_closed"
                      onChange={handleClose}
                      checked={formik.values.is_closed}
                    />{" "}
                    closed
                  </Col>
                </Row>
              </div>

              {disabled ? null : (
                <div className="d-flex flex-wrap gap-2">
                  <Button type="submit" color="primary" className="">
                    Submit
                  </Button>{" "}
                </div>
              )}
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default TimeSlotForm
