import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  // Input,
  // Dropdown,
  // DropdownToggle,
  // DropdownItem,
  // DropdownMenu,
} from "reactstrap"
import { Link } from "react-router-dom"

// Custom Scrollbar
// import SimpleBar from "simplebar-react"

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import servicesIcon4 from "../../assets/images/services-icon/04.png"
import servicesIcon5 from "../../assets/images/services-icon/05.png"
import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"

import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"
import { get } from "helpers/api_helper"
import dayjs from "dayjs"
import { getOrderStatus } from "utils/helpers/order"
import LineAreaChart from "components/Common/LineAreaChart"
// import utc from "dayjs/plugin/utc"
// dayjs.extend(utc)

function DashboardCard({ icon, name, redirectLink, count }) {
  return (
    <Card className="mini-stat bg-primary text-white">
      <CardBody>
        <div className="mb-4">
          <div className="float-start mini-stat-img me-4">
            <img src={icon} alt="" />
          </div>
          <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
            {name}
          </h5>
          <h4 className="fw-medium font-size-24">{count ? count : 0}</h4>
        </div>
        <div className="pt-2">
          <div className="float-end">
            <Link to={`${redirectLink}`} className="text-white-50">
              <i className="mdi mdi-arrow-right h5"></i>
            </Link>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

const Dashboard = props => {
  const [dashboard, setDashboardData] = useState(null)

  useEffect(() => {
    get("dashboard").then(data => setDashboardData(data.result))
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | GoFetchIt</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to GoFetchIt Dashboard
                  </li>
                </ol>
              </Col>

              <Col md="4"></Col>
            </Row>
          </div>
          <Row>
            <Col xl={3} md={6}>
              <DashboardCard
                icon={servicesIcon1}
                name={"Orders"}
                redirectLink={"/orders"}
                count={dashboard?.order_count}
              />
            </Col>
            <Col xl={3} md={6}>
              <DashboardCard
                icon={servicesIcon2}
                name={"Customers"}
                redirectLink={"/customers"}
                count={dashboard?.customer_count}
              />
            </Col>
            <Col xl={3} md={6}>
              <DashboardCard
                icon={servicesIcon3}
                name={"Vehicles"}
                redirectLink={""}
                count={dashboard?.vehicle_count}
              />
            </Col>
            <Col xl={3} md={6}>
              <DashboardCard
                icon={servicesIcon4}
                name={"Delivery Guys"}
                redirectLink={"/deliver-guys"}
                count={dashboard?.driver_count}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={9}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4"> Earning</h4>
                  <Row>
                    {dashboard && dashboard.revenues ? (
                      <Col lg={12}>
                        <div>
                          <LineAreaChart data={dashboard.revenues} />
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xl={3}>
              {/* <Card className="bg-primary"> */}
              <DashboardCard
                icon={servicesIcon5}
                name={"Completed Orders"}
                redirectLink={"orders"}
                count={dashboard?.completed_orders_count}
              ></DashboardCard>
              {/* <CardBody>
                  <div className="text-center text-white py-4">
                    <h5 className="mt-0 mb-4 text-white-50 font-size-16">
                      Completed Orders
                    </h5>
                    <h1>{dashboard ? dashboard.completed_orders_count : 0}</h1>
                    <p className="font-size-14 pt-1">Orders</p>
                    <p className="text-white-50 mb-0">
                      <Link to="/orders" className="text-white">
                        View more
                      </Link>
                    </p>
                  </div>
                </CardBody> */}
              {/* </Card> */}
            </Col>
          </Row>

          <Row>
            <Col xl={5}>
              <Row></Row>
              <Row></Row>
            </Col>
          </Row>

          <Row>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Latest Orders</h4>
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">(#) Order Track Id</th>
                          <th scope="col">Assigned Driver</th>
                          <th scope="col">Order Confirmed At</th>
                          <th scope="col">Amount</th>
                          <th scope="col" colSpan="2">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboard &&
                        dashboard.orders &&
                        dashboard.orders.length
                          ? dashboard.orders.map((order, i) => (
                              <tr>
                                <th scope="row">
                                  <a href={`orders/view/${order._id}`}>
                                    #{order.order_track_id}
                                  </a>
                                  <br />
                                  {getOrderStatus(
                                    "light",
                                    order.is_scheduled ? "Schedule" : "Same-Day"
                                  )}
                                </th>
                                <td>
                                  <div>
                                    <img
                                      src={
                                        order.assigned &&
                                        order.assigned.profile_url
                                      }
                                      alt=""
                                      className="avatar-xs rounded-circle me-2"
                                    />{" "}
                                    {order.assigned && order.assigned.full_name}
                                  </div>
                                </td>
                                <td>
                                  {dayjs(order.createdAt).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )}
                                </td>
                                <td>{order.amount.total_amount}</td>
                                <td>{getOrderStatus(order.status)}</td>
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Client Reviews</h4>
                  {dashboard && dashboard.reviews && dashboard.reviews.length
                    ? dashboard.reviews.map((review, i) => (
                        <div className="dashboard-review">
                          <p className="text-muted mb-3 pb-4">
                            {review.review}
                          </p>

                          <h6 className="mb-0">
                            {Array.from(
                              { length: review.rating },
                              (_, i) => i + 1
                            ).map(star => (
                              <i
                                className="mdi mdi-star
                          "
                              ></i>
                            ))}{" "}
                            {review.user
                              ? `${review.user.first_name}  ${
                                  review.user.last_name
                                    ? review.user.last_name
                                    : ""
                                }`
                              : ""}
                          </h6>
                        </div>
                      ))
                    : ""}

                  <div className="float-end mt-2">
                    <Link to="/reviews" className="text-primary">
                      <i className="mdi mdi-arrow-right h5"></i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
