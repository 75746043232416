import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"

import { get } from "helpers/api_helper"
import OrderForm from "components/Orders/OrderForm"
import SpinnerLoader from "components/Loader/SpinnerLoader"
const EditOrder = props => {
  const [order, setOrder] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    get(`order/${props.match.params.id}`).then(res => {
      const { status, result } = res
      setOrder(result)
      setLoading(false)
    })
  }, [])

  return (
    <div className="page-content">
      <MetaTags>
        <title>Orders | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Orders", href: "/orders" }}
          breadcrumbItem="Edit Order"
        />
        {loading ? (
          <SpinnerLoader/>
        ) : (
          <OrderForm data={order} order_id={props.match.params.id} />
        )}
      </div>
    </div>
  )
}

export default EditOrder
