import React, { useEffect, useState, useContext } from "react"
import MetaTags from "react-meta-tags"
import { Table, Tbody, Td, Tr } from "react-super-responsive-table"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { get, del, post } from "helpers/api_helper"
import ConfirmationModal from "components/Common/Modals/ConfirmationModal"
import NoDataAvailable from "components/Common/NoDataAvailable"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { Pagination } from "components/Common/Pagination"
import { CustomSearch } from "components/Common/CustomSearch"
import NoData from "components/NoData/NoData"
import { TableRowsHoc } from "components/Common/TableRowsHoc"
import SpinnerLoader from "components/Loader/SpinnerLoader"

const PromoCodes = () => {
  const [promoCodeData, setPromoCodes] = useState([])
  const [loading, setIsLoading] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [deleteData, setDeleteData] = useState(false)
  const [pagination, setPagination] = useState({ page_no: 1, limit: 20 })
  const [search, setSearch] = useState("")
  const [selectedTokens, setSelectedTokens] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [multiDelete, setMultiDelete] = useState(false)

  const customAlert = useContext(CustomAlertContext)

  useEffect(() => {
    getPromoCodes()
  }, [])

  const toggleModal = () => {
    if (isOpenModal) {
      setDeleteData(false)
    }
    setIsOpenModal(!isOpenModal)
    setMultiDelete(false)
  }

  const openModal = (status, data) => {
    setIsOpenModal(status)
    setDeleteData(data)
  }
  const getPromoCodes = (page = pagination) => {
    setIsLoading(true)
    get("promoCodes", { params: page }).then(res => {
      const { status, result } = res
      setPromoCodes(result.data)
      setPagination(result.meta_data)

      setIsLoading(false)
    })
  }
  const handleDelete = () => {
    if (multiDelete) {
      post("promo-codes/multi-delete", {
        ids: selectedTokens.map(tokens => tokens._id),
      })
        .then(res => {
          customAlert.notify(res.message)
          setSelectedTokens([])
          setIsOpenModal(false)
          getPromoCodes()
        })
        .catch(err => console.log(`err`, err))
    } else {
      del(`promoCodes/${deleteData._id}`).then(res => {
        const { status, result } = res
        customAlert.notify(res.message)
        setDeleteData(false)
        setIsOpenModal(false)
        getPromoCodes()
      })
    }
  }

  const handleCheck =
    (user = false) =>
    event => {
      let tokens = []
      let obj = { _id: user._id, device_id: user.device_id }
      if (user) {
        tokens = [...selectedTokens]
        if (event.target.checked) {
          tokens.push(obj)
        } else {
          let index = tokens.findIndex(i => i._id === user._id)
          if (index > -1) {
            tokens.splice(index, 1) // 2nd parameter means remove one item only
          }
        }
      } else {
        if (event.target.checked) {
          promoCodeData.map((dg, i) =>
            tokens.push({ _id: dg._id, device_id: dg.device_id })
          )
        } else {
          tokens = []
        }
      }

      setSelectedTokens(tokens)
    }
  const getCheckBox = index => user_id => {
    let user = promoCodeData.length
      ? promoCodeData.find(x => x._id === user_id)
      : {}
    return (
      <span>
        <input
          type="checkbox"
          onChange={handleCheck(user)}
          checked={selectedTokens.find(token => token._id === user_id)}
        />{" "}
        {/* {(pagination.page_no - 1) * pagination.limit + index + 1} */}
      </span>
    )
  }
  const getSno = index => user_id => {
    return (
      <span>{(pagination.page_no - 1) * pagination.limit + index + 1}</span>
    )
  }
  const getFields = index => {
    return [
      {
        column: (
          <>
            <input
              type="checkbox"
              onChange={handleCheck()}
              checked={
                promoCodeData.length &&
                selectedTokens.length == promoCodeData.length
              }
              className="th-checkbox"
            />
            {/* SN */}
          </>
        ),
        key: "_id",
        value: getCheckBox(index),
      },
      {
        column: "S.No.",
        key: "_id",
        value: getSno(index),
      },
      { column: "Promo Code", key: "promo_code" },
      { column: "Start Date", key: "start_date" },
      { column: "End Date", key: "end_date" },
      { column: "Discount Type", key: "discount_type" },
      { column: "Discount", key: "discount" },
    ]
  }

  const getRows = () => (
    <TableRowsHoc length={promoCodeData.length}>
      {promoCodeData.map((promo_code, i) => (
        <CustomRow
          fields={getFields(i)}
          data={promo_code}
          section={"promo-codes"}
          handleDelete={openModal}
          key={promo_code._id}
          // index={i}
          pagination={pagination}
        />
      ))}
    </TableRowsHoc>
  )

  const handleMultiStatus = status => {
    post("promo-code/multi-change-status", {
      ids: selectedTokens.map(tokens => tokens._id),
      status,
    })
      .then(res => {
        customAlert.notify(res.message)
        setSelectedTokens([])
        getPromoCodes()
      })
      .catch(err => console.log(`err`, err))
  }

  const handleMultiDelete = () => {
    setMultiDelete(true)
    setIsOpenModal(true)
  }
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Promo Codes | GoFetchIt</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumb breadcrumbItem="Promo Codes" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CustomSearch
                    getData={getPromoCodes}
                    pagination={pagination}
                    setSearch={setSearch}
                    placeholder={"Promo Code"}
                  />
                  <div className="d-flex justify-content-between">
                    {selectedTokens.length ? (
                      <Dropdown
                        isOpen={dropdownOpen}
                        toggle={() => setDropdownOpen(!dropdownOpen)}
                      >
                        <DropdownToggle caret>Action</DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => handleMultiStatus("true")}
                          >
                            Active
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => handleMultiStatus("false")}
                          >
                            Inactive
                          </DropdownItem>
                          <DropdownItem onClick={handleMultiDelete}>
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    ) : (
                      ""
                    )}{" "}
                    <Button color="primary" className="mb-2 ml-auto">
                      <a href="/promo-codes/add" style={{ color: "white" }}>
                        Add New
                      </a>{" "}
                    </Button>
                  </div>

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      {loading ? (
                        <SpinnerLoader/>
                      ) : promoCodeData ? (
                        <Table className="table table-striped table-bordered">
                          <CustomColumn fields={getFields()} index={false} />
                          <Tbody>{getRows()}</Tbody>
                        </Table>
                      ) : (
                        <NoDataAvailable module="promo Codes" />
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {pagination.total_pages && pagination.total_pages > 1 ? (
          <Pagination
            pagination={pagination}
            getData={getPromoCodes}
            search={search}
          />
        ) : null}
      </div>
      <ConfirmationModal
        toggleModal={toggleModal}
        isOpenModal={isOpenModal}
        deleteRecord={handleDelete}
        title={multiDelete ? "Delete Promo Codes" : "Delete Promo Code"}
      />
    </>
  )
}

export default PromoCodes
