import React, { useState, useContext } from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { post, put } from "helpers/api_helper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { textField } from "components/Common/Form/InputFields"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { yupMessages } from "helpers/validations/messages"
import { formats } from "helpers/validations/regex"
import { selectField } from "components/Common/Form/InputFields"

let language_options = [
  { name: "Arabic", value: "ar" },
  { name: "English", value: "en" },
]

let country_options = [
  { name: "UAE", value: "+27" },
  { name: "South Africa", value: "+971" },
]

const eyeSlash = <i className="mdi mdi-eye-off" />
const eye = <i className="mdi mdi-eye" />
const CustomerForm = props => {
  const { disabled, customer_id, data = {} } = props
  const customAlert = useContext(CustomAlertContext)

  const handleSubmit = values => {
    if (values.password == "") {
      delete values.password
    }
    if (values.address == "") {
      delete values.address
    }
    let callApi
    if (customer_id) {
      callApi = updateCustomer(values)
    } else {
      values.otp = 1234
      callApi = addCustomer(values)
    }

    callApi
      .then(res => {
        customAlert.notify(res.message)
        window.location.href = "/customers"
      })
      .catch(err => {
        console.log(`err.response`, err.response)

        customAlert.notify(err.response.data.message, "error")
      })
  }

  const addCustomer = data => post(`customers`, data)

  const updateCustomer = data => put(`customers/${customer_id}`, data)

  const CustomerSchema = Yup.object().shape({
    first_name: Yup.string()
      .strict()
      .trim(`First name ${yupMessages.trim}`)
      .required(`First name ${yupMessages.is_required}`)
      .matches(formats.string, `First name ${yupMessages.alpha_space}`),
    last_name: Yup.string()
      .strict()
      .trim(`Last name ${yupMessages.trim}`)
      .required(`Last name ${yupMessages.is_required}`)
      .matches(formats.string, `Last name ${yupMessages.alpha_space}`),
    password: customer_id
      ? Yup.string()
          .strict()
          .trim(`Password ${yupMessages.trim}`)
          .matches(formats.password, yupMessages.password)
      : Yup.string()
          .trim(`Password ${yupMessages.trim}`)
          .required(`Password ${yupMessages.is_required}`)
          .matches(formats.password, yupMessages.password),
    email: Yup.string()
      .email(`Email ${yupMessages.string_email}`)
      .required(`Email ${yupMessages.is_required}`),
    city: Yup.string()
      .strict()
      .trim()
      .matches(formats.string, yupMessages.city_name),
    contact_number: Yup.string()
      .trim()
      .required(`Contact number ${yupMessages.is_required}`)
      .max(10, `Contact number ${yupMessages.string_max} 10 digits.`)
      .min(10, `Contact number ${yupMessages.string_min} 10 digits.`),
    address: Yup.string().strict().trim(),
    language_code: Yup.string().required(
      `Language selection ${yupMessages.is_required}`
    ),
    country_code: Yup.string().required(
      `Country selection ${yupMessages.is_required}`
    ),
  })

  const formik = useFormik({
    initialValues: {
      first_name: data.first_name || "",
      last_name: data.last_name || "",
      email: data.email || "",
      contact_number: data.contact_number || "",
      address: data.address || "",
      city: data.city || "",
      language_code: data.language_code || "en",
      country_code: data.country_code || "",
    },
    validationSchema: CustomerSchema,
    onSubmit: values => {
      handleSubmit(values)
    },
  })

  const [passwordShown, setPasswordShown] = useState(false)
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown)
  }
  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Row>
                  <Col lg={6}>
                    {textField(
                      formik,
                      "first_name",
                      "First Name*",
                      "Enter your First Name"
                    )}
                  </Col>
                  <Col lg={6}>
                    {textField(
                      formik,
                      "last_name",
                      "Last Name*",
                      "Enter your Last Name"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="mb-3 password-class">
                {textField(
                  formik,
                  "password",
                  "Password*",
                  "Enter your password",
                  false,
                  passwordShown ? "text" : "password"
                )}
                <i
                  className={`icon-password ${
                    formik.errors.password ? "mb-4" : ""
                  }`}
                  onClick={togglePasswordVisiblity}
                >
                  {passwordShown ? eyeSlash : eye}
                </i>{" "}
              </div>
              <div className="mb-3">
                {textField(
                  formik,
                  "email",
                  "E-Mail*",
                  "Enter Valid Email",
                  false,
                  "email"
                )}
              </div>
              <div className="mb-3">
                <Row>
                  <Col xl={6}>
                    {textField(
                      formik,
                      "address",
                      "Address",
                      "Enter your address"
                    )}
                  </Col>
                  <Col xl={6}>
                    {textField(formik, "city", "City", "Enter your city")}
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Row>
                  <Col xl={6}>
                    {selectField(
                      formik,
                      "country_code",
                      "Country*",
                      country_options,
                      "Select country"
                    )}
                  </Col>
                  <Col xl={6}>
                    {textField(
                      formik,
                      "contact_number",
                      "Contact Number*",
                      "Enter your contact number",
                      false,
                      "number"
                    )}
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Row>
                  <Col xl={6}>
                    {selectField(
                      formik,
                      "language_code",
                      "Language",
                      language_options
                    )}
                  </Col>
                </Row>
              </div>
              {disabled ? null : (
                <div className="d-flex flex-wrap gap-2">
                  <Button type="submit" color="primary" className="">
                    Submit
                  </Button>
                </div>
              )}
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default CustomerForm
