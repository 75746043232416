import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import { put } from "helpers/api_helper"

const UserProfile = props => {
  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [id, setidx] = useState(1)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(false)
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setname(`${obj.user.first_name} ${obj.user.last_name}`)
      setemail(obj.user.email)
      setidx(obj.user._id)

      setTimeout(() => {
        props.resetProfileFlag()
      }, 3000)
    }
  }, [props.success])

  function handleValidSubmit(event, values) {
    // props.editProfile(values)
    put(`customers/${id}`, values).then(res => {
      setShowAlert(true)
      setAlertMessage(res.message)
    })
  }

  const dismissAlert = () => {
    setShowAlert(false)
    setAlertMessage(false)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | GoFetchIt</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              <Alert
                color="success"
                isOpen={showAlert}
                toggle={dismissAlert}
                fade={false}
              >
                {alertMessage}
              </Alert>

              <Card>
                <CardBody>
                  <div className="d-flex">
                    {/* <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div> */}
                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">Id no: #{id}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Update Profile</h4>

          <Card>
            <CardBody>
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <div className="mb-3">
                  <AvField
                    name="password"
                    label="Change Password"
                    value={name || ""}
                    className="form-control"
                    placeholder="Enter Password"
                    type="password"
                    required
                  />
                  <AvField
                    name="confirm_password"
                    label="Confirm Password"
                    value={name || ""}
                    className="form-control"
                    type="password"
                    required
                    placeholder="Re-type Password"
                    errorMessage="Enter Re-password"
                    validate={{
                      required: { value: true },
                      match: { value: "password" },
                    }}
                  />
                  <AvField name="id" value={id || ""} type="hidden" />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Change Password
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, { editProfile, resetProfileFlag })(UserProfile)
)
