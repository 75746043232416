import React, { useEffect, useState, useContext } from "react"
import MetaTags from "react-meta-tags"
import { Table, Tbody, Td, Tr } from "react-super-responsive-table"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap" //Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { get, del, post } from "helpers/api_helper"
import ConfirmationModal from "components/Common/Modals/ConfirmationModal"
import NoDataAvailable from "components/Common/NoDataAvailable"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { Pagination } from "components/Common/Pagination"
import { CustomSearch } from "components/Common/CustomSearch"
import NoData from "components/NoData/NoData"
import { TableRowsHoc } from "components/Common/TableRowsHoc"
import SpinnerLoader from "components/Loader/SpinnerLoader"

const DeliverGuys = () => {
  const [deliverGuyData, setDeliverGuys] = useState([])
  const [loading, setIsLoading] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [deleteData, setDeleteData] = useState(false)
  const customAlert = useContext(CustomAlertContext)
  const [selectedTokens, setSelectedTokens] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [pagination, setPagination] = useState({ page_no: 1, limit: 20 })
  const [search, setSearch] = useState("")
  const [multiDelete, setMultiDelete] = useState(false)

  useEffect(() => {
    getDeliverGuys()
  }, [])

  const toggleModal = () => {
    if (isOpenModal) {
      setDeleteData(false)
    }
    setIsOpenModal(!isOpenModal)
    setMultiDelete(false)
  }
  const openModal = (status, data) => {
    setIsOpenModal(status)
    setDeleteData(data)
  }
  const getDeliverGuys = (query = pagination) => {
    setIsLoading(true)
    get("deliverGuys", { params: query }).then(res => {
      const { status, result } = res
      let deliverGuys = result.data.map(dg => {
        dg.name = (
          <span className=" mx-2">
            <a href={`deliver-guys/view/${dg._id}`}>
              {dg.first_name} {dg.last_name || ""}
            </a>
          </span>
        )
        return dg
      })
      setDeliverGuys(deliverGuys)
      setPagination(result.meta_data)
      setIsLoading(false)
    })
  }

  const handleDelete = () => {
    if (multiDelete) {
      post("deliverGuys/multi-delete", {
        ids: selectedTokens.map(tokens => tokens._id),
      })
        .then(res => {
          customAlert.notify(res.message)
          setSelectedTokens([])
          getDeliverGuys()
          setIsOpenModal(false)
          setMultiDelete(false)
        })
        .catch(err => console.log(`err`, err))
    } else {
      del(`deliverGuys/${deleteData._id}`).then(res => {
        const { status, result } = res
        customAlert.notify(res.message)
        setDeleteData(false)
        setIsOpenModal(false)
        getDeliverGuys()
      })
    }
  }

  const handleCheck =
    (user = false) =>
    event => {
      let tokens = []
      let obj = { _id: user._id, device_id: user.device_id }
      if (user) {
        tokens = [...selectedTokens]
        if (event.target.checked) {
          tokens.push(obj)
        } else {
          let index = tokens.findIndex(i => i._id === user._id)
          if (index > -1) {
            tokens.splice(index, 1) // 2nd parameter means remove one item only
          }
        }
      } else {
        if (event.target.checked) {
          deliverGuyData.map((dg, i) =>
            tokens.push({ _id: dg._id, device_id: dg.device_id })
          )
        } else {
          tokens = []
        }
      }

      setSelectedTokens(tokens)
    }
  const getCheckBox = index => user_id => {
    let user = deliverGuyData.length
      ? deliverGuyData.find(x => x._id === user_id)
      : {}
    return (
      <span>
        <input
          type="checkbox"
          onChange={handleCheck(user)}
          checked={selectedTokens.find(token => token._id === user_id)}
        />{" "}
        {/* {(pagination.page_no - 1) * pagination.limit + index + 1} */}
      </span>
    )
  }
  const getSno = index => user_id => {
    return (
      <span>{(pagination.page_no - 1) * pagination.limit + index + 1}</span>
    )
  }
  const getFields = index => {
    return [
      {
        column: (
          <>
            <input
              type="checkbox"
              onChange={handleCheck()}
              checked={
                deliverGuyData.length &&
                selectedTokens.length == deliverGuyData.length
              }
              className="th-checkbox"
            />
          </>
        ),
        key: "_id",
        value: getCheckBox(index),
      },
      {
        column: "S.No.",
        key: "_id",
        value: getSno(index),
      },
      { column: "Name", key: "name" },
      { column: "E-mail", key: "email" },
      {
        column: "Contact Number",
        key: "contact_number",
        value: (cell, row) =>
          (row.country_code ? row.country_code + "-" : "") + row.contact_number,
      },
    ]
  }
  const getRows = () => (
    <TableRowsHoc length={deliverGuyData.length}>
      {deliverGuyData.map((deliverGuy, i) => (
        <CustomRow
          fields={getFields(i)}
          data={deliverGuy}
          section={"deliver-guys"}
          handleDelete={openModal}
          rowStyle={
            deliverGuy.status == false &&
            ((deliverGuy.kyc.document_type && deliverGuy.kyc.status == false) ||
              (deliverGuy.vehicles.vehicle_type &&
                deliverGuy.vehicles.status == false)) &&
            "deliverGuy-list-row"
          }
          pagination={pagination}
          // index={i}
          key={deliverGuy._id}
        />
      ))}
    </TableRowsHoc>
  )
  const handleMultiStatus = status => {
    post("deliver-guy/multi-change-status", {
      ids: selectedTokens.map(tokens => tokens._id),
      status,
    })
      .then(res => {
        customAlert.notify(res.message)
        setSelectedTokens([])
        getDeliverGuys()
      })
      .catch(err => {
        console.log(`err`, err)
        customAlert.notify(err.response.data.message)
        setSelectedTokens([])
      })
  }

  const handleMultiDelete = () => {
    setMultiDelete(true)
    setIsOpenModal(true)
  }
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Delivery Guys | GoFetchIt</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumb title="GoFetchIt" breadcrumbItem="Delivery Guys" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CustomSearch
                    getData={getDeliverGuys}
                    pagination={pagination}
                    setSearch={setSearch}
                    placeholder={"Name, E-mail"}
                  />
                  <div className="d-flex justify-content-between">
                    {selectedTokens.length ? (
                      <Dropdown
                        isOpen={dropdownOpen}
                        toggle={() => setDropdownOpen(!dropdownOpen)}
                      >
                        <DropdownToggle caret>Action</DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => handleMultiStatus("true")}
                          >
                            Active
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => handleMultiStatus("false")}
                          >
                            Inactive
                          </DropdownItem>
                          <DropdownItem onClick={handleMultiDelete}>
                            Delete
                          </DropdownItem>{" "}
                        </DropdownMenu>
                      </Dropdown>
                    ) : null}{" "}
                    <Button color="primary" className="mb-2 ml-auto">
                      <a href="/deliver-guys/add" style={{ color: "white" }}>
                        Add New
                      </a>
                    </Button>
                  </div>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      {loading ? (
                        <SpinnerLoader />
                      ) : deliverGuyData ? (
                        <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <CustomColumn fields={getFields()} index={false} />
                          <Tbody>{getRows()}</Tbody>
                        </Table>
                      ) : (
                        <NoDataAvailable module="deliverGuy Codes" />
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {pagination.total_pages && pagination.total_pages > 1 ? (
          <Pagination
            pagination={pagination}
            getData={getDeliverGuys}
            search={search}
          />
        ) : null}
      </div>
      <ConfirmationModal
        toggleModal={toggleModal}
        isOpenModal={isOpenModal}
        deleteRecord={handleDelete}
        title={multiDelete ? "Delete Delivery Guys" : "Delete Delivery Guy"}
      />
    </>
  )
}

export default DeliverGuys
