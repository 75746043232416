import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  return (
    <Row className="align-items-center">
      <Col sm={6}>
        <div className="page-title-box">
          <h4 className="font-size-18 text-capitalize">
            {props.breadcrumbItem}
          </h4>
          <ol className="breadcrumb mb-0">
            <BreadcrumbItem>
              <span>
                <Link to="/#">GoFetchIt</Link>
              </span>
            </BreadcrumbItem>
            {props.breadcrumb1 ? (
              <BreadcrumbItem>
                <span>
                  <Link to={props.breadcrumb1.href}>
                    {props.breadcrumb1.title}
                  </Link>
                </span>
              </BreadcrumbItem>
            ) : null}
            <BreadcrumbItem className="text-capitalize" active>
              {props.breadcrumbItem}
            </BreadcrumbItem>
          </ol>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumb
