import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Table, Tbody, Td, Tr } from "react-super-responsive-table"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { get, del, put } from "helpers/api_helper"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
import { getOrderStatus } from "utils/helpers/order"
import { Pagination } from "components/Common/Pagination"
import { CustomSearch } from "components/Common/CustomSearch"
import { TableRowsHoc } from "components/Common/TableRowsHoc"
import CustomSpinner from "components/CustomSpinner"
import Select from "react-select"

const scheduledOptions = [
  { value: "true", label: "Scheduled" },
  { value: "false", label: "Live" },
  { value: "all", label: "All" },
]

const statusOptions = [
  { value: "1", label: "Confirmed" },
  { value: "2", label: "In-Process" },
  { value: "3", label: "Delivered" },
  { value: "4", label: "Cancelled" },
  { value: "all", label: "All" },
  { value: "0", label: "In-Cart" },
]

const Orders = () => {
  const [orderData, setOrderData] = useState([])
  const [pagination, setPagination] = useState({ page_no: 1, limit: 20 })
  const [loading, setIsLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [status, setStatus] = useState({ value: "all", label: "All" })
  const [type, setType] = useState({ value: "all", label: "All" })

  useEffect(() => {
    getOrders()
  }, [])

  const getOrders = (query = pagination) => {
    let filters = query
    if (status && status.value !== "all") {
      filters.status = status.value
    }
    if (type && type.value) {
      filters.is_scheduled = type.value
    }

    setIsLoading(true)
    get("orders", { params: filters }).then(res => {
      const { status, result } = res
      setOrderData(result.data)
      setPagination(result.meta_data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    handleFilterSelections()
  }, [status, type, search])

  const handleFilterSelections = () => {
    setPagination({ page_no: 1, limit: 20 })
    setIsLoading(true)
    let filters = { page_no: 1, limit: 20 }
    if (search) {
      filters.search = search
    }
    if (status && status.value !== "all") {
      filters.status = status.value
    }
    if (type && type.value) {
      filters.is_scheduled = type.value
    }

    get("orders", { params: filters })
      .then(res => {
        const { result } = res
        setOrderData(result.data)
        setPagination(result.meta_data)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  const getAddress = key => key["address"]
  const getCustomerName = key =>
    key ? (
      <a
        href={`/customers/view/${key["_id"]}`}
      >{`${key["first_name"]} ${key["last_name"]}`}</a>
    ) : null

  // const getOrderTrackId = key => `#${key}`
  const getOrderTrackId = n_id => {
    let order = orderData.length ? orderData.find(n => n._id == n_id) : ""
    let n_link = ""
    if (order.title) {
      n_link = `orders/view/${order._id}`
    }
    return n_link ? (
      <>
        <a href={n_link}>{order.order_track_id}</a>
        <br />
        {getOrderStatus("light", order.is_scheduled ? "Schedule" : "Same-Day")}
      </>
    ) : (
      order.order_track_id
    )
  }
  let fields = [
    { column: "Order ID", key: "_id", value: getOrderTrackId },
    {
      column: "Customer",
      key: "customer_details",
      value: getCustomerName,
    },
    { column: "Pickup Address", key: "pickup_address", value: getAddress },
    { column: "Delivery Address", key: "delivery_address", value: getAddress },
    { column: "Status", key: "status", value: getOrderStatus },
  ]

  const getRows = () => (
    <TableRowsHoc length={orderData.length}>
      {orderData.map((order, i) => (
        <CustomRow
          fields={fields}
          data={order}
          section={"orders"}
          status={false}
          action={{ view: true }}
          key={order._id}
          index={i}
          pagination={pagination}

          // handleDelete={openModal}
        />
      ))}
    </TableRowsHoc>
  )

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Orders | GoFetchIt</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumb title="GoFetchIt" breadcrumbItem="Orders" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <div style={{ width: "200px", marginRight: "12px" }}>
                      <Select
                        options={scheduledOptions}
                        placeholder={"Select Type"}
                        onChange={setType}
                        value={type}
                        isClearable={false}
                        isSearchable={false}
                      />
                    </div>
                    <div style={{ width: "200px", marginRight: "12px" }}>
                      <Select
                        options={statusOptions}
                        placeholder={"Select Status"}
                        onChange={setStatus}
                        value={status}
                        isClearable={false}
                        isSearchable={false}
                      />
                    </div>
                    <CustomSearch
                      getData={getOrders}
                      pagination={pagination}
                      setSearch={setSearch}
                      placeholder={"Order Track ID"}
                    />
                  </div>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      {
                        <Table className="table table-striped table-bordered">
                          <CustomColumn fields={fields} status={false} />
                          <Tbody>
                            {loading ? (
                              <CustomSpinner span={fields.length + 3} />
                            ) : (
                              getRows()
                            )}
                          </Tbody>
                        </Table>
                      }
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {pagination.total_pages && pagination.total_pages > 1 ? (
          <Pagination
            pagination={pagination}
            getData={getOrders}
            search={search}
          />
        ) : null}
      </div>
    </>
  )
}

export default Orders
