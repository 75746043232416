export const getTransactionType = t_type => {
  let value = ""
  switch (t_type) {
    // "ww" => withdrawal from wallet, "dw" => depositted to wallet, "cop" => customer order payment, "dop" => delivery boy order payment , "fa" => fee added to admin, "cb" => Cashback to customer, "pfa" => payment to gofetch admin, "wwp" => withdrawal wallet to payment from admin
    case "ww":
      value = "withdrawal from wallet"
      break
    case "wr":
      value = "withdrawal request from wallet"
      break
    case "dw":
      value = "depositted to wallet"
      break
    case "cop":
      value = "customer order payment"
      break
    case "dop":
      value = "delivery boy order payment"
      break
    case "fa":
      value = "fee added to admin"
      break
    case "cb":
      value = "Cashback to customer"
      break
    case "pfa":
      value = "payment to goFetch admin"
      break
    case "refund_customer":
      value = "Refund amount to customer"
      break
    default:
      value = "withdrawal wallet to payment from admin" //case: wwp
      break
  }
  return value
}

export const getStatus = status => {
  let value = ""
  switch (status) {
    case 0:
      value = "Pending"
      break
    case 1:
      value = "Completed"
      break
    default:
      value = "Failed"
      break
  }
  return value
}

export const getWithdrawalStatus = status => {
  let value = ""
  switch (status) {
    case 0:
      value = "Pending"
      break
    case 1:
      value = "Completed"
      break
    default:
      value = "Rejected"
      break
  }
  return value
}
