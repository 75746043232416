import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { get } from "helpers/api_helper"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { Col, Card, CardBody } from "reactstrap"
import SpinnerLoader from "components/Loader/SpinnerLoader"
// import moment from "moment"
const ViewNotification = props => {
  const [notification, setNotification] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    get(`notification/${props.match.params.id}`).then(res => {
      const { status, result } = res
      setNotification(result)
      setLoading(false)
    })
  }, [])
  return (
    <div className="page-content">
      <MetaTags>
        <title>Notifications | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb title="Notifications" breadcrumbItem="View Notification" />
        {loading ? (
          <SpinnerLoader />
        ) : (
          <Col>
            <Card>
              <CardBody>
                <div>
                  Admin Notification : {`${notification.admin}`}
                  <div className="d-flex justify-content-between">
                    <p className="mb-0">Id no: #{notification._id}</p>
                    <p className="mb-0">
                      Order ID no: #{notification.order_id}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="mb-0">User ID no: #{notification.user_id}</p>
                    <p className="mb-0">User Type: {notification.user_type}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h5>Title: {notification.title}</h5>
                    <p>Notification Type: {notification.noti_type}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="mb-1">
                      Description: {notification.description}
                    </p>
                  </div>
                  <div className="d-flex ">
                    <p className="mb-1 flex-grow-1">
                      Discount Type: {notification.discount_type}
                    </p>
                    <p className="mb-1">Discount: {notification.discount}</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
      </div>
    </div>
  )
}

export default ViewNotification
