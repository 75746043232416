import React from "react"
import MetaTags from "react-meta-tags"

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import DeliverGuyForm from "components/DeliverGuys/DeliverGuyForm"

const AddDeliverGuy = props => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>Delivery Guys | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Delivery Guys", href: "/deliver-guys" }}
          breadcrumbItem="Add Delivery Guy"
        />
        <DeliverGuyForm data={false} />{" "}
      </div>
    </div>
  )
}

export default AddDeliverGuy
