import { useEffect, useState, useRef } from "react"
import { Label } from "reactstrap"

const RichTextEditor = ({ formik, name, label, ...props }) => {
  const editorRef = useRef()
  const [editorLoaded, setEditorLoaded] = useState(false)

  const { CKEditor, DecoupledEditor } = editorRef.current || {}
  const [data, setData] = useState("")

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, //Added .CKEditor
      DecoupledEditor: require("@ckeditor/ckeditor5-build-decoupled-document"),
    }
    setEditorLoaded(true)
  }, [])

  useEffect(() => {
    if (name == "menu_dishes" && formik.values) {
      formik.values.menu_dishes[0]
        ? setData(formik.values.menu_dishes[0])
        : setData("")
    } else {
      setData(formik.values[name])
    }
  }, [formik.values[name]])

  const editorConfiguration = {
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "alignment",
      "blockQuote",
      "codeBlock",
      "emoticon",
      "alignment:left",
      "alignment:right",
      "alignment:center",
    ],
  }

  const handleChangeText = (e, editor) => {
    const content = editor.getData()
    setData(content)
  }

  return (
    <div style={{ fontWeight: "initial" }}>
      <Label
        className={` ${formik.errors[name] ? "richTextError" : ""}`}
        variant="h6"
      >
        {label}
      </Label>
      {editorLoaded ? (
        <CKEditor
          onReady={editor => {
            // Insert the toolbar before the editable area.
            editor.ui
              .getEditableElement()
              .parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
              )

            // this.editor = editor;
          }}
          onError={({ willEditorRestart }) => {
            // If the editor is restarted, the toolbar element will be created once again.
            // The `onReady` callback will be called again and the new toolbar will be added.
            // This is why you need to remove the older toolbar.
            if (willEditorRestart) {
              this.editor.ui.view.toolbar.element.remove()
            }
          }}
          editor={DecoupledEditor}
          // editor={ClassicEditor}
          data={data}
          onChange={(event, editor) => handleChangeText(event, editor)}
          onBlur={(event, editor) => {
            if (name == "menu_dishes") {
              data
                ? formik.setFieldValue("menu_dishes", [data])
                : formik.setFieldValue("menu_dishes", [])
            } else {
              formik.setFieldValue(name, data)
            }
          }}
          config={editorConfiguration}
        />
      ) : null}
      <span className="error">{formik.errors[name]}</span>
    </div>
  )
}

export default RichTextEditor
