import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { get } from "helpers/api_helper"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { Col, Card, CardBody, Row } from "reactstrap"
import SpinnerLoader from "components/Loader/SpinnerLoader"

let country_options = {
  "+27": "UAE",
  "+971": "South Africa",
}


const ViewDeliverGuy = props => {
  const [deliverGuy, setDeliverGuy] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    get(`deliverGuys/${props.match.params.id}`).then(res => {
      const { status, result } = res
      setDeliverGuy(result)
      setLoading(false)
    })
  }, [])
  return (
    <div className="page-content">
      <MetaTags>
        <title>Delivery Guys | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Delivery Guys", href: "/deliver-guys" }}
          breadcrumbItem="View Delivery Guy"
        />
        {loading ? (
          <SpinnerLoader />
        ) : (
          <Card>
            <CardBody>
              <Row>
                <div className="col-12 fs-5">
                  <div className="invoice-title"></div>
                  <hr />
                  <Row>
                    <div className="col-6">
                      <address>
                        <strong>Id no:</strong>
                        <br />#{deliverGuy._id}
                      </address>
                    </div>
                    <div className="col-6 text-end">
                      <address>
                        <strong>Online:</strong>
                        <br />
                        {`${deliverGuy.online_status}`}
                      </address>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-6">
                      <address>
                        <strong>Name:</strong>
                        <br />
                        {`${deliverGuy.first_name} ${
                          deliverGuy.last_name || ""
                        } `}
                      </address>
                    </div>
                    <div className="col-6 text-end">
                      <address>
                        <strong>Email:</strong>
                        <br />
                        {deliverGuy.email}
                      </address>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-6">
                      <address>
                        <strong>Contact No:</strong>
                        <br />
                        {(deliverGuy.country_code
                          ? deliverGuy.country_code + "-"
                          : "") + deliverGuy.contact_number}
                      </address>
                    </div>
                    <div className="col-6 text-end">
                      <address>
                        <strong>Country:</strong>
                        <br />
                        {country_options[deliverGuy.country_code]}
                      </address>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-6">
                      <address>
                        <strong>Status:</strong>
                        <br />
                        {deliverGuy.status ? "Verified" : "Not Verified"}
                      </address>
                    </div>
                    {deliverGuy.city ? (
                      <div className="col-6 text-end">
                        <address>
                          <strong>City:</strong>
                          <br />
                          {deliverGuy.city}
                        </address>
                      </div>
                    ) : null}
                  </Row>
                </div>
              </Row>
            </CardBody>
          </Card>
        )}
      </div>
    </div>
  )
}

export default ViewDeliverGuy
