import React, { useEffect, useState, useRef, useContext } from "react"
import { Row, Col, Card, CardBody, Button, Alert } from "reactstrap"
import { post, put } from "helpers/api_helper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { selectField, textField } from "components/Common/Form/InputFields"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { yupMessages } from "helpers/validations/messages"
import { formats } from "helpers/validations/regex"
import dayjs from "dayjs"
const discount_options = [
  { name: "percent", value: "percent" },
  { name: "cash", value: "cash" },
]

const coupon_options = [
  { name: "cashback", value: "cashback" },
  { name: "instant", value: "instant" },
]
const PromoCodeForm = props => {
  const { disabled, promo_code_id, p_data } = props
  const customAlert = useContext(CustomAlertContext)

  const handleSubmit = values => {
    let callApi
    if (promo_code_id) {
      callApi = updatePromoCode(values)
    } else {
      callApi = addPromoCode(values)
    }

    callApi
      .then(res => {
        customAlert.notify(res.message)

        window.location.href = "/promo-codes"
      })
      .catch(err => {
        console.log(`err.response`, err.response)

        customAlert.notify(err.response.data.message)
      })
  }

  const addPromoCode = data => post(`promoCodes`, data)

  const updatePromoCode = data => put(`promoCodes/${promo_code_id}`, data)

  const promoCodeSchema = Yup.object().shape({
    promo_code: Yup.string()
      .trim(`Promo code ${yupMessages.trim}`)
      .required(`Promo code ${yupMessages.is_required}`),
    discount_type: Yup.string().required(
      `Discount type ${yupMessages.is_required}`
    ),
    discount: Yup.string().required(`Discount ${yupMessages.is_required}`),

    start_date: Yup.string().required(`Start date ${yupMessages.is_required}`),
    end_date: Yup.string()
      .required(`End date ${yupMessages.is_required}`)
      .test(
        "end_date",
        "end date should be greater than or equal to start date",
        (a, b) => {
          const { start_date } = b.parent
          a
            ? console.log(
                `dayjs(a).diff(start_date)`,
                dayjs(a).diff(start_date)
              )
            : ""
          return a ? dayjs(a).diff(start_date) >= 0 : true
        }
      ),
  })

  const formik = useFormik({
    initialValues: {
      promo_code: p_data ? p_data.promo_code : "",
      start_date: p_data ? p_data.start_date : "",
      end_date: p_data ? p_data.end_date : "",
      discount_type: p_data ? p_data.discount_type : "",
      // coupon_type: p_data ? p_data.coupon_type : "",
      discount: p_data ? p_data.discount : "",
    },
    validationSchema: promoCodeSchema,
    onSubmit: values => {
      handleSubmit(values)
    },
  })

  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Row>
                  <Col lg={6}>
                    {textField(
                      formik,
                      "promo_code",
                      "Coupon Code*",
                      "Enter Promo Code"
                    )}
                  </Col>
                </Row>
              </div>

              <div className="mb-3">
                <Row>
                  <Col lg={6}>
                    {textField(
                      formik,
                      "start_date",
                      "Start Date*",
                      "Promo Code Start Date",
                      false,
                      "date"
                    )}
                  </Col>
                  <Col lg={6}>
                    {textField(
                      formik,
                      "end_date",
                      "End Date*",
                      "Promo Code End Date",
                      false,
                      "date"
                    )}
                  </Col>
                </Row>
              </div>

              <div className="mb-3">
                <Row>
                  <Col xl={6}>
                    {selectField(
                      formik,
                      "discount_type",
                      "Discount Type*",
                      discount_options,
                      "Select Discount Type"
                    )}
                  </Col>
                  <Col xl={6}>
                    {textField(
                      formik,
                      "discount",
                      "Discount*",
                      "Enter discount",
                      false,
                      "number"
                    )}
                  </Col>
                </Row>
              </div>

              {disabled ? null : (
                <div className="d-flex flex-wrap gap-2">
                  <Button type="submit" color="primary" className="">
                    Submit
                  </Button>{" "}
                </div>
              )}
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default PromoCodeForm
