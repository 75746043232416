import axios from "axios"
const API_URL = process.env.REACT_APP_BASEURL
// const API_URL = process.env.REACT_APP_LOCALURL

axios.defaults.baseURL = API_URL

export async function get(url, config = {}) {
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.response.status === 401) {
        window.location.href = "/login"
      }
      return Promise.reject(error)
    }
  )

  return await axios
    .get(url, { ...config, headers: getCommonHeaders() })
    .then(response => response.data)
}

export async function post(url, data, config = {}) {
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.response.status === 401) {
        window.location.href = "/login"
      }
      return Promise.reject(error)
    }
  )

  return axios
    .post(url, data, { ...config, headers: getCommonHeaders() })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.response.status === 401) {
        window.location.href = "/login"
      }
      return Promise.reject(error)
    }
  )

  return axios
    .put(url, data, { ...config, headers: getCommonHeaders() })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.response.status === 401) {
        window.location.href = "/login"
      }
      return Promise.reject(error)
    }
  )

  return await axios
    .delete(url, { ...config, headers: getCommonHeaders() })
    .then(response => response.data)
}

function getCommonHeaders() {
  let accessToken = ""

  try {
    accessToken = JSON.parse(localStorage.getItem("authUser")).token
  } catch (error) {}

  return {
    authorization: accessToken,
    role: "admin",
    "Content-Type": "application/json",
  }
}
