import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (
          pathName === items[i].pathname ||
          pathName.match(items[i].pathname)
        ) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  const list = [
    {
      to: "/customers",
      icon: <i className="mdi mdi-account-circle-outline" />,
      label: props.t("Customer"),
    },
    {
      to: "/deliver-guys",
      icon: <i className="mdi mdi-shield-account-outline" />,
      label: props.t("Delivery Guys"),
    },
    {
      to: "/package-categories",
      icon: <i className="mdi mdi-star-outline" />,
      label: props.t("Package Categories"),
    },
    {
      to: "/time-slots",
      icon: <i className="ti-timer" />,
      label: props.t("Time Slots"),
    },
    {
      to: "/working-hours",
      icon: <i className="ti-timer" />,
      label: props.t("Working Hours"),
    },
    {
      to: "/notifications",
      icon: <i className="ti-bell" />,
      label: props.t("Notifications"),
    },
    {
      to: "/orders",
      icon: <i className="mdi mdi-clipboard-text-multiple-outline" />,
      label: props.t("Orders"),
    },
    {
      to: "/promo-codes",
      icon: <i className="mdi mdi-brightness-percent" />,
      label: props.t("Promo Codes"),
    },
    {
      to: "/transactions",
      icon: <i className="mdi mdi-ballot-recount-outline" />,
      label: props.t("Transactions"),
    },
    {
      to: "/contact-queries",
      icon: <i className="mdi mdi-account-tie-voice" />,
      label: props.t("Contact Queries"),
    },
    // {
    //   to: "/price-per-km",
    //   icon: <i className="mdi mdi-cash-100" />,
    //   label: props.t("Price Per KM"),
    // },
    {
      to: "/reviews",
      icon: <i className="mdi mdi-star-outline" />,
      label: props.t("Reviews"),
    },
    {
      to: "/withdrawal-requests",
      icon: <i className="mdi mdi-bank-transfer-out" />,
      label: "Withdrawals",
    },
    {
      to: "/kyc-options",
      icon: <i className="ti-id-badge" />,
      label: "KYC Options",
    },
    {
      to: "/pages",
      icon: <i className="mdi mdi-page-next-outline" />,
      label: "Pages",
    },
    {
      to: "/faqs",
      icon: <i className="mdi mdi-comment-question-outline" />,
      label: "FAQs",
    },
    {
      to: "/service-area",
      icon: <i className="mdi mdi-google-maps" />,
      label: "Service Areas",
    },
    {
      to: "/support/customer",
      icon: <i className="mdi mdi-wechat" />,
      label: "Customer Support",
    },
    {
      to: "/support/driver",
      icon: <i className="mdi mdi-bike" />,
      label: "Driver Support",
    },
  ]

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ti-home"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            {list.map((item, i) => (
              <li key={i}>
                <Link to={item.to} className="waves-effect">
                  {item.icon}
                  <span>{item.label}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
