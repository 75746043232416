import React, { useState, useRef, useCallback, useEffect } from "react"
import {
  LoadScript,
  GoogleMap,
  DrawingManager,
  Polygon,
  Marker,
} from "@react-google-maps/api"

const libraries = ["drawing"]

const drawing_manager_options = {
  drawingControl: true,
  drawingControlOptions: {
    drawingModes: ["polygon"],
  },
  polygonOptions: {
    fillColor: `#2196F3`,
    strokeColor: `#2196F3`,
    fillOpacity: 0.5,
    strokeWeight: 2,
    clickable: true,
    editable: true,
    draggable: true,
    zIndex: 1,
  },
}

const map_center = {
  lat: -33.99854,
  lng: 18.5058128,
}

const polygon_options = {
  fillColor: `#2196F3`,
  strokeColor: `#2196F3`,
  fillOpacity: 0.5,
  strokeWeight: 2,
}

class LoadScriptOnlyIfNeeded extends LoadScript {
  componentDidMount() {
    const cleaningUp = true
    const isBrowser = typeof document !== "undefined" // require('@react-google-maps/api/src/utils/isbrowser')
    const isAlreadyLoaded =
      window.google &&
      window.google.maps &&
      document.querySelector("body.first-hit-completed") // AJAX page loading system is adding this class the first time the app is loaded
    if (!isAlreadyLoaded && isBrowser) {
      // @ts-ignore
      if (window.google && !cleaningUp) {
        console.error("google api is already presented")
        return
      }

      this.isCleaningUp().then(this.injectScript)
    }

    if (isAlreadyLoaded) {
      this.setState({ loaded: true })
    }
  }
}
const API_KEY = process.env.REACT_APP_GOOGLE_KEY

export default function Map({
  paths = [],
  point,
  setMapName,
  draggableFalse,
  ...props
}) {
  const [path, setPath] = useState(paths)
  const [state, setState] = useState({
    drawingMode: "polygon",
  })
  useEffect(() => {
    setPath(paths)
  }, [paths])
  const noDraw = () => {
    setState(function set(prevState) {
      return Object.assign({}, prevState, {
        drawingMode: "maker",
      })
    })
  }

  const onPolygonComplete = React.useCallback(
    function onPolygonComplete(poly) {
      const polyArray = poly.getPath().getArray()
      let paths = []
      polyArray.forEach(function (path) {
        paths.push({ lat: path.lat(), lng: path.lng() })
      })
      setPath(paths)

      point(paths)
      noDraw()
      poly.setMap(null)
    },
    [point]
  )

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null)
  const listenersRef = useRef([])

  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() }
        })
      setPath(nextPath)
      point(nextPath)
    }
  }, [setPath, point])

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    polygon => {
      polygonRef.current = polygon
      const path = polygon.getPath()
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      )
    },
    [onEdit]
  )

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove())
    polygonRef.current = null
  }, [])

  const getAllAreas = list =>
    list.map(item => (
      <Polygon
        options={polygon_options}
        path={item.data}
        onLoad={onLoad}
        onUnmount={onUnmount}
      />
    ))

  return (
    <LoadScriptOnlyIfNeeded
      id="script-loader"
      googleMapsApiKey={API_KEY}
      libraries={libraries}
      language="en"
      region="in"
    >
      <GoogleMap
        mapContainerClassName={"google-map-container"}
        center={map_center}
        zoom={10}
        version="weekly"
        options={{
          streetViewControl: false,
          disableDefaultUI: true,
          zoomControl: true,
        }}
      >
        {props.allPaths && typeof props.allPaths.map === "function" ? (
          getAllAreas(props.allPaths)
        ) : path.length === 0 ? (
          <DrawingManager
            drawingMode={state.drawingMode}
            options={drawing_manager_options}
            onPolygonComplete={onPolygonComplete}
            editable
            draggable
            // Event used when manipulating and adding points
            onMouseUp={onEdit}
            // Event used when dragging the whole Polygon
            onDragEnd={onEdit}
          />
        ) : (
          <Polygon
            options={polygon_options}
            // Make the Polygon editable / draggable
            editable
            draggable={draggableFalse}
            path={path}
            // Event used when manipulating and adding points
            onMouseUp={onEdit}
            // Event used when dragging the whole Polygon
            onDragEnd={onEdit}
            onLoad={onLoad}
            onUnmount={onUnmount}
          />
        )}
        {path.map((pos, key) => {
          return <Marker key={key} label={"" + key} position={pos} />
        })}
      </GoogleMap>
    </LoadScriptOnlyIfNeeded>
  )
}
