import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Table, Tbody, Td, Tr } from "react-super-responsive-table"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { get } from "helpers/api_helper"
import NoDataAvailable from "components/Common/NoDataAvailable"
import { getStatus, getTransactionType } from "utils/helpers/transaction"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
import { Pagination } from "components/Common/Pagination"
import { CustomSearch } from "components/Common/CustomSearch"
import NoData from "components/NoData/NoData"
import { TableRowsHoc } from "components/Common/TableRowsHoc"
import { currency } from "helpers/currency"
import SpinnerLoader from "components/Loader/SpinnerLoader"

const Transactions = () => {
  const [transactionData, setTransactionData] = useState([])
  const [loading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({ page_no: 1, limit: 20 })
  const [search, setSearch] = useState("")

  useEffect(() => {
    getTransactions()
  }, [])

  const getTransactions = (query = pagination) => {
    setIsLoading(true)
    get("transactions", { params: query }).then(res => {
      const { status, result } = res
      setTransactionData(result.data)
      setPagination(result.meta_data)
      setIsLoading(false)
    })
  }

  const getTransactionId = n_id => {
    let transaction = transactionData.length
      ? transactionData.find(n => n._id == n_id)
      : ""
    let n_link = ""
    if (transaction.order_id) {
      n_link = `orders/view/${transaction.order_id}`
    } else if (transaction.user_type == "customers") {
      n_link = `customers/view/${transaction.user_id}`
    }
    return n_link ? (
      <a href={n_link} target="blank">
        {transaction.t_id}
      </a>
    ) : (
      transaction.t_id
    )
  }

  const getTransactionAmount = (item, row) => {
    let amount = ""
    if (row.currency) {
      amount += row.currency + " "
    }
    amount += item
    return amount
  }

  let fields = [
    { column: "Transaction ID", key: "_id", value: getTransactionId },
    { column: "Amount", key: "amount", value: getTransactionAmount },
    { column: "Description", key: "title" },
    { column: "Status", key: "status", value: getStatus },
  ]
  const getRows = () => (
    <TableRowsHoc length={transactionData.length}>
      {transactionData.map((transaction, i) => (
        <CustomRow
          fields={fields}
          data={transaction}
          section={"transactions"}
          action={{ view: true }}
          status={false}
          key={transaction._id}
          index={i}
          pagination={pagination}
        />
      ))}
    </TableRowsHoc>
  )

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Transactions | GoFetchIt</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumb title="GoFetchIt" breadcrumbItem="Transactions" />

          <Card>
            <CardBody>
              <CustomSearch
                getData={getTransactions}
                pagination={pagination}
                setSearch={setSearch}
                placeholder={"Transaction ID"}
              />
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  {loading ? (
                    <SpinnerLoader/>
                  ) : transactionData ? (
                    <Table className="table table-striped table-bordered">
                      <CustomColumn fields={fields} status={false} />
                      <Tbody>{getRows()}</Tbody>
                    </Table>
                  ) : (
                    <NoDataAvailable module="transactions" />
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        {pagination.total_pages && pagination.total_pages > 1 ? (
          <Pagination
            pagination={pagination}
            getData={getTransactions}
            search={search}
          />
        ) : null}
      </div>
    </>
  )
}

export default Transactions
