import React from "react"
import MetaTags from "react-meta-tags"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import KycOptionForm from "components/KycOptions/KycOptionForm"
const AddKycOption = props => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>KYC Options | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "KYC Options", href: "/kyc-options" }}
          breadcrumbItem="Add KYC Option"
        />
        <KycOptionForm data={false} />{" "}
      </div>
    </div>
  )
}

export default AddKycOption
