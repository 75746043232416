import React from "react"
import MetaTags from "react-meta-tags"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import FaqForm from "components/Faqs/FaqForm"

const AddFaq = () => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>FAQs | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "FAQs", href: "/faqs" }}
          breadcrumbItem="Add Faq"
        />
        <FaqForm data={false} />
      </div>
    </div>
  )
}

export default AddFaq
