import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { get } from "helpers/api_helper"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { Col, Card, CardBody, Row } from "reactstrap"
import SpinnerLoader from "components/Loader/SpinnerLoader"

const ViewContactQueries = props => {
  const [customer_support, setCustomerSupport] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    get(`customer-support/${props.match.params.id}`).then(res => {
      const { result } = res
      setCustomerSupport(result)
      setLoading(false)
    })
  }, [])
  return (
    <div className="page-content">
      <MetaTags>
        <title>CustomerSupports | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{
            title: "Customer Supports",
            href: "/contact-queries",
          }}
          breadcrumbItem="View Customer Support"
        />
        {loading ? (
          <SpinnerLoader/>
        ) : (
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <div className="col-12 fs-5">
                    <div className="invoice-title"></div>
                    <hr />
                    <Row>
                      <div className="col-6">
                        <address>
                          <strong>Id no:</strong>
                          <br />#{customer_support._id}
                        </address>
                      </div>
                      <div className="col-6 text-end">
                        <address>
                          <strong>Name:</strong>
                          <br />
                          {customer_support.full_name ||
                            customer_support.first_name}
                        </address>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-6">
                        <address>
                          <strong>Email:</strong>
                          <br />
                          {customer_support.email}
                        </address>
                      </div>
                      <div className="col-6 text-end">
                        <address>
                          <strong>Contact no:</strong>
                          <br />
                          {customer_support.phone ||
                            customer_support.contact_number}
                        </address>
                      </div>
                    </Row>
                    <Row>
                      <div className="">
                        <address>
                          <strong>Message:</strong>
                          <br />
                          {customer_support.message}
                        </address>
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        )}
      </div>
    </div>
  )
}

export default ViewContactQueries
