import React, { useEffect, useState, useContext } from "react"
import MetaTags from "react-meta-tags"
import { get, post } from "helpers/api_helper"
import Breadcrumb from "components/Common/Breadcrumb"
// import { Col, Card, CardBody, Row, Button } from "reactstrap"
// import { getDeliverGuyStatus } from "utils/helpers/deliverGuy"
import Lightbox from "react-image-lightbox"
// import { CustomAlertContext } from "utils/context/CustomAlertContext"
import KYCForm from "components/DeliverGuys/KYCForm"
const ViewKYC = props => {
  const [kycData, setKYCData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [lightbox_image, setIsOpen] = useState(false)
  // const customAlert = useContext(CustomAlertContext)

  const deliverGuy_id = props.match.params.id
  useEffect(() => {
    setLoading(true)
    get(`deliverGuy/${deliverGuy_id}/kyc`)
      .then(res => {
        const { result } = res
        setKYCData(result)
        setLoading(false)
      })
      .catch(er => console.log(`er`, er))
  }, [])

  // const changeStatus = status => () => {
  //   let data = {
  //     key: "kyc",
  //     status,
  //   }
  //   post(`verify-deliver-guy/${deliverGuy_id}`, data).then(res => {
  //     const { result } = res
  //     customAlert.notify(status === 1 ? "Approved" : "Rejected")
  //     window.location.href = "/deliver-guys"
  //   })
  // }
  return (
    <div className="page-content">
      <MetaTags>
        <title>Delivery Guys View KYC | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Delivery Guys", href: "/deliver-guys" }}
          breadcrumbItem="KYC"
        />
        {lightbox_image && (
          <Lightbox
            mainSrc={lightbox_image}
            onCloseRequest={() => setIsOpen(false)}
          />
        )}
        <KYCForm
          kyc_data={kycData}
          disabled={kycData && kycData.document_type ? true : false}
          deliverGuy_id={deliverGuy_id}
        />
      </div>
    </div>
  )
}

export default ViewKYC
