import Breadcrumb from "components/Common/Breadcrumb"
import SpinnerLoader from "components/Loader/SpinnerLoader"
import Map from "components/Maps/Map"
import { get } from "helpers/api_helper"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"

const ShowAllService = () => {
  const [serviceArea, setServiceArea] = useState({})
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    setLoader(true)
    get(`service-areas`)
      .then(res => {
        setServiceArea(res.result)
        setLoader(false)
      })
      .catch(err => {
        setLoader(false)
      })
  }, [])
  return (
    <div className="page-content">
      <MetaTags>
        <title>Service Area | GoFetch</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Service Areas", href: "/service-area" }}
          breadcrumbItem="All"
        />
        {loader ? (
          <SpinnerLoader />
        ) : serviceArea.data && serviceArea.data.length ? (
          <Map allPaths={serviceArea.data} />
        ) : (
          <div className="p-4">
            <div className="d-flex justify-content-center">
              No Service Area Available.
            </div>
            <div className="d-flex justify-content-center">
              <a href="/service-area/add">
                Please add your first service area.
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ShowAllService
