import React, { useContext, useEffect, useState } from "react"
import { get, post, put } from "helpers/api_helper"

import { Col, Card, CardBody, Row, Button } from "reactstrap"
import Lightbox from "react-image-lightbox"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
// import { getDeliverGuyStatus } from "utils/helpers/deliverGuy"
import { ModalContext } from "utils/context/ModalContext"
import CustomImageInput from "components/Common/CustomImageUpload"
import { useFormik } from "formik"
import * as Yup from "yup"
import { textField, selectField } from "components/Common/Form/InputFields"
import { formats } from "helpers/validations/regex"
import SpinnerLoader from "components/Loader/SpinnerLoader"

const vehicleSchema = Yup.object().shape({
  // vehicle_type: Yup.string().required("Required"),
  model: Yup.string()
    .required("Model is required.")
    .matches(
      /^[aA-zZ0-9\s]+$/,
      "Model can contain only alphabets, numbers and spaces."
    ),
  make_year: Yup.number()
    .required("Make year is required.")
    .min(1980)
    .max(new Date().getFullYear()),
  color: Yup.string()
    .strict()
    .trim()
    .required("Color is required.")
    .matches(/^[aA-zZ\s]+$/, "Color can contain only alphabets and spaces."),
  brand_name: Yup.string()
    .required("Brand name is required.")
    .matches(
      /^[aA-zZ\s]+$/,
      "Brand name can contain only alphabets and spaces."
    ),
  license_plate: Yup.string()
    .required("License plate is required.")
    .matches(
      /^[A-Z0-9\s]+$/,
      "License plate  can contain only capital letter and numbers."
    ),
  rc_no: Yup.string()
    .required("RC is required")
    .matches(
      /^[A-Z0-9\s]+$/,
      "RC number can contain only capital letter and numbers."
    ),
  // chassis_no: Yup.string().required("Required"),
  vehicle_image: Yup.mixed()
    .required("Vehicle image is required.")
    .test("fileFormat", "forms.errors.unsupportedFormat", value => {
      return value && value.length && typeof value != "string"
        ? value.every(file => SUPPORTED_FORMATS.includes(file.type))
        : true
    }),
  rc_image: Yup.mixed()
    .required("RC image is required.")
    .test("fileFormat", "forms.errors.unsupportedFormat", value => {
      return value && value.length && typeof value != "string"
        ? value.every(file => SUPPORTED_FORMATS.includes(file.type))
        : true
    }),
})

let SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  ".jpg",
]
const VehicleModal = props => {
  const [vehicle_data, setVehicleData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [lightbox_image, setIsOpen] = useState(false)
  const customAlert = useContext(CustomAlertContext)
  const modal = useContext(ModalContext)

  useEffect(() => {
    getVehicle()
  }, [])

  const getVehicle = () => {
    setLoading(true)
    get(`deliverGuy/${deliverGuy_id}/vehicle`)
      .then(res => {
        const { status, result } = res
        setVehicleData(result)
        setLoading(false)
      })
      .catch(er => console.log(`er`, er))
  }

  const { disabled, deliverGuy_id } = props

  const handleSubmit = async values => {
    // console.log(`typeof values.vehicle_image`, typeof values.vehicle_image)
    let data = new FormData()
    // data.append("vehicle_type", values.vehicle_type)
    data.append("model", values.model)
    data.append("make_year", values.make_year)
    data.append("brand_name", values.brand_name)
    data.append("rc_no", values.rc_no)
    data.append("license_plate", values.license_plate)
    data.append("color", values.color)
    // data.append("chassis_no", values.chassis_no)
    values.status && values.status.length
      ? data.append("status", values.status.length ? 1 : 0)
      : ""
    typeof values.vehicle_image == "object"
      ? data.append("vehicle_image", values.vehicle_image)
      : null
    typeof values.rc_image == "object"
      ? data.append("rc_image", values.rc_image)
      : null

    let callApi
    callApi = addVehicle(data)

    callApi
      .then(res => {
        customAlert.notify(res.message)
        modal.toggleModal(false)
      })
      .catch(err => {
        console.log(`err`, err)
      })
  }

  const addVehicle = data => post(`deliverGuy/${deliverGuy_id}/vehicle`, data)

  const formik = useFormik({
    initialValues: {
      // vehicle_type: vehicle_data.vehicle_type,
      model: vehicle_data ? vehicle_data.model : "",
      make_year: vehicle_data ? vehicle_data.make_year : "",
      color: vehicle_data ? vehicle_data.color : "",
      brand_name: vehicle_data ? vehicle_data.brand_name : "",
      license_plate: vehicle_data ? vehicle_data.license_plate : "",
      rc_no: vehicle_data ? vehicle_data.rc_no : "",
      // chassis_no: vehicle_data ? vehicle_data.chassis_no : "",
      vehicle_image: vehicle_data ? vehicle_data.vehicle_image : "",
      rc_image: vehicle_data ? vehicle_data.rc_image : "",
      status: vehicle_data ? vehicle_data.status : 0,
    },
    validationSchema: vehicleSchema,
    onSubmit: values => {
      handleSubmit(values)
    },
    enableReinitialize: true,
  })

  // let vehicle_options = [
  //   { name: "Bike", value: "bike" },
  //   { name: "3 Wheeler", value: "3-wheeler" },
  //   { name: "Tata Ace", value: "tata-ace" },
  // ]

  const changeStatus = status => () => {
    let data = {
      key: "vehicles",
      status,
    }
    post(`verify-deliver-guy/${deliverGuy_id}`, data).then(res => {
      const { result } = res

      customAlert.notify(status === 1 ? "Approved" : "Rejected")
      modal.toggleModal(false)
    })
  }
  return (
    <>
      {lightbox_image && (
        <Lightbox
          mainSrc={lightbox_image}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
      {loading ? (
        <SpinnerLoader/>
      ) : (
        <Col className="col-12">
          <Card>
            <CardBody>
              {vehicle_data && vehicle_data.status ? (
                <div className="text-end">
                  {" "}
                  <Button color="danger">
                    {vehicle_data.status === 1 ? "Verified" : "Rejected"}
                  </Button>
                </div>
              ) : null}
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-3">
                  <Row>
                    {/* <Col lg={4}>
                      {selectField(
                        formik,
                        "vehicle_type",
                        "Vehicle Type",
                        vehicle_options,
                        "Please select Vehicle Type"
                      )}
                    </Col> */}
                    <Col lg={6}>
                      {textField(
                        formik,
                        "model",
                        "Model",
                        "Enter your Model",
                        false,
                        "text",
                        false
                      )}
                    </Col>
                    <Col lg={6}>
                      {textField(
                        formik,
                        "make_year",
                        "Year",
                        "Enter your Year",
                        false,
                        "number",
                        false
                      )}
                    </Col>

                    <Col lg={6}>
                      {textField(
                        formik,
                        "color",
                        "color",
                        "Enter color",
                        false,
                        "text",
                        false
                      )}
                    </Col>

                    <Col lg={6}>
                      {textField(
                        formik,
                        "brand_name",
                        "Brand Name",
                        "Enter Brand Name",
                        false,
                        "text",
                        false
                      )}
                    </Col>
                    <Col lg={6}>
                      {textField(
                        formik,
                        "license_plate",
                        "License Plate",
                        "Enter License Plate",
                        false,
                        "text",
                        false
                      )}
                    </Col>

                    <Col lg={6}>
                      {textField(
                        formik,
                        "rc_no",
                        "R.C. No.",
                        "Enter R.C. No.",
                        false,
                        "text",
                        false
                      )}
                    </Col>
                    {/* <Col lg={6}>
                      {textField(
                        formik,
                        "chassis_no",
                        "Chassis No.",
                        "Enter Chassis No.",
                        false,
                        "text",
                        false
                      )}
                    </Col> */}
                  </Row>
                </div>

                <CustomImageInput
                  formik={formik}
                  label={"Vehicle Image"}
                  name={"vehicle_image"}
                  data={vehicle_data ? vehicle_data.vehicle_image : ""}
                  required={true}
                />

                <CustomImageInput
                  formik={formik}
                  label={"RC Image"}
                  name={"rc_image"}
                  data={vehicle_data ? vehicle_data.rc_image : ""}
                  required={true}
                />

                <Row>
                  {vehicle_data && vehicle_data.brand_name ? (
                    vehicle_data.status === 0 && (
                      <Row className="mt-2">
                        <Col md={6} sm={12}>
                          <Button
                            color="primary   btn-deliverGuy"
                            onClick={changeStatus(1)}
                          >
                            {" "}
                            Accept
                          </Button>
                          <Button
                            className="mx-4 p-2 btn-deliverGuy"
                            onClick={changeStatus(2)}
                          >
                            {" "}
                            Reject
                          </Button>
                        </Col>
                      </Row>
                    )
                  ) : (
                    <></>
                  )}
                  {vehicle_data && vehicle_data.status === 1 ? (
                    ""
                  ) : (
                    <Col>
                      <input
                        type="checkbox"
                        name="status"
                        onChange={formik.handleChange}
                      />{" "}
                      Approve
                    </Col>
                  )}
                  <div className="d-flex flex-wrap gap-2">
                    <Button type="submit" color="primary" className="">
                      Submit
                    </Button>{" "}
                  </div>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  )
}

export default VehicleModal
