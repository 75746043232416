import React, { useEffect, useState, useContext } from "react"
import MetaTags from "react-meta-tags"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { get, del } from "helpers/api_helper"
// import ConfirmationModal from "components/Common/Modals/ConfirmationModal"
import { Table, Tbody, Td, Tr } from "react-super-responsive-table"
import { Row, Col, Card, CardBody, CardTitle, Button, Input } from "reactstrap"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
// import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { Pagination } from "components/Common/Pagination"
// import { CustomSearch } from "components/Common/CustomSearch"
// import NoData from "components/NoData/NoData"
import { TableRowsHoc } from "components/Common/TableRowsHoc"
const moment = require("moment-timezone")

const TimeSlots = () => {
  const [timeSlotData, setTimeSlots] = useState([])
  const [search, setSearch] = useState("")
  const [pagination, setPagination] = useState({ page_no: 1, limit: 20 })
  // const [isOpenModal, setIsOpenModal] = useState(false)
  // const [deleteData, setDeleteData] = useState(false)
  // const customAlert = useContext(CustomAlertContext)

  useEffect(() => {
    getTimeSlots()
  }, [])

  const timeFormat = time => {
    return moment(time, "HH:mm").format("LT")
  }
  const getTimeSlots = (query = pagination) => {
    get("time-slots", { params: query }).then(res => {
      const { result } = res
      result.data.map(data => {
        data.pickup_start_time
          ? (data.pickup_start_time = timeFormat(data.pickup_start_time))
          : ""
        data.pickup_end_time
          ? (data.pickup_end_time = timeFormat(data.pickup_end_time))
          : ""
        data.delivery_start_time
          ? (data.delivery_start_time = timeFormat(data.delivery_start_time))
          : ""
        data.delivery_end_time
          ? (data.delivery_end_time = timeFormat(data.delivery_end_time))
          : ""

        return data
      })
      setTimeSlots(result.data)
      setPagination(result.meta_data)
    })
  }

  let fields = [
    { column: "Day", key: "day" },
    { column: "Pickup Start Time", key: "pickup_start_time" },
    { column: "Pickup End Time", key: "pickup_end_time" },
    { column: "Delivery Start Time", key: "delivery_start_time" },
    { column: "Delivery End Time", key: "delivery_end_time" },
  ]

  const getRows = () => (
    <TableRowsHoc length={timeSlotData.length}>
      {timeSlotData.map((timeSlot, i) => (
        <CustomRow
          fields={fields}
          data={timeSlot}
          section={"time-slots"}
          status={false}
          action={{ edit: true }}
          key={timeSlot._id}
          index={i}
        />
      ))}
    </TableRowsHoc>
  )

  return (
    <div className="page-content">
      <MetaTags>
        <title>Time Slots | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb breadcrumbItem="Time Slots" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Table className="table table-striped table-bordered">
                  <CustomColumn fields={fields} status={false} />
                  <Tbody>{getRows()}</Tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      {pagination.total_pages && pagination.total_pages > 1 ? (
        <Pagination
          pagination={pagination}
          getData={getTimeSlots}
          search={search}
        />
      ) : null}
    </div>
  )
}

export default TimeSlots
