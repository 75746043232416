import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"

import { get } from "helpers/api_helper"
import DeliverGuyForm from "components/DeliverGuys/DeliverGuyForm"
import SpinnerLoader from "components/Loader/SpinnerLoader"
const EditDeliverGuy = props => {
  const [deliverGuy, setDeliverGuy] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    get(`deliverGuys/${props.match.params.id}`).then(res => {
      const { status, result } = res
      setDeliverGuy(result)
      setLoading(false)
    })
  }, [])

  return (
    <div className="page-content">
      <MetaTags>
        <title>Delivery Guys | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Delivery Guys", href: "/deliver-guys" }}
          breadcrumbItem="Edit Delivery Guy"
        />
        {loading ? (
          <SpinnerLoader/>
        ) : (
          <DeliverGuyForm
            data={deliverGuy}
            deliverGuy_id={props.match.params.id}
          />
        )}
      </div>
    </div>
  )
}

export default EditDeliverGuy
