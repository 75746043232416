export const starRating = rating => {
  return (
    <span className="icon-color">
      {rating
        ? [...Array(Math.floor(rating))].map((elem, index) => (
            <i className="ion ion-md-star" />
          ))
        : ""}
      {rating % 1 > 0 ? <i className="ion ion-md-star-half" /> : ""}
      {rating
        ? [...Array(Math.floor(5 - rating))].map((elem, index) => (
            <i className="ion ion-md-star-outline" />
          ))
        : ""}
    </span>
  )
}
