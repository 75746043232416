exports.yupMessages = {
  password:
    "Enter password with minimum 6 characters containing atleast one number, one capital letter and one special character",
  confirm_password: "password must match",
  required: "Required",
  first_name: "Enter valid first name",
  last_name: "Enter valid last name",
  city_name: "Enter valid city name",
  is_required: "is required.",
  trim: "must be trimmed string.",
  string_email: "must be a valid email.",
  string_min: "should have a minimum length of",
  string_max: "length must be at most",
  number_base: "should be a type of number",
  number_min: "must be greater than or equal to",
  number_max: "must be less than or equal to",
  field_required: "is a required field",
  alpha_space: "can contain only alphabets and space.",
  alpha_numeric_spaces: "can contain only alphabets, numbers and space.",
  string_base: "should be a type of string",
}
