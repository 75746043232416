import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import PackageCategoryForm from "components/PackageCategory/PackageCategoryForm"
const AddPackageCategory = props => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>Package Category | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{
            title: "Package Categories",
            href: "/package-categories",
          }}
          breadcrumbItem="Add Package Category"
        />
        <PackageCategoryForm c_data={false} />{" "}
      </div>
    </div>
  )
}

export default AddPackageCategory
