import { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { get } from "helpers/api_helper"
import { starRating } from "../../utils/helpers/reviews"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { Col, Card, CardBody, Row } from "reactstrap"
import SpinnerLoader from "components/Loader/SpinnerLoader"

const ViewReview = props => {
  const [review, setReview] = useState({})
  const [loader, setLoader] = useState()

  useEffect(() => {
    setLoader(true)
    get(`review/${props.match.params.id}`)
      .then(res => {
        setReview(res.result)
        setLoader(false)
      })
      .catch(err => {
        setLoader(false)
      })
  }, [])

  const review_card = (
    <Card>
      <CardBody>
        <Row>
          <Col md={6} sm={12}>
            <h5 className="mb-1">Id no: #{review._id}</h5>
          </Col>
          <Col md={6} sm={12}>
            <h5 className="mb-1">Rating: {review.rating}</h5>
          </Col>
          <Col md={6} sm={12}>
            <h5 className="mb-1">
              Delivery Guy:{" "}
              <a href={`/deliver-guy/view/${review.deliverGuy_id}`}>
                {review.deliverGuy_id}
              </a>
            </h5>
          </Col>
          <Col md={6} sm={12}>
            {review.user ? (
              <h5 className="mb-1">
                Reviewed By:{" "}
                <a href={`/customer/view/${review.user.user_id}`}>
                  {review.user.first_name}
                </a>
              </h5>
            ) : null}
          </Col>
          <Col md={6} sm={12}>
            <h5 className="mb-1">
              Order Id:{" "}
              <a href={`/order/view/${review.order_id}`}>
                {review.order && review.order.length
                  ? review.order[0].order_track_id
                  : null}
              </a>
            </h5>
          </Col>
          <Col md={12}>
            <h5 className="mb-1">Review: {review.review}</h5>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )

  return (
    <div className="page-content">
      <MetaTags>
        <title>Reviews | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Reviews", href: "/reviews" }}
          breadcrumbItem="View Review"
        />
        {loader ? <SpinnerLoader /> : review_card}
      </div>
    </div>
  )
}

export default ViewReview
