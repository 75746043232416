import React, { useEffect, useState } from "react"
import { Button } from "reactstrap"
import Lightbox from "react-image-lightbox"

const CustomImageInput = props => {
  const [selectedFile, setSelectedFile] = useState(false)
  const [selectedMultipleFiles, setSelectedMultipleFiles] = useState([])
  const [lightbox_image, setIsOpen] = useState(false)

  // const [imageUrl, setImageUrl] = useState("")

  const { label, name, data, multiple, formik } = props

  useEffect(() => {
    if (data) {
      if (typeof data != "string" && data.length) {
        let files = data.map(image => {
          return {
            name: image,
            preview: `${image}`,
          }
        })
        setSelectedMultipleFiles(files)
      } else {
        setSelectedFile({
          name: data,
          preview: `${data}`,
        })
      }
    }
  }, [data])
  const handleImage = e => {
    let file = e.target.files[0]
    Object.assign(file, {
      preview: URL.createObjectURL(file),
    })
    setSelectedFile(file)
    if (file) {
      let u = URL.createObjectURL(file)
      let img = new Image()
      img.src = u
      // setImageUrl(u)
    }
    props.formik.setFieldValue(name, file)
  }
  const handleMultipleImageChange = async e => {
    let files = e.target.files

    for (const file of files) {
      if (file) {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
        let u = URL.createObjectURL(file)
        let img = new Image()

        img.src = u
      }
    }
    setSelectedMultipleFiles([...files])

    props.formik.setFieldValue(name, [...files])
  }

  return (
    <div className="d-flex mb-3">
      {lightbox_image && (
        <Lightbox
          mainSrc={lightbox_image}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
      <div className="image-upload-container ">
        <label htmlFor={label} className="mx-2">
          {label}
        </label>
        <input
          name={name}
          type="text"
          validate={{ required: { value: props.required } }}
          hidden
          value={selectedFile.name}
        />
        <input
          type="file"
          name="vehicleImg"
          onChange={props.multiple ? handleMultipleImageChange : handleImage}
          className="input-file-hidden"
          multiple={props.multiple}
        />
        <Button className="btn-upload-file" type="button">
          Select Image
        </Button>
        {formik.errors[name] && (
          <span className="error">{formik.errors[name]}</span>
        )}
        <div className="d-flex flex-wrap m-2">
          {multiple && selectedMultipleFiles && selectedMultipleFiles.length
            ? selectedMultipleFiles.map((el, i) => (
                <div className="position-relative" key={i}>
                  <img
                    src={el.preview}
                    className=" m-2 d-inline"
                    width="100px"
                    height="100px"
                  />
                </div>
              ))
            : ""}
        </div>
      </div>
      {selectedFile && (
        <img
          src={selectedFile.preview}
          height="100"
          alt={selectedFile.name}
          className="mx-2"
          onClick={() => setIsOpen(selectedFile.preview)}
        />
      )}
    </div>
  )
}
export default CustomImageInput
