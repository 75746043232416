import React, { useContext, useEffect, useState } from "react"
import { get, post, put } from "helpers/api_helper"
import { Col, Card, CardBody, Row, Button } from "reactstrap"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { ModalContext } from "utils/context/ModalContext"
import CustomImageInput from "components/Common/CustomImageUpload"
import { selectField } from "components/Common/Form/InputFields"
import { useFormik } from "formik"
import * as Yup from "yup"
import SpinnerLoader from "components/Loader/SpinnerLoader"

const kycSchema = Yup.object().shape({
  document_type: Yup.string().required("Required"),
  document_front_image: Yup.mixed()
    .required("Required")
    .test("fileFormat", "forms.errors.unsupportedFormat", value => {
      return value && value.length && typeof value != "string"
        ? value.every(file => SUPPORTED_FORMATS.includes(file.type))
        : true
    }),
  document_back_image: Yup.mixed()
    .required("Required")
    .test("fileFormat", "forms.errors.unsupportedFormat", value => {
      return value && value.length && typeof value != "string"
        ? value.every(file => SUPPORTED_FORMATS.includes(file.type))
        : true
    }),
})
let SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  ".jpg",
]
const KYCModal = props => {
  const [kyc_data, setKYCData] = useState(null)
  const [idOptions, setIdOptions] = useState([
    {
      name: "No Options Available",
      value: "No Options Available",
      disabled: true,
    },
  ])
  const [loading, setLoading] = useState(false)
  const customAlert = useContext(CustomAlertContext)
  const modal = useContext(ModalContext)
  const { deliverGuy_id, disabled } = props

  useEffect(() => {
    setLoading(true)
    get(`deliverGuy/${deliverGuy_id}/kyc`)
      .then(res => {
        const { result } = res
        setKYCData(result)
        setLoading(false)
      })
      .catch(er => console.log(`er`, er))
    get("kyc-options")
      .then(res => {
        const { result } = res
        let tempIdOptions = []
        result.map(item => {
          tempIdOptions.push({ name: item.name, value: item.name })
        })

        setIdOptions(tempIdOptions)
      })
      .catch(er => console.log(`er`, er))
  }, [])

  const handleSubmit = async values => {
    let data = new FormData()
    data.append("document_type", values.document_type)

    typeof values.document_front_image == "object"
      ? data.append("document_front_image", values.document_front_image)
      : null
    typeof values.document_back_image == "object"
      ? data.append("document_back_image", values.document_back_image)
      : null
    values.status ? data.append("status", values.status.length ? 1 : 0) : ""

    let callApi
    callApi = addKYC(data)
    callApi
      .then(res => {
        customAlert.notify(res.message)
        modal.toggleModal(false)
      })
      .catch(err => {
        console.log(`err.response`, err.response)
      })
  }

  const addKYC = data => post(`deliverGuy/${deliverGuy_id}/kyc`, data)

  const formik = useFormik({
    initialValues: {
      document_type: kyc_data ? kyc_data.document_type : "",
      document_front_image: kyc_data ? kyc_data.document_front_image : "",
      document_back_image: kyc_data ? kyc_data.document_back_image : "",
    },
    validationSchema: kycSchema,
    onSubmit: values => {
      handleSubmit(values)
    },
    enableReinitialize: true,
  })

  const changeStatus = status => () => {
    let data = {
      key: "kyc",
      status,
    }
    post(`verify-deliver-guy/${deliverGuy_id}`, data).then(res => {
      const { result } = res
      console.log(`result`, result)
      customAlert.notify(status === 1 ? "Approved" : "Rejected")
      modal.toggleModal(false)
    })
  }
  return loading ? (
    <SpinnerLoader />
  ) : (
    <Card>
      <CardBody>
        {kyc_data && kyc_data.status ? (
          <div className="text-end">
            {" "}
            <Button color="danger">
              {kyc_data.status === 1 ? "Verified" : "Rejected"}
            </Button>
          </div>
        ) : null}
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            {selectField(formik, "document_type", "Document Type", idOptions)}
          </div>
          <CustomImageInput
            formik={formik}
            label={"Document Front Image"}
            name={"document_front_image"}
            data={kyc_data ? kyc_data.document_front_image : false}
            required={true}
          />

          <CustomImageInput
            formik={formik}
            label={"Document Back Image"}
            name={"document_back_image"}
            data={kyc_data ? kyc_data.document_back_image : false}
            required={true}
          />

          <Row>
            {kyc_data && kyc_data.document_type ? (
              kyc_data.status === 0 && (
                <Row className="mt-2">
                  <Col md={6} sm={12}>
                    <Button
                      color="primary   btn-deliverGuy"
                      onClick={changeStatus(1)}
                    >
                      {" "}
                      Accept
                    </Button>
                    <Button
                      className="mx-4 p-2 btn-deliverGuy"
                      onClick={changeStatus(2)}
                    >
                      {" "}
                      Reject
                    </Button>
                  </Col>
                </Row>
              )
            ) : (
              <></>
            )}
            {kyc_data && kyc_data.status === 1 ? (
              ""
            ) : (
              <div className="gap-2 mb-3 mt-3">
                <Col>
                  <input
                    type="checkbox"
                    name="status"
                    onChange={formik.handleChange}
                  />{" "}
                  Approve
                </Col>
              </div>
            )}
            <div className="d-flex flex-wrap gap-2">
              <Button type="submit" color="primary" className="">
                Submit
              </Button>
            </div>
          </Row>
        </form>
      </CardBody>
    </Card>
  )
}

export default KYCModal
