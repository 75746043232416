import React, { useEffect, useState, useContext } from "react"
import MetaTags from "react-meta-tags"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import Breadcrumb from "components/Common/Breadcrumb"
import { get } from "helpers/api_helper"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import SpinnerLoader from "components/Loader/SpinnerLoader"
import { Pagination } from "components/Common/Pagination"
import { TableRowsHoc } from "components/Common/TableRowsHoc"

const Supports = ({ type = "Customer" }) => {
  const [supportData, setSupportData] = useState([])
  const [loading, setIsLoading] = useState(false)
  const customAlert = useContext(CustomAlertContext)
  const [pagination, setPagination] = useState({ page_no: 1, limit: 20 })

  useEffect(() => {
    getSupport()
  }, [])

  const getSupport = (query = pagination) => {
    setIsLoading(true)
    get(`supports/consumer/${type.toLowerCase()}`, { params: query })
      .then(res => {
        const { result } = res
        setSupportData(result.data)
        setPagination(result.meta_data)
        setIsLoading(false)
      })
      .catch(err => {
        customAlert.notify(err.response.data.message, "error")
        setIsLoading(false)
      })
  }

  const getViewTicket = ticket => (
    <a href={`/support/view/${ticket}`}>{ticket}</a>
  )

  let fields = [
    { column: "Ticket Id", key: "ticket_id", value: getViewTicket },
    { column: "Category", key: "category" },
    { column: "Title", key: "title" },
    { column: "Description", key: "description" },
  ]

  const getRows = () => (
    <TableRowsHoc length={supportData.length}>
      {supportData.map((support, i) => (
        <CustomRow
          fields={fields}
          data={support}
          section={"supports"}
          status={true}
          key={support._id}
          action={null}
          index={i}
        />
      ))}
    </TableRowsHoc>
  )

  return (
    <div className="page-content">
      <MetaTags>
        <title>{type} Support | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb breadcrumbItem={`${type} Support`} />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {loading ? (
                  <SpinnerLoader />
                ) : (
                  <Table className="table table-striped table-bordered">
                    <CustomColumn fields={fields} status={false} />
                    <Tbody>{getRows()}</Tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      {pagination.total_pages && pagination.total_pages > 1 ? (
        <Pagination pagination={pagination} getData={getSupport} />
      ) : null}
    </div>
  )
}

export default Supports
