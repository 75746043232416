import React, { useEffect, useState, useContext } from "react"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { post, put } from "helpers/api_helper"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  richTextField,
  selectField,
  textField,
} from "components/Common/Form/InputFields"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { yupMessages } from "helpers/validations/messages"
import { formats } from "helpers/validations/regex"
const FaqForm = props => {
  const { disabled, faq_id, data } = props
  const customAlert = useContext(CustomAlertContext)

  const handleSubmit = values => {
    let callApi
    if (faq_id) {
      callApi = updateFaq(values)
    } else {
      callApi = addFaq(values)
    }

    callApi
      .then(res => {
        customAlert.notify(res.message)
        window.location.href = "/faqs"
      })
      .catch(err => {
        console.log(`err.response`, err.response)

        customAlert.notify(err.response.data.message)
      })
  }

  const addFaq = data => post(`faqs`, data)

  const updateFaq = data => put(`faq/${faq_id}`, data)

  const FaqSchema = Yup.object().shape({
    Q: Yup.string()
      .strict()
      .trim(`Question ${yupMessages.trim}`)
      .required(`Question ${yupMessages.is_required}`),
    A: Yup.string()
      .strict()
      .trim(`Answer ${yupMessages.trim}`)
      .required(`Answer ${yupMessages.is_required}`),
    type: Yup.string().required(`Type ${yupMessages.is_required}`),
  })
  const formik = useFormik({
    initialValues: {
      type: data ? data.type : "",
      Q: data ? data.Q : "",
      A: data ? data.A : "",
    },
    validationSchema: FaqSchema,
    onSubmit: values => {
      handleSubmit(values)
    },
  })

  let options = [
    { name: "Customer", value: "customers" },
    { name: "Driver", value: "deliverGuys" },
    { name: "Both", value: "both" },
  ]

  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                {selectField(
                  formik,
                  "type",
                  "Select Type*",
                  options,
                  "Please select Type"
                )}
              </div>
              <div className="mb-3">
                {textField(formik, "Q", "Question*", "Enter Question")}
              </div>
              <div className="mb-3">
                {richTextField(formik, "A", "Answer*", "Enter Answer")}
              </div>
              {disabled ? null : (
                <div className="d-flex flex-wrap gap-2">
                  <Button type="submit" color="primary" className="">
                    Submit
                  </Button>{" "}
                </div>
              )}
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default FaqForm
