import React, { useEffect, useState, useContext } from "react"
import MetaTags from "react-meta-tags"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { get, del } from "helpers/api_helper"
import { Table, Tbody, Td, Tr } from "react-super-responsive-table"
import { Row, Col, Card, CardBody, CardTitle, Button, Input } from "reactstrap"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { Pagination } from "components/Common/Pagination"
import NoData from "components/NoData/NoData"
import { TableRowsHoc } from "components/Common/TableRowsHoc"
const moment = require("moment-timezone")

const WorkingHours = () => {
  const [workingHourData, setWorkingHours] = useState([])
  const [search, setSearch] = useState("")
  const [pagination, setPagination] = useState({ page_no: 1, limit: 20 })

  const customAlert = useContext(CustomAlertContext)

  useEffect(() => {
    getWorkingHours()
  }, [])
  const timeFormat = time => {
    return moment(time, "HH:mm").format("LT")
  }
  const getWorkingHours = (query = pagination) => {
    get("working-hours", { params: query }).then(res => {
      const { status, result } = res
      result.data.map(data => {
        data.start_time
          ? (data.start_time = timeFormat(data.start_time))
          : (data.start_time = "Closed")
        data.end_time
          ? (data.end_time = timeFormat(data.end_time))
          : (data.end_time = "Closed")

        return data
      })
      setWorkingHours(result.data)
      setPagination(result.meta_data)
    })
  }

  let fields = [
    { column: "Day", key: "day" },
    { column: "Start Time", key: "start_time" },
    { column: "End Time", key: "end_time" },
  ]

  const getRows = () => (
    <TableRowsHoc length={workingHourData.length}>
      {workingHourData.map((WorkingHour, i) => (
        <CustomRow
          fields={fields}
          data={WorkingHour}
          section={"working-hours"}
          status={false}
          action={{ edit: true }}
          key={WorkingHour._id}
          index={i}
        />
      ))}
    </TableRowsHoc>
  )

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Working Hours | GoFetchIt</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumb breadcrumbItem="Working Hours" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Table className="table table-striped table-bordered">
                    <CustomColumn fields={fields} status={false} />
                    <Tbody>{getRows()}</Tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {pagination.total_pages && pagination.total_pages > 1 ? (
          <Pagination
            pagination={pagination}
            getData={getWorkingHours}
            search={search}
          />
        ) : null}
      </div>
    </>
  )
}

export default WorkingHours
