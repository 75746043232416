export const getOrderStatus = (status, label = "") => {
  let value = ""
  switch (status) {
    case 1:
      value = <span className="badge bg-info">Confirmed</span>
      break
    case 2:
      value = <span className="badge bg-warning">In Process</span>
      break
    case 3:
      value = <span className="badge bg-success">Delivered</span>
      break
    case 4:
      value = <span className="badge bg-danger">Cancelled</span>
      break
    case "light":
      value = <span className="badge bg-light">{label}</span>
      break
    default:
      value = <span className="badge bg-pink">In-Cart</span>
      break
  }
  return value
}
