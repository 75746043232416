import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgotPassword from "../pages/Authentication/ForgotPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

//Customer
import Customers from "../pages/Customers"
import EditCustomer from "../pages/Customers/EditCustomer"
import ViewCustomer from "../pages/Customers/ViewCustomer"
import AddCustomer from "pages/Customers/AddCustomer"
// promocodes
import PromoCodes from "pages/PromoCodes"
import EditPromoCode from "pages/PromoCodes/EditPromoCode"
import AddPromoCode from "pages/PromoCodes/AddPromoCode"
import ViewPromoCode from "pages/PromoCodes/ViewPromoCode"

//  deliver-guys
import DeliverGuys from "pages/DeliverGuys"
import EditDeliverGuy from "pages/DeliverGuys/EditDeliverGuy"
import AddDeliverGuy from "pages/DeliverGuys/AddDeliverGuy"
import ViewDeliverGuy from "pages/DeliverGuys/ViewDeliverGuy"
// kyc
import KYC from "pages/DeliverGuys/KYC"
// vehicles
import Vehicles from "pages/DeliverGuys/Vehicles"

// notifications
import Notifications from "pages/Notifications"
import ViewNotification from "pages/Notifications/ViewNotification"

// transactions
import Transactions from "pages/Transactions"
import ViewTransaction from "pages/Transactions/ViewTransaction"

// Order
import Orders from "pages/Orders"
import AddOrder from "pages/Orders/AddOrder"
import EditOrder from "pages/Orders/EditOrder"
import ViewOrder from "pages/Orders/ViewOrder"

// CustomerSupports
import ContactQueries from "pages/ContactQueries"
import ViewContactQueries from "pages/ContactQueries/ViewContactQueries"

// Reviews
import Reviews from "pages/Reviews"
import ViewReview from "pages/Reviews/ViewReview"

// // WithdrawalRequests
import WithdrawalRequests from "pages/WithdrawalRequests"
import ViewWithdrawalRequest from "pages/WithdrawalRequests/ViewWithdrawalRequest"

import Pages from "pages/Pages"
import EditPage from "pages/Pages/EditPage"
import Faqs from "pages/Faqs"
import EditFaq from "pages/Faqs/EditFaq"
import AddFaq from "pages/Faqs/AddFaq"
import ViewFaq from "pages/Faqs/ViewFaq"
import ViewPage from "pages/Pages/ViewPage"
import AddPage from "pages/Pages/AddPage"
// My notifications
import MyNotifications from "pages/MyNotifications"
import PackageCategories from "pages/PackageCategories"
import AddPackageCategory from "pages/PackageCategories/AddPackageCategory"
import EditPackageCategory from "pages/PackageCategories/EditPackageCategory"
import ViewPackageCategory from "pages/PackageCategories/ViewPackageCategory"
import TimeSlots from "pages/TimeSlots"
import EditTimeSlot from "pages/TimeSlots/EditTimeSlot"
// Kyc Options
import KycOptions from "pages/KycOptions"
import AddKycOption from "pages/KycOptions/AddKycOption"
import EditKycOption from "pages/KycOptions/EditKycOption"
import WorkingHours from "pages/WorkingHours"
import AddWorkingHour from "pages/WorkingHours/AddWorkingHour"
import EditWorkingHour from "pages/WorkingHours/EditWorkingHour"
import ServiceArea from "pages/ServiceArea"
import AddServiceArea from "pages/ServiceArea/AddServiceArea"
import EditServiceAreas from "pages/ServiceArea/EditServiceArea"
import ShowAllServiceAreas from "pages/ServiceArea/ShowAllServiceAreas"
import Support from "pages/Support"
import SupportTicketView from "pages/Support/TicketView"
import ViewServiceAreas from "pages/ServiceArea/ViewServiceArea"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/my-notifications", component: MyNotifications },

  // //profile
  { path: "/profile", component: UserProfile },

  //  customers
  { path: "/customers", component: Customers },
  { path: "/customers/edit/:id", component: EditCustomer },
  { path: "/customers/add", component: AddCustomer },
  { path: "/customers/view/:id", component: ViewCustomer },

  //  deliver-guys
  { path: "/deliver-guys", component: DeliverGuys },
  { path: "/deliver-guys/edit/:id", component: EditDeliverGuy },
  { path: "/deliver-guys/add", component: AddDeliverGuy },
  { path: "/deliver-guys/view/:id", component: ViewDeliverGuy },
  // kyc
  { path: "/deliver-guys/:id/kyc", component: KYC },
  // vehicles
  { path: "/deliver-guys/:id/vehicles", component: Vehicles },

  //  promo-codes
  { path: "/promo-codes", component: PromoCodes },
  { path: "/promo-codes/edit/:id", component: EditPromoCode },
  { path: "/promo-codes/add", component: AddPromoCode },
  { path: "/promo-codes/view/:id", component: ViewPromoCode },
  // notifications
  { path: "/notifications", component: Notifications },
  { path: "/notification/view/:id", component: ViewNotification },

  // transactions
  { path: "/transactions", component: Transactions },
  { path: "/transactions/view/:id", component: ViewTransaction },

  // Orders
  { path: "/orders", component: Orders },
  { path: "/orders/add", component: AddOrder },
  { path: "/orders/edit/:id", component: EditOrder },
  { path: "/orders/view/:id", component: ViewOrder },

  // kyc-options
  { path: "/kyc-options", component: KycOptions },
  { path: "/kyc-options/add", component: AddKycOption },
  { path: "/kyc-options/edit/:id", component: EditKycOption },

  // contact-queries
  { path: "/contact-queries", component: ContactQueries },
  { path: "/contact-queries/view/:id", component: ViewContactQueries },

  // reviews
  { path: "/reviews", component: Reviews },
  { path: "/reviews/view/:id", component: ViewReview },

  // // withdrawal-requests
  { path: "/withdrawal-requests", component: WithdrawalRequests },
  { path: "/withdrawal-requests/view/:id", component: ViewWithdrawalRequest },

  // package-categories
  { path: "/package-categories", component: PackageCategories },
  { path: "/package-categories/add", component: AddPackageCategory },
  { path: "/package-categories/edit/:id", component: EditPackageCategory },
  { path: "/package-categories/view/:id", component: ViewPackageCategory },

  // time-slots
  { path: "/time-slots", component: TimeSlots },
  { path: "/time-slots/edit/:id", component: EditTimeSlot },

  // working-hours
  { path: "/working-hours", component: WorkingHours },
  { path: "/working-hours/edit/:id", component: EditWorkingHour },

  { path: "/working-hours/add", component: AddWorkingHour },

  // Pages
  { path: "/pages", component: Pages },
  { path: "/pages/edit/:id", component: EditPage },
  { path: "/pages/view/:id", component: ViewPage },
  { path: "/pages/add", component: AddPage },

  // Faq
  { path: "/faqs", component: Faqs },
  { path: "/faqs/edit/:id", component: EditFaq },
  { path: "/faqs/add", component: AddFaq },
  { path: "/faqs/view/:id", component: ViewFaq },

  //Service Area
  { path: "/service-area", component: ServiceArea },
  { path: "/service-area/add", component: AddServiceArea },
  { path: "/service-area/edit/:id", component: EditServiceAreas },
  { path: "/service-area/all", component: ShowAllServiceAreas },
  { path: "/service-area/view/:id", component: ViewServiceAreas },

  //support
  { path: "/support/driver", component: () => <Support type={"Driver"} /> },
  { path: "/support/customer", component: () => <Support type={"Customer"} /> },
  { path: "/support/view/:id", component: SupportTicketView },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/forgot-password", component: ForgotPassword },
]

export { userRoutes, authRoutes }
