import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { get } from "helpers/api_helper"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { Col, Card, CardBody, Row } from "reactstrap"
import renderHTML from "react-render-html"
import SpinnerLoader from "components/Loader/SpinnerLoader"

const languageLabel = {
  ar: "Arabic",
  en: "English"
}

const ViewPage = props => {
  const [page, setPage] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    get(`page/${props.match.params.id}`).then(res => {
      const { status, result } = res
      setPage(result)
      setLoading(false)
    })
  }, [])
  return (
    <div className="page-content">
      <MetaTags>
        <title>Pages | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{
            title: "Pages",
            href: "/pages",
          }}
          breadcrumbItem="View Page"
        />
        {loading ? (
          <SpinnerLoader/>
        ) : (
          <Card>
            <CardBody>
              <Row>
                <div className="col-12 fs-5">
                  <div className="invoice-title"></div>
                  <hr />
                  <Row>
                    <div className="col-6">
                      <address>
                        <strong>Id no:</strong>
                        <br />#{page._id}
                      </address>
                    </div>
                    <div className="col-6 text-end">
                      <address>
                        <strong>Name:</strong>
                        <br />
                        {page.name}
                      </address>
                    </div>
                    <div className="col-6 mb-3">
                      <strong>Language:</strong>
                      {" "}{languageLabel[page.language_code]}
                    </div>
                  </Row>
                  <Row>
                    <div className="">
                      <address>
                        <strong>Content:</strong>
                        <br />
                        {page.content ? renderHTML(page.content) : ""}
                      </address>
                    </div>
                  </Row>
                </div>
              </Row>
            </CardBody>
          </Card>
        )}
      </div>
    </div>
  )
}

export default ViewPage
