import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { get } from "helpers/api_helper"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { Col, Card, CardBody, Row } from "reactstrap"
import SpinnerLoader from "components/Loader/SpinnerLoader"
const ViewPackageCategory = props => {
  const [package_category, setPackageCategory] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    get(`package-categories/${props.match.params.id}`).then(res => {
      const { status, result } = res
      setPackageCategory(result)
      setLoading(false)
    })
  }, [])
  return (
    <div className="page-content">
      <MetaTags>
        <title>Package Categories | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{
            title: "Package Categories",
            href: "/package-categories",
          }}
          breadcrumbItem="View Package Category"
        />
        {loading ? (
          <SpinnerLoader/>
        ) : (
          <Card>
            <CardBody>
              <Row>
                <div className="col-12 fs-5">
                  <div className="invoice-title"></div>
                  <hr />
                  {/* <Row>
                    <Col md={6} sm={12}>
                      <h5 className="mb-1">Id no: #{package_category._id}</h5>
                    </Col>

                    <Col md={6} sm={12}>
                      <h5 className="mb-1">Name: {package_category.name}</h5>
                    </Col>

                    <Col>
                      <h5 className="mb-1">Value: {package_category.value}</h5>
                    </Col>
                  </Row> */}
                  <Row>
                    <div className="col-6">
                      <address>
                        <strong>Id no:</strong>
                        <br />#{package_category._id}
                      </address>
                    </div>
                    <div className="col-6 text-end">
                      <address>
                        <strong>Name:</strong>
                        <br />
                        {package_category.name}
                      </address>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-6">
                      <address>
                        <strong>Value:</strong>
                        <br />
                        {package_category.value}
                      </address>
                    </div>
                  </Row>
                </div>
              </Row>
            </CardBody>
          </Card>
        )}
      </div>
    </div>
  )
}

export default ViewPackageCategory
