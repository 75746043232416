import ServiceAreaForm from "components/Maps/ServiceAreaForm"
import MetaTags from "react-meta-tags"
import Breadcrumb from "../../components/Common/Breadcrumb"

const AddServiceArea = () => (
  <div className="page-content">
    <MetaTags>
      <title>Service Area | GoFetch</title>
    </MetaTags>
    <div className="container-fluid">
      <Breadcrumb
        breadcrumb1={{ title: "Service Area", href: "/service-area" }}
        breadcrumbItem="Add Service Area"
      />

      <ServiceAreaForm data={false} />
    </div>
  </div>
)

export default AddServiceArea
