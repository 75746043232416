import { post, put } from "helpers/api_helper"
import React, { useContext, useState } from "react"
import { Button, Row, Col } from "reactstrap"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import Map from "./Map"
import { useFormik } from "formik"
import { selectField, textField } from "components/Common/Form/InputFields"
import * as Yup from "yup"
import { yupMessages } from "helpers/validations/messages"

let currency_options = [
  { name: "ZAR", value: "ZAR" },
  { name: "AED", value: "AED" },
  // { name: "USD", value: "USD" },
  // { name: "INR", value: "INR" },
]

const formSchema = Yup.object().shape({
  title: Yup.string()
    .strict()
    .trim(`Service area name ${yupMessages.trim}`)
    .required(`Service area name ${yupMessages.is_required}`),
  base_price: Yup.number()
    .min(1, "Base price must be greater than or equal to 1")
    .required(`Base price ${yupMessages.is_required}`),
  base_price_inclusive_km: Yup.number()
    .min(1, "Base price inclusive kms must be greater than or equal to 1")
    .required(`Base price inclusive kms ${yupMessages.is_required}`),
  per_km_price: Yup.number()
    .min(1, "per km price must be greater than or equal to 1")
    .required(`Per km price ${yupMessages.is_required}`),
  data: Yup.array().min(1, "Please select a closed service area.").required("Please select a closed service area."),
  currency: Yup.string()
    .required(`Currency ${yupMessages.is_required}`),
})

const ServiceAreaForm = ({
  serviceArea = {},
  hideActionBar = false,
  draggableFalse = true,
  ...props
}) => {
  const customAlert = useContext(CustomAlertContext)
  const [loader, setLoader] = useState(false)

  const formik = useFormik({
    initialValues: {
      title: serviceArea.title || "",
      base_price: serviceArea.base_price || 75,
      base_price_inclusive_km: serviceArea.base_price_inclusive_km || 15,
      per_km_price: serviceArea.per_km_price || 5,
      data: serviceArea.data || [],
      currency: serviceArea.currency,
    },
    validationSchema: formSchema,
    onSubmit: values => {
      handleSubmit(values)
    },
  })

  const handleSubmit = values => {
    setLoader(true)
    let request = data =>
      props.serviceArea_id
        ? put(`service-areas/${props.serviceArea_id}`, data)
        : post(`service-areas`, { ...data })

    request(values)
      .then(res => {
        customAlert.notify(res.message)
        setTimeout(() => {
          window.location.href = "/service-area";
        }, 1500)
      })
      .catch(err => {
        customAlert.notify(err.response.data.message)
      })
      .finally(() => {
        setLoader(false)
      })
  }

  const action_bar = (
    <div className="my-2">
      <Button
        type="submit"
        color="secondary"
        className="m-1"
        onClick={formik.resetForm}
        disabled={loader}
      >
        Reset
      </Button>
      <Button
        className="m-1"
        type="submit"
        color="primary"
        onClick={formik.handleSubmit}
        disabled={loader}
      >
        Submit
      </Button>
    </div>
  )

  const service_area_info = (
    <Row className="mb-2">
      <Col lg={6} md={6}>
        {textField(
          formik,
          "title",
          "Service Area Name*",
          "Enter Service Area Name",
          "",
          "text",
          false,
          hideActionBar
        )}
      </Col>
      <Col xl={6}>
        {selectField(
          formik,
          "currency",
          "Currency",
          currency_options,
          undefined,
          "",
          hideActionBar
        )}
      </Col>
      <Col lg={6} md={6}>
        {textField(
          formik,
          "base_price",
          "Base Price",
          "Enter your First Name",
          "",
          "number",
          false,
          hideActionBar
        )}
      </Col>
      <Col lg={6} md={6}>
        {textField(
          formik,
          "base_price_inclusive_km",
          "Base Price Inclusive Kms",
          "Enter base price inclusive kms",
          "",
          "number",
          false,
          hideActionBar
        )}
      </Col>
      <Col lg={6} md={6}>
        {textField(
          formik,
          "per_km_price",
          "Per Km Price (After base price exclusive kms)",
          "Enter per km price",
          "",
          "number",
          false,
          hideActionBar
        )}
      </Col>
    </Row>
  )

  return (
    <div className="service-map">
      {service_area_info}
      {formik.errors["data"] && (
        <span className="error">{formik.errors["data"]}</span>
      )}
      <Map
        paths={formik.values.data}
        point={paths => formik.setFieldValue("data", paths)}
        draggableFalse={draggableFalse}
      />
      {hideActionBar ? null : action_bar}
    </div>
  )
}

export default ServiceAreaForm
