import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { get } from "helpers/api_helper"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { Col, Card, CardBody, Row } from "reactstrap"
import renderHTML from "react-render-html"
import SpinnerLoader from "components/Loader/SpinnerLoader"
const ViewFaq = props => {
  const [faq, setFaq] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    get(`faq/${props.match.params.id}`).then(res => {
      const { status, result } = res
      setFaq(result)
      setLoading(false)
    })
  }, [])
  return (
    <div className="page-content">
      <MetaTags>
        <title>FAQs | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{
            title: "FAQs",
            href: "/faqs",
          }}
          breadcrumbItem="View Faq"
        />
        {loading ? (
          <SpinnerLoader/>
        ) : (
          <Card>
            <CardBody>
              <Row>
                <div className="col-12 fs-5">
                  <div className="invoice-title"></div>
                  <hr />
                  {/* <Row>
                    <Col md={6} sm={12}>
                      <h5 className="mb-1">Id no: #{faq._id}</h5>
                    </Col>
                    <Col md={6} sm={12}>
                      <h5 className="mb-1">Type: {faq.type}</h5>
                    </Col>
                    <Col md={12} sm={12}>
                      <h5 className="mb-1">Question: {faq.Q}</h5>
                    </Col>

                    <Col md={12} sm={12}>
                      <h5 className="mb-1">
                        Answer: {faq.A ? renderHTML(faq.A) : ""}
                      </h5>
                    </Col>
                  </Row> */}
                  <Row>
                    <div className="col-6">
                      <address>
                        <strong>Id no:</strong>
                        <br />#{faq._id}
                      </address>
                    </div>
                    <div className="col-6 text-end">
                      <address>
                        <strong>Type:</strong>
                        <br />
                        {faq.type}
                      </address>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-6">
                      <address>
                        <strong>Question:</strong>
                        <br />
                        {faq.Q}
                      </address>
                    </div>
                  </Row>
                  <Row>
                    <div className="">
                      <address>
                        <strong>Answer:</strong>
                        <br />
                        {faq.A ? renderHTML(faq.A) : ""}
                      </address>
                    </div>
                  </Row>
                </div>
              </Row>
            </CardBody>
          </Card>
        )}
      </div>
    </div>
  )
}

export default ViewFaq
