import { useState, useContext } from "react"
import { Link } from "react-router-dom"

export const Pagination = ({ pagination, getData, search }) => {
  // console.log(`pagination, getData, search`, pagination, getData, search)
  let show_delta = pagination.total_pages > 4 ? true : false
  const pagination_length = () => {
    if (pagination.total_pages - pagination.page_no <= 3) {
      show_delta = false
      return pagination.total_pages - pagination.page_no
    } else if (pagination.total_pages > 3) {
      return 3
    } else {
      return pagination.total_pages
    }
  }
  return (
    <div className="text-center">
      <ul className="pagination justify-content-center pagination-rounded">
        <li
          className={
            pagination.page_no > 1 ? "page-item" : "page-item disabled"
          }
          onClick={() =>
            pagination.page_no > 1
              ? getData({ page_no: pagination.page_no - 1, search })
              : false
          }
        >
          <Link to="#" className="page-link">
            <i className="mdi mdi-chevron-left"></i>
          </Link>
        </li>
        {Array.from({ length: pagination_length() }, (_, i) =>
          pagination.page_no > 1 ? i + pagination.page_no : i + 1
        ).map((page_no, index) => (
          <li
            className="page-item"
            onClick={() => getData({ page_no: page_no, search })}
          >
            <Link
              to="#"
              className={`page-link ${
                page_no == pagination.page_no ? "current-page" : ""
              }`}
            >
              {page_no}
            </Link>
          </li>
        ))}
        {show_delta ? (
          <li className="page-item">
            <Link to="#" className="page-link">
              ...
            </Link>
          </li>
        ) : null}

        {pagination.total_pages > 3 ? (
          <li
            className="page-item"
            onClick={() => getData({ page_no: pagination.total_pages, search })}
          >
            <Link to="#" className="page-link">
              {pagination.total_pages || pagination.page_no}
            </Link>
          </li>
        ) : null}

        <li
          className={
            pagination.page_no < pagination.total_pages
              ? "page-item"
              : "page-item disabled"
          }
          onClick={() =>
            pagination.page_no < pagination.total_pages
              ? getData({ page_no: pagination.page_no + 1, search })
              : false
          }
        >
          <Link to="#" className="page-link">
            <i className="mdi mdi-chevron-right"></i>
          </Link>
        </li>
      </ul>
    </div>
  )
}
