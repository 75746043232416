import React, { useEffect, useState, useContext } from "react"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { post, put } from "helpers/api_helper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { textField } from "components/Common/Form/InputFields"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { yupMessages } from "helpers/validations/messages"
import { formats } from "helpers/validations/regex"
const PackageCategoryForm = props => {
  const { disabled, packageCategory_id, c_data } = props
  const customAlert = useContext(CustomAlertContext)

  const handleSubmit = values => {
    if (values.password == "") {
      delete values.password
    }
    let callApi
    if (packageCategory_id) {
      callApi = updatePackageCategory(values)
    } else {
      values.otp = 1234
      callApi = addPackageCategory(values)
    }

    callApi
      .then(res => {
        customAlert.notify(res.message)
        window.location.href = "/package-categories"
      })
      .catch(err => {
        console.log(`err.response`, err.response)

        customAlert.notify(err.response.data.message)
      })
  }

  const addPackageCategory = data => post(`package-categories`, data)

  const updatePackageCategory = data =>
    put(`package-categories/${packageCategory_id}`, data)

  const PackageCategorySchema = Yup.object().shape({
    name: Yup.string()
      .strict()
      .trim(`Name ${yupMessages.trim}`)
      .required(`Name ${yupMessages.is_required}`)
      .matches(formats.string, `Value ${yupMessages.alpha_space}`),
    value: Yup.string()
      .strict()
      .trim(`Value ${yupMessages.trim}`)
      .required(`Name ${yupMessages.is_required}`)
      .matches(
        formats.alpha_numeric_spaces,
        `Value ${yupMessages.alpha_numeric_spaces}`
      ),
  })
  const formik = useFormik({
    initialValues: {
      name: c_data ? c_data.name : "",
      value: c_data ? c_data.value : "",
    },
    validationSchema: PackageCategorySchema,
    onSubmit: values => {
      handleSubmit(values)
    },
  })

  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Row>
                  <Col lg={6}>
                    {textField(formik, "name", "Name*", "Enter your Name")}
                  </Col>
                  <Col lg={6}>
                    {textField(formik, "value", "Value*", "Enter your Value")}
                  </Col>
                </Row>
              </div>

              {disabled ? null : (
                <div className="d-flex flex-wrap gap-2">
                  <Button type="submit" color="primary" className="">
                    Submit
                  </Button>{" "}
                </div>
              )}
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default PackageCategoryForm
