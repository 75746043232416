import dayjs from "dayjs"
import { post } from "helpers/api_helper"
import { currency } from "helpers/currency"
import { useState, useContext, useEffect } from "react"
import { Thead, Tr, Th, Td } from "react-super-responsive-table"
import Switch from "react-switch"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { ModalContext } from "utils/context/ModalContext"
import KYCModal from "./Modals/KYCModal"
import VehicleModal from "./Modals/VehicleModal"
const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
        // background: "#fdab00",
      }}
    >
      {" "}
      Yes
    </div>
  )
}

export const CustomColumn = ({
  fields,
  status = true,
  action = true,
  index = true,
}) => {
  return (
    <Thead>
      <Tr>
        {index ? <Th>S.No.</Th> : ""}
        {fields.map((field, i) => (
          <Th key={i}>{field.column}</Th>
        ))}
        <Th>Created at</Th>
        {status ? <Th>Status</Th> : null}
        {action ? <Th>Action</Th> : null}
      </Tr>
    </Thead>
  )
}

export const CustomRow = ({
  fields,
  data,
  section,
  handleDelete,
  status = true,
  action = { edit: true, view: true, delete: true, status: true },
  handleSwitch,
  index,
  pagination,
}) => {
  const [is_checked, setChecked] = useState(data.status)
  const [row_data, setRowData] = useState(data)
  const [rowStyle, setRowStyle] = useState(false)

  const customAlert = useContext(CustomAlertContext)
  const modal = useContext(ModalContext)

  useEffect(() => {
    setRowData(data)
  }, [])
  useEffect(() => {
    setChecked(data.status)
  }, [data.status])

  useEffect(() => {
    // setRowData
    if (section == "deliver-guys") {
      row_data.status == false &&
        ((row_data.kyc.document_type && row_data.kyc.status == false) ||
          (row_data.vehicles.vehicle_type && row_data.vehicles.status == false))
        ? setRowStyle("deliverGuy-list-row")
        : setRowStyle(false)
    }
  }, [
    row_data && row_data.kyc && row_data.kyc.status,
    row_data && row_data.vehicles && row_data.vehicles.status,
  ])
  const changeStatus = (id, status) => {
    post(`${section}/change-status/${id}`, { status })
      .then(res => {
        const { result } = res
        setChecked(result.status)
        customAlert.notify(res.message, "success")
      })
      .catch(err => {
        customAlert.notify(err.response.data.message, "error")
      })
  }

  const openKYC = deliverGuy_id => e => {
    modal.setModalData({
      title: "KYC",
      blockContent: (
        <KYCModal
          deliverGuy_id={deliverGuy_id}
          setRowData={setRowData}
          deliverGuy={row_data}
        />
      ),
      size: "lg",
    })
  }
  const openVehicle = deliverGuy_id => e => {
    modal.setModalData({
      title: "Vehicle",
      blockContent: (
        <VehicleModal
          deliverGuy_id={deliverGuy_id}
          setRowData={setRowData}
          deliverGuy={row_data}
        />
      ),
      size: "lg",
    })
  }
  return (
    <Tr className={rowStyle || ""}>
      {isNaN(index) ? (
        ""
      ) : (
        <Td>
          {pagination
            ? (pagination.page_no - 1) * pagination.limit + index + 1
            : index + 1}
        </Td>
      )}
      {fields.map((field, i) => (
        <Td key={data._id} className={field.className || ""}>
          {field.value ? field.value(data[field.key], data) : data[field.key]}
        </Td>
      ))}
      <Td>
        {data.createdAt
          ? dayjs(data.createdAt).format("DD MMM YYYY, hh:mm A")
          : ""}
      </Td>
      {status ? (
        <Td>
          <Switch
            uncheckedIcon={<Offsymbol />}
            checkedIcon={<OnSymbol />}
            onColor="#fdab00"
            onChange={() =>
              handleSwitch
                ? handleSwitch(data)
                : changeStatus(data._id, !is_checked)
            }
            checked={is_checked}
            height={20}
            width={50}
          />
        </Td>
      ) : null}
      {action ? (
        <Td>
          <>
            {section == "deliver-guys" ? (
              <>
                <span title="KYC" className=" mx-2">
                  <a
                    href="javascript:void(0)"
                    title="KYC"
                    className={`${row_data.kyc.document_type && row_data.kyc.status === 1
                      ? "approved"
                      : ""
                      } c-pointer`}
                    onClick={openKYC(row_data._id, row_data.kyc.document_type)}
                  >
                    <i className="ti-id-badge"></i>
                  </a>
                </span>
                <span title="vehicles" className="mx-2">
                  <a
                    href="javascript:void(0)"
                    title="vehicles"
                    className={`${row_data.vehicles.vehicle_type &&
                      row_data.vehicles.status === 1
                      ? "approved"
                      : ""
                      } c-pointer`}
                    onClick={openVehicle(
                      row_data._id,
                      row_data.vehicles.vehicle_type
                    )}
                  >
                    <i className="ti-car"></i>
                  </a>
                </span>
              </>
            ) : null}
            {action.edit ? (
              <span title="Edit" className=" mx-2">
                <a
                  href={`/${section}/edit/${section == "pages" ? data.slug : data._id
                    }`}
                  title="Edit"
                >
                  <i className="ti-pencil ml-2"></i>
                </a>
              </span>
            ) : null}
            {action.view ? (
              <span title="View" className=" mx-2">
                <a
                  href={`/${section}/view/${section == "pages" ? data.slug : data._id
                    }`}
                  title="View"
                >
                  <i className="ti-eye"></i>
                </a>
              </span>
            ) : null}
            {action.delete ? (
              section == "customers" &&
                data.roles.length &&
                data.roles[0] == "admin" ? (
                ""
              ) : (
                <span>
                  <a
                    href="javascript:void(0)"
                    title="Delete"
                    onClick={() => handleDelete(true, data)}
                  >
                    <i className="ti-trash ml-2"></i>
                  </a>
                </span>
              )
            ) : null}
          </>
        </Td>
      ) : null}
    </Tr>
  )
}
