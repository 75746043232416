export const CustomSearch = ({
  getData,
  pagination,
  setSearch,
  placeholder,
}) => {
  const handleSearch = event => {
    let search = event.target.value

    if (search && search.length >= 2) {
      setSearch(search)
      let query = {
        // ...pagination,
        search,
      }
      // console.log(`query`, query)
      getData(query)
    } else {
      getData()
    }
  }

  return (
    <div className="text-end">
      Search:{" "}
      <input
        type="text"
        color="primary"
        className="mb-2"
        onChange={handleSearch}
        placeholder={placeholder}
      />
    </div>
  )
}
