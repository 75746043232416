import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//i18n
import { withTranslation } from "react-i18next"
import { get, put } from "helpers/api_helper"

const NotificationDropdown = props => {
  const [menu, setMenu] = useState(false)
  const [notificationData, setNotificationData] = useState([])

  useEffect(() => {
    getNotifications()
  }, [])

  const getNotifications = () => {
    get(`notifications?admin=true&status=false`).then(res => {
      setNotificationData(res.result.data || [])
    })
  }

  const markRead = noti_id => () => {
    put(`markRead/${noti_id}`).then(res => getNotifications())
  }

  const readNotification = () => {
    put("view-all-notification")
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="mdi mdi-bell-outline"></i>
          {notificationData.length ? (
            <span className="badge bg-danger rounded-pill">
              <span className="notification-count">
                {notificationData.length}
              </span>
            </span>
          ) : (
            ""
          )}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16">
                  {" "}
                  {props.t("Notifications")} ({notificationData.length})
                </h6>
              </Col>
            </Row>
          </div>
          <SimpleBar style={{ height: "230px" }}>
            {notificationData.length
              ? notificationData.map((notification, i) => (
                  <Link
                    to={`/deliver-guy/${notification.user_id}/${notification.noti_type}`}
                    className="text-reset notification-item"
                    onClick={markRead(notification._id)}
                    key={notification._id}
                  >
                    <div className="d-flex">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title bg-success rounded-circle font-size-16">
                          <i
                            className={
                              notification.noti_type == "kyc"
                                ? `ti-id-badge`
                                : "ti-car"
                            }
                          ></i>
                        </span>
                      </div>
                      <div className="flex-1">
                        <h6 className="mt-0 mb-1">{notification.title}</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{notification.description}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              : ""}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/my-notifications"
              onClick={readNotification}
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
