import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Table, Tbody, Tr, Th, Td } from "react-super-responsive-table"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
// import "../Tables/datatables.scss"/
import { get } from "helpers/api_helper"
import NoDataAvailable from "components/Common/NoDataAvailable"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
import { Pagination } from "components/Common/Pagination"
import { CustomSearch } from "components/Common/CustomSearch"
import NoData from "components/NoData/NoData"
import { TableRowsHoc } from "components/Common/TableRowsHoc"
import CustomSpinner from "components/CustomSpinner"

const ContactQueries = () => {
  const [customerSupportData, setCustomerSupportData] = useState([])
  const [loading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({ page_no: 1, limit: 20 })
  const [search, setSearch] = useState("")

  useEffect(() => {
    getCustomerSupports()
  }, [])

  const getCustomerSupports = (query = pagination) => {
    setIsLoading(true)
    get("customer-support", { params: query }).then(res => {
      const { status, result } = res
      setCustomerSupportData(result.data)
      setPagination(result.meta_data)
      setIsLoading(false)
    })
  }

  let fields = [
    { column: "Name", key: "full_name" },
    { column: "Email", key: "email" },
    { column: "Message", key: "message" },
  ]
  const getRows = () => (
    <TableRowsHoc length={customerSupportData.length}>
      {customerSupportData.map((customerSupport, i) => (
        <CustomRow
          fields={fields}
          data={customerSupport}
          section={"contact-queries"}
          action={{ view: true }}
          status={false}
          key={customerSupport._id}
          index={i}
          pagination={pagination}
        />
      ))}
    </TableRowsHoc>
  )
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Contact Queries | GoFetchIt</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumb title="GoFetchIt" breadcrumbItem="Contact Queries" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CustomSearch
                    getData={getCustomerSupports}
                    pagination={pagination}
                    setSearch={setSearch}
                    placeholder={"Name, E-mail"}
                  />
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table className="table table-striped table-bordered">
                        <CustomColumn fields={fields} status={false} />
                        <Tbody>
                          {loading ? (
                            <CustomSpinner span={fields.length + 3} />
                          ) : (
                            getRows()
                          )}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {pagination.total_pages && pagination.total_pages > 1 ? (
          <Pagination
            pagination={pagination}
            getData={getCustomerSupports}
            search={search}
          />
        ) : null}
      </div>
    </>
  )
}

export default ContactQueries
