import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import Breadcrumb from "components/Common/Breadcrumb"
import { get } from "helpers/api_helper"
import SpinnerLoader from "components/Loader/SpinnerLoader"
import ServiceAreaForm from "components/Maps/ServiceAreaForm"
import { Button } from "reactstrap"

const ViewServiceAreas = props => {
  const [serviceArea, setServiceArea] = useState({})
  const [loader, setLoader] = useState()

  useEffect(() => {
    setLoader(true)
    get(`service-areas/${props.match.params.id}`)
      .then(res => {
        const { status, result } = res
        setServiceArea(res.result)
        setLoader(false)
      })
      .catch(err => {
        setLoader(false)
      })
  }, [])

  return (
    <div className="page-content">
      <MetaTags>
        <title>Service Area | GoFetch</title>
      </MetaTags>
      <div className="container-fluid ">
        <Breadcrumb
          breadcrumb1={{ title: "View Service Areas", href: "/service-area" }}
          breadcrumbItem={serviceArea.title}
        />
        <div className="d-flex justify-content-end my-1">
          <a href={`/service-area/edit/${serviceArea._id}`}>
            <Button color="primary">Edit </Button>
          </a>
        </div>

        {loader ? (
          <SpinnerLoader />
        ) : (
          <ServiceAreaForm
            serviceArea={serviceArea}
            serviceArea_id={props.match.params.id}
            hideActionBar={true}
            draggableFalse={false}
          />
        )}
      </div>
    </div>
  )
}

export default ViewServiceAreas
