import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Table, Tbody } from "react-super-responsive-table"

import { Row, Col, Card, CardBody, Button } from "reactstrap"

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
// import "../Tables/data/tables.scss"
import { get } from "helpers/api_helper"
import NoDataAvailable from "components/Common/NoDataAvailable"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
import { TableRowsHoc } from "components/Common/TableRowsHoc"
import SpinnerLoader from "components/Loader/SpinnerLoader"

const KycOptions = () => {
  const [kyc_option_data, setCurrencyData] = useState([])
  const [loading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({ page_no: 1, limit: 20 })

  useEffect(() => {
    getKycOptions()
  }, [])

  const getKycOptions = () => {
    setIsLoading(true)

    get("kyc-options").then(res => {
      const { status, result } = res
      setCurrencyData(result)
      setIsLoading(false)
    })
  }

  let fields = [{ column: "Name", key: "name" }]

  const getRows = () => (
    <TableRowsHoc length={kyc_option_data.length}>
      {kyc_option_data.map((kycOption, i) => (
        <CustomRow
          fields={fields}
          data={kycOption}
          section={"kyc-options"}
          action={{ edit: true }}
          key={kycOption._id}
          index={i}
          pagination={pagination}
        />
      ))}
    </TableRowsHoc>
  )
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>KYC Options | GoFetchIt</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumb title="GoFetchIt" breadcrumbItem="KYC Options" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-end">
                    <Button color="primary" className="mb-2">
                      <a href="/kyc-options/add" style={{ color: "white" }}>
                        Add New
                      </a>
                    </Button>
                  </div>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      {loading ? (
                        <SpinnerLoader/>
                      ) : kyc_option_data ? (
                        <Table className="table table-striped table-bordered">
                          <CustomColumn fields={fields} />
                          <Tbody>{getRows()}</Tbody>
                        </Table>
                      ) : (
                        <NoDataAvailable module="KycOptions" />
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default KycOptions
