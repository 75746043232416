import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import WorkingHourForm from "components/WorkingHours/WorkingHourForm"
const AddWorkingHour = props => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>Working Hour | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{
            title: "Working Hours",
            href: "/working-hours",
          }}
          breadcrumbItem="Add Working Hour"
        />
        <WorkingHourForm c_data={false} />{" "}
      </div>
    </div>
  )
}

export default AddWorkingHour
