import React, { useState, useContext } from "react"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { post, put, get } from "helpers/api_helper"
import CustomImageInput from "components/Common/CustomImageUpload"
import { selectField } from "components/Common/Form/InputFields"
import { useFormik } from "formik"
import * as Yup from "yup"
import { CustomAlertContext } from "utils/context/CustomAlertContext"

const kycSchema = Yup.object().shape({
  document_type: Yup.string().required("Required"),
  document_front_image: Yup.mixed()
    .required("Required")
    .test("fileFormat", "forms.errors.unsupportedFormat", value => {
      return value && value.length && typeof value != "string"
        ? value.every(file => SUPPORTED_FORMATS.includes(file.type))
        : true
    }),
  document_back_image: Yup.mixed()
    .required("Required")
    .test("fileFormat", "forms.errors.unsupportedFormat", value => {
      return value && value.length && typeof value != "string"
        ? value.every(file => SUPPORTED_FORMATS.includes(file.type))
        : true
    }),
})
let SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  ".jpg",
]
const KYCForm = props => {
  const { deliverGuy_id, kyc_data, disabled } = props
  const customAlert = useContext(CustomAlertContext)
  const [idOptions, setIdOptions] = useState([
    {
      name: "No Options Available",
      value: "No Options Available",
      disabled: true,
    },
  ])

  useEffect(() => {
    get("kyc-options")
      .then(res => {
        const { result } = res
        let tempIdOptions = []
        result.map(item => {
          tempIdOptions.push({ name: item.name, value: item.name })
        })

        setIdOptions(tempIdOptions)
      })
      .catch(er => console.log(`er`, er))
  }, [])

  const handleSubmit = async values => {
    let data = new FormData()
    data.append("document_type", values.document_type)

    typeof values.document_front_image == "object"
      ? data.append("document_front_image", values.document_front_image)
      : null
    typeof values.document_back_image == "object"
      ? data.append("document_back_image", values.document_back_image)
      : null
    values.status ? data.append("status", values.status.length ? 1 : 0) : ""

    let callApi
    callApi = addKYC(data)
    callApi
      .then(res => {
        customAlert.notify(res.message)
        window.location.href = `/deliver-guys/${deliverGuy_id}/kyc`
      })
      .catch(err => {
        console.log(`err.response`, err.response)
      })
  }

  const addKYC = data => post(`deliverGuy/${deliverGuy_id}/kyc`, data)

  const formik = useFormik({
    initialValues: {
      document_type: kyc_data ? kyc_data.document_type : "",
      document_front_image: kyc_data ? kyc_data.document_front_image : "",
      document_back_image: kyc_data ? kyc_data.document_back_image : "",
    },
    validationSchema: kycSchema,
    onSubmit: values => {
      handleSubmit(values)
    },
    enableReinitialize: true,
  })

  const changeStatus = status => () => {
    let data = {
      key: "kyc",
      status,
    }
    post(`verify-deliver-guy/${deliverGuy_id}`, data).then(res => {
      const { result } = res

      customAlert.notify(status === 1 ? "Approved" : "Rejected")
      window.location.href = "/deliver-guys"
    })
  }
  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            {kyc_data && kyc_data.status ? (
              <div className="text-end">
                {" "}
                <Button color="danger">
                  {kyc_data.status === 1 ? "Verified" : "Rejected"}
                </Button>
              </div>
            ) : null}
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                {selectField(
                  formik,
                  "document_type",
                  "Document Type",
                  idOptions
                )}
              </div>

              <CustomImageInput
                formik={formik}
                label={"Document Front Image"}
                name={"document_front_image"}
                data={kyc_data ? kyc_data.document_front_image : false}
                required={true}
              />

              <CustomImageInput
                formik={formik}
                label={"Document Back Image"}
                name={"document_back_image"}
                data={kyc_data ? kyc_data.document_back_image : false}
                required={true}
              />

              <Row>
                {disabled ? (
                  kyc_data.status === 0 && (
                    <Row className="mt-2">
                      <Col md={6} sm={12}>
                        <Button
                          color="primary   btn-deliverGuy"
                          onClick={changeStatus(1)}
                        >
                          {" "}
                          Accept
                        </Button>
                        <Button
                          className="mx-4 p-2 btn-deliverGuy"
                          onClick={changeStatus(2)}
                        >
                          {" "}
                          Reject
                        </Button>
                      </Col>
                    </Row>
                  )
                ) : (
                  <>
                    <Col>
                      <input
                        type="checkbox"
                        name="status"
                        onChange={formik.handleChange}
                      />
                      Approve
                    </Col>
                  </>
                )}

                <div className="d-flex flex-wrap gap-2">
                  <Button type="submit" color="primary" className="">
                    Submit
                  </Button>
                </div>
              </Row>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default KYCForm
