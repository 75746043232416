import React from "react"
import { Td, Tr } from "react-super-responsive-table"
import { Spinner } from "reactstrap"

const CustomSpinner = ({ span }) => (
  <Tr>
    <Td colSpan={span} style={{ textAlign: "center" }}>
      <Spinner />
    </Td>
  </Tr>
)

export default CustomSpinner
