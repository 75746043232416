import React, { useEffect, useState, useContext } from "react"
import MetaTags from "react-meta-tags"
//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"
import { get, del, post } from "helpers/api_helper"
import ConfirmationModal from "components/Common/Modals/ConfirmationModal"
import { Table, Tbody, Td, Tr } from "react-super-responsive-table"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { CustomColumn, CustomRow } from "components/Common/CustomTable"
import { CustomAlertContext } from "utils/context/CustomAlertContext"
import { Pagination } from "components/Common/Pagination"
import { CustomSearch } from "components/Common/CustomSearch"
import NoData from "components/NoData/NoData"
import { TableRowsHoc } from "components/Common/TableRowsHoc"

const PackageCategories = () => {
  const [packageCategoryData, setPackageCategories] = useState([])
  const [search, setSearch] = useState("")
  const [pagination, setPagination] = useState({ page_no: 1, limit: 20 })
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [deleteData, setDeleteData] = useState(false)
  const [selectedTokens, setSelectedTokens] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [multiDelete, setMultiDelete] = useState(false)

  const customAlert = useContext(CustomAlertContext)

  useEffect(() => {
    getPackageCategories()
  }, [])
  const getPackageCategories = (query = pagination) => {
    get("package-categories", { params: query }).then(res => {
      const { status, result } = res

      setPackageCategories(result.data)
      setPagination(result.meta_data)
    })
  }

  const toggleModal = () => {
    if (isOpenModal) {
      setDeleteData(false)
    }
    setIsOpenModal(!isOpenModal)
    setMultiDelete(false)
  }

  const openModal = (status, data) => {
    setIsOpenModal(status)
    setDeleteData(data)
  }

  const handleDelete = () => {
    if (multiDelete) {
      post("package-categories/multi-delete", {
        ids: selectedTokens.map(tokens => tokens._id),
      })
        .then(res => {
          customAlert.notify(res.message)
          setSelectedTokens([])
          setIsOpenModal(false)
          getPackageCategories()
        })
        .catch(err => console.log(`err`, err))
    } else {
      del(`package-categories/${deleteData._id}`).then(res => {
        const { status, result } = res
        customAlert.notify(res.message)
        setDeleteData(false)
        setIsOpenModal(false)
        getPackageCategories()
      })
    }
  }

  const handleCheck =
    (user = false) =>
    event => {
      let tokens = []
      let obj = { _id: user._id, device_id: user.device_id }
      if (user) {
        tokens = [...selectedTokens]
        if (event.target.checked) {
          tokens.push(obj)
        } else {
          let index = tokens.findIndex(i => i._id === user._id)
          if (index > -1) {
            tokens.splice(index, 1) // 2nd parameter means remove one item only
          }
        }
      } else {
        if (event.target.checked) {
          packageCategoryData.map((dg, i) =>
            tokens.push({ _id: dg._id, device_id: dg.device_id })
          )
        } else {
          tokens = []
        }
      }

      setSelectedTokens(tokens)
    }
  const getCheckBox = index => user_id => {
    let user = packageCategoryData.length
      ? packageCategoryData.find(x => x._id === user_id)
      : {}
    return (
      <span>
        <input
          type="checkbox"
          onChange={handleCheck(user)}
          checked={selectedTokens.find(token => token._id === user_id)}
        />{" "}
        {/* {(pagination.page_no - 1) * pagination.limit + index + 1} */}
      </span>
    )
  }
  const getSno = index => user_id => {
    return (
      <span>{(pagination.page_no - 1) * pagination.limit + index + 1}</span>
    )
  }
  const getFields = index => {
    return [
      {
        column: (
          <input
            type="checkbox"
            onChange={handleCheck()}
            checked={
              packageCategoryData.length &&
              selectedTokens.length == packageCategoryData.length
            }
            className="th-checkbox"
          />
        ),
        key: "_id",
        value: getCheckBox(index),
      },
      {
        column: "S.No.",
        key: "_id",
        value: getSno(index),
      },
      { column: "Name", key: "name" },
    ]
  }
  const getRows = () => (
    <TableRowsHoc length={packageCategoryData.length}>
      {packageCategoryData.map((packageCategory, i) => (
        <CustomRow
          fields={getFields(i)}
          data={packageCategory}
          section={"package-categories"}
          // status={false}
          handleDelete={openModal}
          key={packageCategory._id}
          // index={i}
          pagination={pagination}
        />
      ))}
    </TableRowsHoc>
  )
  const handleMultiStatus = status => {
    post("package-category/multi-change-status", {
      ids: selectedTokens.map(tokens => tokens._id),
      status,
    })
      .then(res => {
        customAlert.notify(res.message)
        setSelectedTokens([])
        getPackageCategories()
      })
      .catch(err => console.log(`err`, err))
  }
  const handleMultiDelete = () => {
    setMultiDelete(true)
    setIsOpenModal(true)
  }
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Package Categories | GoFetchIt</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumb breadcrumbItem="Package Categories" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CustomSearch
                    getData={getPackageCategories}
                    pagination={pagination}
                    setSearch={setSearch}
                  />
                  <div className="d-flex justify-content-between">
                    {selectedTokens.length ? (
                      <Dropdown
                        isOpen={dropdownOpen}
                        toggle={() => setDropdownOpen(!dropdownOpen)}
                      >
                        <DropdownToggle caret>Action</DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => handleMultiStatus("true")}
                          >
                            Active
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => handleMultiStatus("false")}
                          >
                            Inactive
                          </DropdownItem>
                          <DropdownItem onClick={handleMultiDelete}>
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    ) : (
                      ""
                    )}{" "}
                    <Button color="primary" className="mb-2 ml-auto">
                      <a
                        href="/package-categories/add"
                        style={{ color: "white" }}
                      >
                        Add New
                      </a>{" "}
                    </Button>
                  </div>

                  <Table className="table table-striped table-bordered">
                    <CustomColumn fields={getFields()} index={false} />
                    <Tbody>{getRows()}</Tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {pagination.total_pages && pagination.total_pages > 1 ? (
          <Pagination
            pagination={pagination}
            getData={getPackageCategories}
            search={search}
          />
        ) : null}
      </div>
      <ConfirmationModal
        toggleModal={toggleModal}
        isOpenModal={isOpenModal}
        deleteRecord={handleDelete}
        title={
          multiDelete ? "Delete Package Categories" : "Delete Package Category"
        }
      />
    </>
  )
}

export default PackageCategories
