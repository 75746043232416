import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb"

import PromoCodeForm from "components/PromoCodes/PromoCodeForm"
const AddPromoCode = props => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>Promo Codes | GoFetchIt</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumb
          breadcrumb1={{ title: "Promo Codes", href: "/promo-codes" }}
          breadcrumbItem="Add Promo Code"
        />
        <PromoCodeForm p_data={false} />{" "}
      </div>
    </div>
  )
}

export default AddPromoCode
